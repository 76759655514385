import { useEffect, useState } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CURRENCY_SYMBOL } from '@/util/constants';
import { formatDateTimeSQL } from '@/util/util';


function OrderListItem({ order, index }) {

  const [showArrowIcon, setShowArrowIcon] = useState(false);

  //this should comment out when order status show on history
  const [badgeStatus, setBadgeStatus] = useState('secondary');

  const [open, setOpen] = useState(false);

  useEffect(() => {

    if (!index) return;

    if (index === 1) {
      setOpen(true);
    }

  }, [index]);

  useEffect(() => {
    switch (order.status) {
      case 'pending':
        setBadgeStatus('primary2');
        break;

      case 'processing':
      case 'delivering':
        setBadgeStatus('info');
        break;

      case 'delivered':
        setBadgeStatus('success');
        break;

      case 'cancelled':
        setBadgeStatus('danger');
        break;

      default:
        setBadgeStatus('primary')
        break;
    }
  }, [order.status]);

  useEffect(() => {
    if (open) {
      setShowArrowIcon(true);
    } else {
      setShowArrowIcon(false);
    }
  }, [open]);

  const addonsCount = (items) => (items.reduce((acc, itm) => (acc + itm.addons.length), 0));

  const calculateItemTotal = (item) => {

    const itemAddonsTotal = item?.addons.reduce((acc, adn) => (acc + (adn.price * item.qty)), 0);

    const itemsTotal = (item.price * item.qty);

    return (itemsTotal + itemAddonsTotal).toFixed(2);
  };

  const getOrderStatus = (order) => {
    if (order.type === 'collection' && order.status === 'delivered') {
      return 'Collected'
    }

    switch (order.status) {
      case 'pending':
        return 'Order Placed';

      case 'processing':
        return 'Preparing';

      case 'delivering':
        return 'On the Way';

      default:
        return order.status;
    }
  };

  return (
    <>

      <TableRow onClick={() => setOpen(!open)} className="cursor" sx={{ backgroundColor: '#F3F3F3' }}>

        <TableCell component="th" scope="order">
          {`Ordered At: ${formatDateTimeSQL(order.confirmation_time ?? order.created_at, 'hh:mm a')}`}
          <br />
          {
            order.requested_time_is_asap
            && 'Requested For: ASAP'
          }
          {
            !order.requested_time_is_asap
            && `Requested For: ${formatDateTimeSQL(order.requested_delivery_time, 'hh:mm a')}`
          }
          {
            order.accepted_delivery_time
            && ` (Accepted: ${formatDateTimeSQL(order.accepted_delivery_time, 'hh:mm a')})`
          }
        </TableCell>

        <TableCell>
          Payment:
          {' '}
          {order.payment_type?.toUpperCase()}
          <br />
          Total:
          {' '}
          {`${CURRENCY_SYMBOL}${order.total.toFixed(2)}`}
        </TableCell>

        <TableCell align="right">
          {
            !showArrowIcon
            && <ExpandMoreIcon />
          }
          {
            showArrowIcon
            && <ExpandLessIcon />
          }
        </TableCell>

      </TableRow>

      <TableRow sx={{ border: '5px solid #F3F3F3' }}>
        <TableCell colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>

            <Typography variant="h6" gutterBottom>
              Order Details:
              {' '}
              {order.type?.toUpperCase()}
            </Typography>

            <Table aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Item Name</TableCell>
                          <TableCell align="right">Item Price</TableCell>
                          <TableCell align="right">Total price</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map((item) => (
                  <TableRow key={item.item_id}>
                    <TableCell>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className="itemName">
                              {`${item.qty} x ${item.name}`}
                            </TableCell>

                            <TableCell align="right" className="itemPrice">
                              {CURRENCY_SYMBOL}
                              {(item.qty * item.price).toFixed(2)}
                            </TableCell>

                            <TableCell align="right" rowSpan={(item?.addons.length || 0) + 1} className="itemPrice">
                              {CURRENCY_SYMBOL}
                              {calculateItemTotal(item)}
                            </TableCell>
                          </TableRow>

                          {
                            item?.addons.map((addon) => (

                              <TableRow key={`${addon.item_id}_${addon.addon_id}`}>
                                <TableCell className="addonName">
                                  {`${item.qty} x ${addon.name}`}
                                </TableCell>

                                <TableCell align="right" className="addonPrice">
                                  {CURRENCY_SYMBOL}
                                  {(item.qty * addon.price).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                ))}

                {
                  order.set_menus.length !== 0 && (
                    <TableRow>
                      <TableCell className="itemName">
                        Set Menus
                      </TableCell>
                    </TableRow>
                  )
                }

                {
                  order.set_menus?.map((setMenu) => (
                    <TableRow key={setMenu.id}>
                      <TableCell>
                        <Table>
                          <TableBody>
                            <TableRow>

                              <TableCell className="itemName setMenuTitle">
                                {`${setMenu.qty} x ${setMenu.name}`}
                              </TableCell>

                              <TableCell align="right" className="itemPrice">
                                {CURRENCY_SYMBOL}
                                {setMenu.menu_price.toFixed(2)}
                              </TableCell>

                              <TableCell
                                align="right"
                                rowSpan={(setMenu.items.length + addonsCount(setMenu.items) || 0) + 1}
                                className="itemPrice"
                              >
                                {CURRENCY_SYMBOL}
                                {(setMenu.qty * setMenu.price).toFixed(2)}
                              </TableCell>

                            </TableRow>


                            {
                              setMenu?.items.map((item, index) => (
                                <>
                                  <TableRow key={`${item.id}_${index}`}>
                                    <TableCell className="setMenuItem">
                                      {`${setMenu.qty} x ${item.name}`}
                                    </TableCell>

                                    <TableCell align="right" className="setMenuItem">
                                      {CURRENCY_SYMBOL}
                                      {(setMenu.qty * item.price).toFixed(2)}
                                    </TableCell>
                                  </TableRow>

                                  {
                                    item?.addons.map((addon) => (
                                      <TableRow key={addon.id}>
                                        <TableCell className="setMenuAddon">
                                          {`${setMenu.qty} x ${addon.name}`}
                                        </TableCell>
                                        <TableCell align="right" className="setMenuAddon">
                                          {CURRENCY_SYMBOL}
                                          {(setMenu.qty * addon.price).toFixed(2)}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  }
                                </>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  ))
                }

              </TableBody>

            </Table>


            <TableContainer>
              <Table>
                <TableBody>

                  {
                    order.type === 'delivery' && (
                      <TableRow style={{ marginTop: 5 }}>
                        <TableCell colSpan={8} align="right" className="otherText">Delivery Charge</TableCell>
                        <TableCell
                          align="right"
                          className="itemPrice"
                        >
                          {`+ ${CURRENCY_SYMBOL}${order.delivery_amount.toFixed(2)}`}
                        </TableCell>
                      </TableRow>
                    )
                  }


                  <TableRow>
                    <TableCell colSpan={8} align="right" className="otherText">Discount</TableCell>
                    <TableCell
                      align="right"
                      className="itemPrice"
                    >
                      {`- ${CURRENCY_SYMBOL}${order.discount_amount.toFixed(2)}`}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={8} align="right" className="otherText">Coupon</TableCell>
                    <TableCell
                      align="right"
                      className="itemPrice"
                    >
                      {`- ${CURRENCY_SYMBOL}${order.coupon_amount.toFixed(2)}`}
                    </TableCell>
                  </TableRow>

                  {
                    order.points_used !== 0
                    && (
                      <TableRow>
                        <TableCell colSpan={8} align="right" className="otherText">
                          Points (
                          {order.points_used}
                          )
                        </TableCell>
                        <TableCell
                          align="right"
                          className="itemPrice"
                        >
                          {`- ${CURRENCY_SYMBOL}${order.points_amount.toFixed(2)}`}
                        </TableCell>
                      </TableRow>
                    )

                  }

                  {
                    order.adjustment_amount > 0
                    && (
                      <TableRow>
                        <TableCell colSpan={8} align="right" className="otherText">Adjust amount</TableCell>
                        <TableCell
                          align="right"
                          className="itemPrice"
                        >
                          {`+${CURRENCY_SYMBOL}${order.adjustment_amount.toFixed(2)}`}
                        </TableCell>
                      </TableRow>
                    )
                  }


                  <TableRow>
                    <TableCell colSpan={8} align="right" className="otherText">Total Cost</TableCell>
                    <TableCell
                      align="right"
                      className="itemPrice"
                    >
                      {`${CURRENCY_SYMBOL}${order.total.toFixed(2)}`}
                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>

            {
              order.type === 'collection' && (
                <Box margin={1}>

                  <Typography variant="h6" gutterBottom>
                    Collection Details
                  </Typography>


                  <TableContainer>
                    <Table>
                      <TableBody>

                        <TableRow>
                          <TableCell colSpan={1}>Collection Status</TableCell>
                          <TableCell align="right">
                            <span className={`badge badge-${badgeStatus} order-status `}>
                              {getOrderStatus(order)}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )
            }


            {
              order.type === 'delivery' && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Delivery Details
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>

                        <TableRow>
                          <TableCell colSpan={1}>Delivery Status</TableCell>

                          <TableCell align="right">
                            <span
                              className={`badge badge-${badgeStatus} order-status`}
                            >
                              {order.status}
                            </span>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={1}>House No</TableCell>

                          <TableCell align="right">{order.house_no}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={1}>Street</TableCell>

                          <TableCell align="right">{order.street_name}</TableCell>

                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={1}>City</TableCell>

                          <TableCell align="right">{order.city}</TableCell>

                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={1}>Postcode</TableCell>

                          <TableCell align="right">{order.postcode}</TableCell>

                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={1}>Delivery Instruction</TableCell>

                          <TableCell align="right">{order.note}</TableCell>

                        </TableRow>


                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )
            }
          </Collapse>
        </TableCell>
      </TableRow>
    </>


  );
}


export default OrderListItem;


