import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Hidden,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import CartItem from '@/components/cart/cartItem';
import { connect } from 'react-redux';
import {
  cartDiscountUpdate,
  cartItemAdd,
  cartItemAddonsUpdate,
  cartItemRemove,
  cartOrderNoteUpdate,
  cartOrderTypeUpdate,
  clearCoupon,
  clearDiscount
} from '@/store/cart/cartActions';
import { CURRENCY_SYMBOL } from '@/util/constants';
import { moneyFormat } from '@/util/util';
import { useEffect, useReducer, useState } from 'react';
import NumberAnimation from '@/components/util/Animations/NumberAnimation';
import { useNavigate } from 'react-router-dom';
import routes from '@/util/routes';
import useSetting from '@/hooks/useSetting';
import _ from 'lodash';
import { formSingleValidator, formValidator, validationRules } from '@/util/formValidation';
import useNotify from '@/hooks/useNotify';

const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};


const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    note: false,
  }
};


function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) || 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function Cart({ drawerControlHandler, ...otherProps }) {

  const styles = {
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: (theme) => theme.palette.background.paper,
    },
  };

  const setting = useSetting([
    'order_status',
    'order_disable_message',
  ]);

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  const [notify] = useNotify();

  const cartBtnStyle = {
    root: {
      width: '100%'
    }
  };

  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const [orderType, setOrderType] = useState(otherProps.orderType);

  useEffect(() => {
    setOrderType(otherProps.orderType);
  }, [otherProps.orderType]);

  const [note, setNote] = useState({
    note: otherProps?.note,
  });

  const validationSchema = {
    note: [
      validationRules.string(),
      validationRules.min([5]),
      validationRules.max([100]),
    ],
  };

  const noteChangeHandler = (e) => {

    const name = e.target.name;

    const value = e.target.value;

    setNote({
      ...note,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);


      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();
  };

  useEffect(() => {

    otherProps.clearDiscount();
    otherProps.clearCoupon();

  }, [otherProps.cart.items, otherProps.orderType, otherProps.cart.addons]);

  const orderMinimumSetting = useSetting([
    'delivery_order_minimum',
    'collection_order_minimum',
    'delivery_order_delivery_time',
    'collection_order_delivery_time',
  ]);

  const [orderMinimum, setOrderMinimum] = useState(orderMinimumSetting.delivery_order_minimum);

  const [prepareTime, setPrepareTime] = useState(orderMinimumSetting.delivery_order_delivery_time);
  //to store order minimum value in the state from setting.
  //default delivery minimum set

  useEffect(() => {

    if (orderType === 'Collection') {
      setOrderMinimum(orderMinimumSetting.collection_order_minimum);
      setPrepareTime(orderMinimumSetting.collection_order_delivery_time);
    } else {
      setOrderMinimum(orderMinimumSetting.delivery_order_minimum);
      setPrepareTime(orderMinimumSetting.delivery_order_delivery_time);

    }


  }, [orderMinimumSetting, setOrderMinimum]);


  const orderTypeHandler = (e) => {
    setOrderType(e.target.value);
    otherProps.cartOrderTypeUpdate(e.target.value);
  };

  const ItemSubmitHandler = async () => {

    if (drawerControlHandler !== undefined) {
      drawerControlHandler('right', false);
    }

    const { error, data } = await formValidator(note, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      return;
    }

    otherProps.cartOrderNoteUpdate(note.note);

    const totalAmount = (otherProps.cart.itemsTotal + otherProps.cart.addonsTotal).toFixed(2);

    if (!otherProps.cart.items.length) {
      notify.warning('Please add some item first');
      return;
    }

    if (orderMinimum > parseFloat(totalAmount)) {
      notify.warning(`Minimum order for ${orderType} is ${CURRENCY_SYMBOL}${orderMinimum}`);
      return;
    }


    navigate(routes.orderConfirmation);

  };

  // update total if needed
  useEffect(() => {
    setTotal(moneyFormat(otherProps.cart.itemsTotal + otherProps.cart.addonsTotal));
  }, [otherProps.cart.itemsTotal, otherProps.cart.addonsTotal])

  return (
    <Box sx={styles.root}>

      <Paper className="bg-light mb-1">
        <List component="nav">
          <ListItem button>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Box className="order-type-title" fontSize={16}>
                    {orderType}
                    {' '}
                    Minimum
                  </Box>
                  <Box>
                    {CURRENCY_SYMBOL}
                    {orderMinimum}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box className="order-type-title" fontSize={16}>
                    {orderType}
                    {' '}
                    Time
                  </Box>
                  <Box>
                    {prepareTime}
                    {' '}
                    min
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box fontSize={16} marginTop={1}>
                  <h6 className="order-type-title">Choose Order Type</h6>
                </Box>
                <RadioGroup value={orderType} color="primary" onChange={orderTypeHandler}>

                  <Box marginTop={1} display="flex" justifyContent="space-between">


                    {
                      (otherProps.unavailableOrderType === 'Collection' || otherProps.unavailableOrderType === '')
                      && (
                        <Box>
                          <FormControlLabel
                            className="order-type-radio"
                            value="Delivery"
                            control={<Radio color="primary" />}
                            label="Delivery"
                          />
                        </Box>
                      )
                    }

                    {
                      (otherProps.unavailableOrderType === 'Delivery' || otherProps.unavailableOrderType === '')

                      && (
                        <Box>
                          <FormControlLabel
                            className="order-type-radio"
                            value="Collection"
                            control={<Radio color="primary" />}
                            label="Collection"
                          />
                        </Box>
                      )
                    }


                  </Box>
                </RadioGroup>

              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Paper>

      <Paper>
        <List component="nav">
          {
            otherProps.cart.items.map((itm, index) => <CartItem key={`${itm.id}_${index}`} item={itm} index={index} />)
          }
          <ListItem>
            <Divider />
          </ListItem>
          <ListItem>

            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>Total</Box>
              <Box>
                <Hidden mdUp>
                  {CURRENCY_SYMBOL}
                  {total}
                </Hidden>

                <Hidden lgDown>

                  {CURRENCY_SYMBOL}
                  <NumberAnimation number={total} decimal={2} component="span" />
                </Hidden>

              </Box>
            </Box>
          </ListItem>
        </List>
      </Paper>


      <Paper className="bg-light mb-1">
        {
          (!otherProps.isOffDay && !otherProps.isClosed && setting.order_status)
          && (
            <Box>
              <TextField
                type="text"
                variant="outlined"
                margin="dense"
                fullWidth
                name="note"
                label="Any Order Instruction"
                id="note"
                autoComplete="off"
                error={!!errorState.fields.note}
                helperText={errorState.fields.note}
                value={note.note}
                onChange={noteChangeHandler}
              />
              <Button
                type="button"
                variant="contained"
                color="primary"
                sx={cartBtnStyle.root}
                onClick={() => ItemSubmitHandler()}
                style={{ padding: 7 }}
              >
                Continue
              </Button>
            </Box>
          )
        }
        {
          otherProps.isOffDay
          && (
            <Button variant="outlined" color="primary" sx={cartBtnStyle.root} disabled>
              Today we're closed
            </Button>
          )
        }
        {
          otherProps.isClosed
          && (
            <Button variant="outlined" color="primary" sx={cartBtnStyle.root} disabled>
              Restaurant has closed now.
            </Button>
          )
        }
        {
          !setting.order_status
          && (
            <Button variant="outlined" color="primary" sx={cartBtnStyle.root} disabled>
              {
                setting.order_disable_message
              }
            </Button>
          )
        }

      </Paper>

    </Box>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  isOffDay: state.opening.isOffDay,
  isClosed: state.opening.isClosed,
  orderType: state.cart.order.type,
  note: state.cart.order.note,
  unavailableOrderType: state.opening.unavailableOrderType,
});

const mapDispatchToProps = (dispatch) => ({
  cartAddItem: (item) => dispatch(cartItemAdd(item)),
  cartRemoveItem: (item) => dispatch(cartItemRemove(item)),
  cartUpdateItemAddon: (item, addons) => dispatch(cartItemAddonsUpdate(item, addons)),
  cartOrderTypeUpdate: (type) => dispatch(cartOrderTypeUpdate(type)),
  cartOrderNoteUpdate: (note) => dispatch(cartOrderNoteUpdate(note)),
  cartDiscountUpdate: (discount) => dispatch(cartDiscountUpdate(discount)),
  clearCoupon: () => dispatch(clearCoupon()),
  clearDiscount: () => dispatch(clearDiscount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
