import { apiRequest } from '@/util/util';
import {
  SETTING_GET_OPENING_HOURS_FAIL,
  SETTING_GET_OPENING_HOURS_REQUEST,
  SETTING_GET_OPENING_HOURS_SUCCESS,
  SETTING_VALIDATE_OPENING_HOURS_STATE,
} from './openingTypes';
import { API_GET_OPENING } from '@/util/constants';
import OpeningValidator from '@/util/Classes/OpeningValidator';


// --- opening hours --- //

const getOpeningHoursRequest = () => ({
  type: SETTING_GET_OPENING_HOURS_REQUEST
});

const getOpeningHoursFail = (err) => ({
  type: SETTING_GET_OPENING_HOURS_FAIL,
  payload: err
});

const getOpeningHoursSuccess = (data) => ({
  type: SETTING_GET_OPENING_HOURS_SUCCESS,
  payload: data
});

const validateOpeningStates = (data) => ({
  type: SETTING_VALIDATE_OPENING_HOURS_STATE,
  payload: data
});

export const getSettingOpeningHour = () => async (dispatch) => {

  dispatch(getOpeningHoursRequest());

  try {

    // allow auth (pre auth request)
    const response = await apiRequest.get(API_GET_OPENING);

    await dispatch(getOpeningHoursSuccess(response.data?.data));

    // also validate hours & opening sates
    await dispatch(updateOpeningHourState());

  } catch (e) {
    // console.log('ERR', e);
    dispatch(getOpeningHoursFail(e.response?.data));
  }

};

export const updateOpeningHourState = () => async (dispatch, getState) => {

  const validator = new OpeningValidator(getState);

  // today & tomorrow timing
  const data = await validator.getValidationData();

  dispatch(validateOpeningStates(data));
};
