import { Link } from 'react-router-dom';
import useSetting from '@/hooks/useSetting';
import { SETTING_FILE_PATH } from '@/util/constants';
import ScrollAnim from 'rc-scroll-anim';

function About({ showBtn }) {

  const aboutSetting = useSetting([
    'about_img',
    'about_title',
    'about_description',
    'site_title',
  ]);


  return (
    <div className="row serv_sec_info">
      <div className="col-lg-6 col-md-6 col-sm-12 banner_bottom_grid help fff" style={{ marginTop: 'auto', marginBottom: 'auto' }}>

        <img
          src={`${SETTING_FILE_PATH + aboutSetting.about_img}`}
          className="img-fluid radius-image"
          height="100vh"
          alt="indian food and restaurants"
        />

      </div>

      <div className="col-lg-6 col-md-6 col-sm-12 banner_bottom_left pl-lg-4 mt-lg-0 mt-md-5 mt-4 text-center">
        <h2 className="title-big text-center about-title">
          {aboutSetting.site_title}
        </h2>

        {
          showBtn
          && (
          <p className="about-description">
            The people of Chesterton have been long searching for the perfect curry that truly represents the authentic flavour of Bangladesh🇧🇩
            Shapla Tandoori has arrived to Chesterton to take over the previous takeaway service and can promise you that you won't be so disappointed!
            What makes Shapla Tandoori so special? ✨
            <br />
            <br />
            Shapla Tandoori understand that people have long been searching for the perfect curry and have made a pledge not to disappoint with their exotic flavours🌶
            <br />
            <br />
            To excite you even further...the highly renowned chef of the year from the previous takeaway service will be apart of the
            Shapla Tandoori team so you won't miss out on any of the delicious flavours that were previously admired🙌🏼
            Shapla Tandoori truly do provide a sense of authenticity like no other.

          </p>
)
          // && <p className="about-description">{aboutSetting.about_description?.substring(0, 500)}</p>
        }

        {
          !showBtn
          && (
          <p className="about-description">
            The people of Chesterton have been long searching for the perfect curry that truly represents the authentic flavour of Bangladesh🇧🇩
            Shapla Tandoori has arrived to Chesterton to take over the previous takeaway service and can promise you that you won't be so disappointed!
            What makes Shapla Tandoori so special? ✨

            <br />
            <br />

            Shapla Tandoori understand that people have long been searching for the perfect curry and have made a pledge not to disappoint with their exotic flavours🌶
            To excite you even further...the highly renowned chef of the year from the previous takeaway service will be apart of the
            Shapla Tandoori team so you won't miss out on any of the delicious flavours that were previously admired🙌🏼
            Shapla Tandoori truly do provide a sense of authenticity like no other.
            <br />
            <br />
            With the Shapla being the national flower of Bangladesh  because it symbolises the innocence and purity of its people,
            we really do take you on a story of Bangladesh history🇧🇩
            From Chef's Special Lamb to Lamb Achari there are an array of dishes for you to taste provided by the 2022 Chef of the Year✨
          </p>
)

        }

        {showBtn
          && <Link to="/about" className="btn btn-primary btn-style mt-4">Read More</Link>}
      </div>
    </div>
  );
}


export default About;
