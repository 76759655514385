import {
  OFFER_GET_REQUEST,
  OFFER_GET_FAIL,
  OFFER_GET_SUCCESS
} from './offerTypes';
import { apiRequest } from '@/util/util';
import { API_GET_OFFERS, API_GET_SEO } from '@/util/constants';
import { Dispatch } from 'react';
import { AllOffers } from '@/types';
import { AxiosError } from 'axios';

///// Login
export const offerRequest = () => ({
    type: OFFER_GET_REQUEST
  });

export const offerRequestFail = (err: any) => ({
    type: OFFER_GET_FAIL,
    payload: err
  });

export const offerRequestSuccess = ({ coupons = [], discounts = [] }: AllOffers) => ({
    type: OFFER_GET_SUCCESS,
    payload: { coupons, discounts }
  });

export const fetchOffers = () => async (dispatch: Dispatch<any>) => {

    dispatch(offerRequest());

    try {

      // get offer from api
      const { data } = await apiRequest.get(API_GET_OFFERS);

      const offerData = {
        coupons: data.data.coupon,
        discounts: data.data.discount,
      }

      dispatch(offerRequestSuccess(offerData));

    } catch (e) {
      dispatch(offerRequestFail((e as AxiosError).response?.data));
    }

  };
