/* eslint-disable default-param-last */
import {
  CART_ITEM_ADD,
  CART_ITEM_REMOVE,
  CART_ITEM_ADDONS_UPDATE,
  CART_COUPON_UPDATE,
  CART_DELIVERY_ADDRESS_UPDATE,
  CART_DELIVERY_CHARGE_UPDATE,
  CART_DELIVERY_TIME_UPDATE,
  CART_ORDER_TYPE_UPDATE,
  CART_ORDER_NOTE_UPDATE,
  CART_CLEAR,
  CART_PAYMENT_AMOUNT_UPDATE,
  CART_PAYMENT_METHOD_UPDATE,
  CART_PAYMENT_STATUS_UPDATE,
  CART_UPDATE_ITEMS_TOTAL,
  CART_UPDATE_ADDONS_TOTAL,
  CART_DISCOUNT_UPDATE,
  CART_UPDATE_ASAP_TIME,
  CLEAR_COUPON,
  CLEAR_DISCOUNT,
  CART_ORDER_POINTS,
  CART_POINTS_TO_MONEY,
  CART_ADJUST_POINT_MONEY,
  DELETE_CART_ITEM,
  CART_SET_MENU_ADDED,
  CART_SET_MENU_TOTAL,
  CART_SET_MENU_REMOVE, CART_SET_MENU_QUANTITY_INCREASE, CART_SET_MENU_QUANTITY_DECREASE,
} from './cartTypes';

const defaultState = {
  items: [],
  set_menus: [],
  coupon: {
    id: 0,
    value: 0,
    code: '',
    type: '',
    amount: 0, // 50BFJKS
  },
  discount: {
    id: 0,
    amount: 0,
    type: '',
    value: 0, // 10%, 10.50
  },
  delivery: {
    address: {},
    charge: 0,
    distance: 0,
    time: null, // holds date & time (Date Obj)
    isAsapTime: true,
  },
  order: {
    type: 'Delivery',
    note: ''
  },
  itemsTotal: 0,
  addonsTotal: 0,
  setMenusTotal: 0,
  orderPoint: 0,
  pointsToMoney: 0,
  adjustPointMoney: 0,
  payment: {
    amount: 0,
    method: '',
    status: false
  }
};

const reducer = (state = defaultState, { type, payload }) => {

  switch (type) {

    case CART_ITEM_ADD:
      return {
        ...state,
        items: [...itemAdd(state.items, payload.item, payload.addon, payload.isUpdate, payload.index)],
      }

    case CART_SET_MENU_ADDED:
      return {
        ...state,
        set_menus: [
          ...state.set_menus,
          payload,
        ],
      }

    case CART_ITEM_REMOVE:
      return {
        ...state,
        items: [...itemRemove(state.items, payload.item, payload.addon)],
      }

    case DELETE_CART_ITEM: {
      return {
        ...state,
        items: [...deleteCartItem(state.items, payload.item, payload.addon)],
      }
    }

    case CART_SET_MENU_REMOVE: {
      return {
        ...state,
        set_menus: [...removeSetMenuItem(state.set_menus, payload.item, payload.index)]
      }
    }

    case CART_SET_MENU_QUANTITY_INCREASE: {
      return {
        ...state,
        set_menus: [...increaseSetMenuQty(state.set_menus, payload.item, payload.index)]
      }
    }

    case CART_SET_MENU_QUANTITY_DECREASE: {
      return {
        ...state,
        set_menus: [...decreaseSetMenuQty(state.set_menus, payload.item, payload.index)]
      }
    }

    case CART_CLEAR:
      return {
        ...defaultState
      }

    case CLEAR_COUPON:
      return {
        ...state,
        coupon: { ...defaultState.coupon },
      }

    case CLEAR_DISCOUNT:
      return {
        ...state,
        discount: { ...defaultState.discount },
      }

    case CART_UPDATE_ITEMS_TOTAL:
      return {
        ...state,
        itemsTotal: calculateItemsTotal(state.items),
      }

    case CART_SET_MENU_TOTAL:
      return {
        ...state,
        setMenusTotal: calculateSetMenuTotal(state.set_menus),
      }

    case CART_UPDATE_ADDONS_TOTAL:
      return {
        ...state,
        addonsTotal: calculateAddonsTotal(state.addons, state.items),
      }

    case CART_ITEM_ADDONS_UPDATE:
      return {
        ...state,
        addons: [...itemAddonsUpdate(state.addons, state.items, payload.addons, payload.item)],
      }

    case CART_COUPON_UPDATE:
      return {
        ...state,
        coupon: payload
      }

    case CART_DISCOUNT_UPDATE:
      return {
        ...state,
        discount: payload
      }

    case CART_DELIVERY_ADDRESS_UPDATE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          address: payload
        }
      }

    case CART_DELIVERY_CHARGE_UPDATE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          distance: payload.distance,
          charge: payload.charge,
        }

      }

    case CART_DELIVERY_TIME_UPDATE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          time: payload
        }
      }

    case CART_UPDATE_ASAP_TIME:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          isAsapTime: payload
        }
      }

    case CART_ORDER_TYPE_UPDATE:
      return {
        ...state,
        order: {
          ...state.order,
          type: payload,
        }
      }

    case CART_ORDER_NOTE_UPDATE:
      return {
        ...state,
        order: {
          ...state.order,
          note: payload,
        }
      }

    case CART_PAYMENT_AMOUNT_UPDATE:
      return {
        ...state,
        payment: {
          ...state.payment,
          amount: payload
        }
      }

    case CART_PAYMENT_METHOD_UPDATE:
      return {
        ...state,
        payment: {
          ...state.payment,
          method: payload
        }
      }

    case CART_PAYMENT_STATUS_UPDATE:
      return {
        ...state,
        payment: {
          ...state.payment,
          status: payload
        }
      }

    case CART_ORDER_POINTS:
      return {
        ...state,
        orderPoint: payload,
      }

    case CART_POINTS_TO_MONEY:
      return {
        ...state,
        pointsToMoney: payload,
      }

    case CART_ADJUST_POINT_MONEY:
      return {
        ...state,
        adjustPointMoney: payload,
      }

    default: return state
  }

};

export default reducer;


function itemAdd(items, item, addon = [], isUpdate, index) {


  const existingItemIndex = items.findIndex((itm) => {

    if (itm.id !== item.id) return false;

    // if length is not same then they're different
    if (itm?.selected_addon.length !== addon.length && !isUpdate) return false;


    // checking if same addons exists in the item
    const itemsExistingAddons = itm?.selected_addon.map(({ id }) => id);

    const matchingAddonWithExistingAddons = addon
      .filter((addon) => itemsExistingAddons.includes(addon.id))
      .length;
return itemsExistingAddons.length === matchingAddonWithExistingAddons;
  });


  const newItemWithAddon = { ...item, selected_addon: addon }

  // replace old item with new one that has updated qty
  const existingItem = items[existingItemIndex];

  if (isUpdate) {

    const existingAddonIds = existingItem?.selected_addon.map((addon) => addon.id);

    let isAddonsExists = existingAddonIds
      ? addon.length === addon.filter((x) => existingAddonIds.includes(x.id)).length
      : false;
if (addon.length === 0 && isAddonsExists) {
      const newItem = {
        ...newItemWithAddon,
        qty: (existingItem.qty + 1),
      }
      items.splice(existingItem, 1);
      items.splice(index, 1, newItem);
      return items;
    }

    if (isAddonsExists) {
      const newItem = {
        ...existingItem,
        qty: (existingItem.qty + 1),
      }

      items.splice(existingItemIndex, 1, newItem);
      items.splice(index, 1);
      return items;
    }

    if (!isAddonsExists) {


      const existingItemIndex = items.findIndex((itm, itemIndex) => `${itm.id}_${itemIndex}` === `${item.id}_${index}`)

      const existingItem = items[existingItemIndex];
      const newItem = {
        ...newItemWithAddon,
        qty: existingItem.qty,
      }

      items.splice(index, 1, newItem);
      return items;
    }
  }


  if (addon?.length) {

    const existingAddonIds = existingItem?.selected_addon.map((addon) => addon.id);

    let isAddonsExists = existingAddonIds
      ? addon.length === addon.filter((x) => existingAddonIds.includes(x.id)).length
      : false
      ;

    //if addon same & item exist in cart Just UPdate quantity
    if (existingItemIndex !== -1 && isAddonsExists) {

      const Item = {
        ...existingItem,
        qty: (existingItem.qty + 1),

      }

      items.splice(existingItemIndex, 1, Item);
      return items;
    }


    if (existingItemIndex !== -1 && !isAddonsExists) {

      return [
        {
          ...newItemWithAddon,
          qty: 1
        },
        ...items
      ];
    }

  }




  // if this is a new item & there's no item
  if (existingItemIndex === -1 && !items.length) {
    return [
      {
        ...newItemWithAddon,
        qty: 1
      }
    ];
  }

  // if this is a new item & there's items available
  if (existingItemIndex === -1) {
    return [
      {
        ...newItemWithAddon,
        qty: 1
      },
      ...items
    ];
  }


  const newItem = {
    ...existingItem,
    qty: (existingItem.qty + 1),

  }

  items.splice(existingItemIndex, 1, newItem);
  return items;

}

function itemRemove(items, item, addon) {

  const existingItemIndex = items.findIndex((itm) => {

    if (itm.id !== item.id) return false;

    // if length is not same then they're different
    if (itm?.selected_addon.length !== addon.length) return false;


    // checking if same addons exists in the item
    const itemsExistingAddons = itm?.selected_addon.map(({ id }) => id);

    const matchingAddonWithExistingAddons = addon
      .filter((addon) => itemsExistingAddons.includes(addon.id))
      .length;
return itemsExistingAddons.length === matchingAddonWithExistingAddons;
  });

  // if item not found just return all items
  if (existingItemIndex === -1) {
    return items;
  }

  const existingItem = items[existingItemIndex];

  // if item has more than 1 qty then deduct one
  if (existingItem.qty > 1) {

    const newItem = {
      ...existingItem,
      qty: (existingItem.qty - 1)
    };

    items.splice(existingItemIndex, 1, newItem);

  } else { // or remove the item

    items.splice(existingItemIndex, 1);
  }

  return items;
}


function increaseSetMenuQty(items, item, index) {

  const existingItemIndex = items?.findIndex((itm) => {
    if (itm.id !== item.id) return false;

    return itm.id === item.id;
  })

  // if item not found just return all items
  if (existingItemIndex === -1) {
    return items;
  }

  const existingItem = items[existingItemIndex];

  const newItem = {
    ...existingItem,
    qty: (existingItem.qty + 1)
  }

  items.splice(existingItemIndex, 1, newItem)
  return items;
}

function decreaseSetMenuQty(items, item, index) {

  const existingItemIndex = items?.findIndex((itm) => {
    if (itm.id !== item.id) return false;

    return itm.id === item.id;
  });

  // if item not found just return all items
  if (existingItemIndex === -1) {
    return items;
  }

  const existingItem = items[existingItemIndex];

  if (existingItem.qty > 1) {
    const newItem = {
      ...existingItem,
      qty: (existingItem.qty - 1)
    }

    items.splice(existingItemIndex, 1, newItem)
  } else {
    items.splice(existingItemIndex, 1);
  }

  return items;
}

function deleteCartItem(items, item, addon) {

  const existingItemIndex = items.findIndex((itm) => {

    if (itm.id !== item.id) return false;

    // if length is not same then they're different
    if (itm?.selected_addon.length !== addon.length) return false;


    // checking if same addons exists in the item
    const itemsExistingAddons = itm?.selected_addon.map(({ id }) => id);

    const matchingAddonWithExistingAddons = addon
      .filter((addon) => itemsExistingAddons.includes(addon.id))
      .length;
return itemsExistingAddons.length === matchingAddonWithExistingAddons;
  });

  // if item not found just return all items
  if (existingItemIndex === -1) {
    return items;
  }

  items.splice(existingItemIndex, 1);

  return items;
}

function removeSetMenuItem(stateItems, item, index) {

  const existingItemIndex = stateItems?.findIndex((itm) => {
    if (itm.id !== item.id) return false;

    return itm.id === item.id;
  })

  if (existingItemIndex === -1) {
    return stateItems;
  }

  stateItems.splice(index, 1);

  return stateItems;
}


function itemAddonsUpdate(stateAddons, stateItems, addons, item) {

  const otherItemAddons = stateAddons.filter((addon) => addon.item_id !== item.id);


  // if addons are empty, we'll assume to remove item addons
  if (!addons.length) {
    return otherItemAddons;
  }

  // merge new addons & return
  const newAddons = addons.map((addon) => ({
    ...addon,
    item_id: item.id
  }));

  return [
    ...otherItemAddons,
    ...newAddons
  ];
}

function calculateItemsTotal(items) {
  const total = items.reduce((acc, itm) => acc + (itm.price * itm.qty), 0);

  return total;
}

function calculateSetMenuTotal(setMenus) {
  const setMenuTotal = setMenus.reduce((acc, itm) => acc + (parseFloat(itm.total_cost) * itm.qty), 0);
  return setMenuTotal;
}

function calculateAddonsTotal(addons, items) {

  const total = items.reduce((acc, item) => {


    const itemAddonsTotal = item.selected_addon.reduce((acc, addon) => acc + addon.price, 0);

    return acc + (itemAddonsTotal * item.qty);
  }, 0);

  return total;
}
