/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import PageHeader from '@/components/pageHeader/pageHeader';
import useSetting from '@/hooks/useSetting';
import { SETTING_FILE_PATH } from '@/util/constants';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
// import 'react-image-lightbox/style.css';
import SlideAnimation from '@/components/util/Animations/SlideAnimation';
import { updateFirstArrival } from '@/store/layout/layoutActions';
import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'


function GalleryPage({ imagePerCol = 4, ...otherProps }) {

  const styles = {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: (theme) => theme.palette.background.paper,
    },
    gridList: {
      width: '100%',
      height: '100%',
    },
    onHover: {
      cursor: 'zoom-in',
    },
    onImageBox: {
      textAlign: 'center',
      marginBottom: '5px',
    },
    circularProgress: {
      color: 'primary',
      size: '4rem',
      thickness: 1.6,
    },
  };


  const gallerySetting = useSetting([
    'gallery_img_1',
    'gallery_img_2',
    'gallery_img_3',
    'gallery_img_4',
    'gallery_img_5',
    'gallery_img_6',
    'gallery_img_7',
    'gallery_img_8',
    'gallery_img_9',
  ]);


  const tileData = [
    {
      img: gallerySetting?.gallery_img_1,
      title: 'Image',
      author: 'author',
      cols: 2,
    },
    {
      img: gallerySetting?.gallery_img_2,
      title: 'Image',
      author: 'author',
      cols: 1,
    },
    {
      img: gallerySetting?.gallery_img_3,
      title: 'Image',
      author: 'author',
      cols: 3,
    },
    {
      img: gallerySetting?.gallery_img_4,
      title: 'Image',
      author: 'author',
      cols: 4,
    },
    {
      img: gallerySetting?.gallery_img_5,
      title: 'Image',
      author: 'author',
      cols: 2,
    },
    {
      img: gallerySetting?.gallery_img_6,
      title: 'Image',
      author: 'author',
      cols: 1,
    },
    {
      img: gallerySetting?.gallery_img_7,
      title: 'Image',
      author: 'author',
      cols: 3,
    },
    {
      img: gallerySetting?.gallery_img_8,
      title: 'Image',
      author: 'author',
      cols: 2,
    },
    {
      img: gallerySetting?.gallery_img_9,
      title: 'Image',
      author: 'author',
      cols: 2,
    }
  ];



  const getImageElements = () => tileData
    .filter((tile) => tile.image !== null)
    .map((tile, idx) => (

      // <ImageListItem sx={styles.onHover} key={idx} cols={parseInt(`${isMobile ? imagePerCol * 2 : 2}`)}>
      //   <Image
      //     clickHandler={() => imagePreviewHandler(idx)}
      //     src={SETTING_FILE_PATH + tile.img}
      //     alt={tile?.title}
      //     circularProgressStyle={styles.circularProgress}
      //     loaderBoxStyle={styles.onImageBox}
      //   />


      // </ImageListItem>

      <div key={idx} className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <Item
          original={SETTING_FILE_PATH + tile.img}
          //thumbnail={SETTING_FILE_PATH + tile.img}
          width="924"
          height="800"
        >
          {({ ref, open }) => (
            <img
              className="gallery-image"
              style={{ cursor: 'zoom-in' }}
              ref={ref}
              src={SETTING_FILE_PATH + tile.img}
              alt="best indian takeaways near me"
              onClick={open}
            />
          )}
        </Item>




      </div>

    ));

  return (
    <SlideAnimation in>
      <>
        <PageHeader pageName="Our Gallery" />

        <section className="w3l-stats services py-2 backgroundDark gallery-section">

          <h2 className="text-center exo2">Gallery</h2>
          <div className="row">



            <Gallery>

              {
                    getImageElements()
                  }

            </Gallery>

          </div>


        </section>
      </>
    </SlideAnimation>
  );
}

GalleryPage.propTypes = {
  imagePerCol: PropTypes.number,
}

const mapStateToProps = (state) => ({
  settings: state.settting,
  first_arrival: state.layout.first_arrival,
});

const mapDispatchToProps = (dispatch) => ({
  updateFirstArrival: (IsFirstArrive) => dispatch(updateFirstArrival(IsFirstArrive)),
});


export default connect(mapStateToProps, mapDispatchToProps)(GalleryPage);
