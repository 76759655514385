import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAIL,
  LOGIN_REQUEST_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_REQUEST_FAIL,
  LOGOUT_REQUEST_SUCCESS,
  ADD_USER_ADDRESSES,
  DELETE_USER_ADDRESS,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_POINTS,
  UPDATE_ERROR,
  REQUIRE_AUTH,
  GET_USER_REQUEST,
  GET_USER_REQUEST_FAIL,
  GET_USER_REQUEST_SUCCESS
} from './userTypes';

import {
  AUTH_ENDPOINT_LOGIN,
  AUTH_ENDPOINT_LOGOUT, AUTH_ENDPOINT_REGISTER,
  AUTH_TOKEN_NAME,
  AUTH_USER_ENDPOINT,
  USER_ADDRESS
} from '@/util/constants';
import { apiRequest, pullFromLocalStorage, putToLocalStorage } from '@/util/util';

///// Login
export const updateRequireAuth = (payload) => ({
    type: REQUIRE_AUTH,
    payload,
  })

export const loginRequest = () => ({
    type: LOGIN_REQUEST
  });

export const loginRequestFail = (err) => ({
    type: LOGIN_REQUEST_FAIL,
    payload: err
  });

export const loginRequestSuccess = (user) => ({
    type: LOGIN_REQUEST_SUCCESS,
    payload: user
  });

export const login = (email, password, remember = false) => async (dispatch) => {

    dispatch(loginRequest());

    try {

      const deviceName = 'web';

      // authenticate
      const response = await apiRequest.post(AUTH_ENDPOINT_LOGIN, {
        email,
        password,
        remember,
        device_name: deviceName
      });

      const { access_token, user } = response.data.data;

      if (access_token) {
        putToLocalStorage(AUTH_TOKEN_NAME, access_token, false);
      }

      dispatch(loginRequestSuccess(user));

    } catch (e) {
      dispatch(loginRequestFail(e.response?.data));
    }

  };



export const addUserAddresses = (address) => ({
    type: ADD_USER_ADDRESSES,
    payload: address
  });

export const deleteUserAddresses = (address) => ({
    type: DELETE_USER_ADDRESS,
    payload: address
  });

export const updateUserAddresses = (address) => ({
    type: UPDATE_USER_ADDRESS,
    payload: address
  });




const updateErrors = (payload) => ({
    type: UPDATE_ERROR,
    payload,
  });



///// Register
export const registrationDirect = (name, email, password, password_confirmation, phone) => async () => {

    try {

      // authenticate
      const registration = await apiRequest.post(AUTH_ENDPOINT_REGISTER, {
 name, email, password, password_confirmation, phone
});

      // get auth user
      // const user = await apiRequest.get(AUTH_USER_ENDPOINT);

      return registration;

    } catch (e) {
      return e.response?.data;
    }

  };

export const saveAddress = (addressData) => async (dispatch) => {
    try {

      const address = await apiRequest.post(USER_ADDRESS, addressData);

      dispatch(addUserAddresses(address.data));

      return address.data;

    } catch (e) {
      return e.address?.data;
    }
  }

export const removeAddress = (deleteUrl) => async (dispatch) => {
    try {

      const addressDelete = await apiRequest.delete(deleteUrl);

      dispatch(deleteUserAddresses(addressDelete.data));

      return addressDelete;

    } catch (e) {
      return e.addressDelete?.data;
    }
  }


export const updateAddress = (updatedData, updateUrl) => async (dispatch) => {
    try {

      const addressUpdate = await apiRequest.post(updateUrl, updatedData);

      dispatch(updateUserAddresses(addressUpdate.data));

      return addressUpdate.data;

    } catch (e) {
      return e.addressUpdate?.data;
    }
  }




///// LogOut
const logOutRequest = () => ({
    type: LOGOUT_REQUEST
  });

const logOutRequestFail = (err) => ({
    type: LOGOUT_REQUEST_FAIL,
    payload: err
  });

const logOutRequestSuccess = (user) => ({
    type: LOGOUT_REQUEST_SUCCESS,
    payload: user
  });

export const logoutFromUI = (user) => async (dispatch) => {
  // remove token
  pullFromLocalStorage(AUTH_TOKEN_NAME, false);

  dispatch(logOutRequestSuccess(user));
};

export const logOut = () => async (dispatch) => {

    dispatch(logOutRequest());

    try {

      // get auth user
      const { data } = await apiRequest.post(AUTH_ENDPOINT_LOGOUT);

      dispatch(logOutRequestSuccess(data.data));

      // remove token
      pullFromLocalStorage(AUTH_TOKEN_NAME, false);

    } catch (e) {
      dispatch(logOutRequestFail(e.response.data));
    }

  };


///// Get auth user

const getUserRequest = () => ({
    type: GET_USER_REQUEST
  });

const getUserRequestFail = (err) => ({
    type: GET_USER_REQUEST_FAIL,
    payload: err
  });

const getUserRequestSuccess = (user) => ({
    type: GET_USER_REQUEST_SUCCESS,
    payload: user
  });

export const getUser = () => async (dispatch) => {

    dispatch(getUserRequest());

    try {

      // get auth user
      const response = await apiRequest.get(AUTH_USER_ENDPOINT);

      const { data } = response.data;


      dispatch(getUserRequestSuccess(data));

      return data;

    } catch (e) {
      dispatch(getUserRequestFail(e.response?.data));
    }

    return {};
  };


export const updateUserPoints = (payload) => ({
    type: UPDATE_USER_POINTS,
    payload,
  })
