///// Server urls

//localhost
export const SERVER_BASE_URL = process.env.REACT_APP_BASE_URL_API;
export const BASE_URL_ADMIN = process.env.REACT_APP_BASE_URL_ADMIN;
export const API_BASE_URL = `${SERVER_BASE_URL}/api`;

export const MAIL_VENDOR_URL = process.env.REACT_APP_MAIL_VENDOR_URL;

// WS
export const WS_AUTHORIZATION = 'broadcasting/auth';

//Main Server
export const AUTH_TOKEN_NAME = 'accessToken';

export const AUTH_ENDPOINT_LOGIN = `${API_BASE_URL}/auth/login`;
export const AUTH_ENDPOINT_LOGOUT = `${API_BASE_URL}/auth/logout`;
export const AUTH_ENDPOINT_REGISTER = `${API_BASE_URL}/auth/register`;


export const AUTH_USER_ENDPOINT = `${API_BASE_URL}/user`;
export const AUTH_ENDPOINT_FORGET_PASSWORD = `${API_BASE_URL}/auth/password/email`;
export const AUTH_ENDPOINT_RESET_PASSWORD = `${API_BASE_URL}/auth/password/reset`;

export const API_GET_OPENING = '/opening';
export const API_GET_SETTING = '/setting';
export const API_GET_SEO = '/get-meta';

export const API_STORE_ORDER = '/order';
export const API_CONFIRM_ORDER = '/order/confirm';

export const GET_TODAYS_ORDER = `${API_BASE_URL}/order`;

export const CURRENCY_SYMBOL = '£';

export const USER_ADDRESS_TYPES = ['home', 'work'];

export const USER_ADDRESS = `${API_BASE_URL}/user/address`;

export const API_GET_ORDER_TOKEN = '/order/get-token';

export const API_ADD_RESERVATON = '/reservation';

export const API_USER_FROFILE_UPDATE = '/user/update';

export const API_STATISTICAL_COUNT = '/statistics';

export const API_DELIVERY_CHARGE = '/order/get-distance';

export const API_USER_POINTS = '/order/get-points';

export const API_COUPON_APPLY = '/order/get-coupon';

export const API_DISCOUNT_APPLY = '/order/get-discount';

export const API_CONTACT_SUBMIT = '/contact-mail';
export const API_GET_OFFERS = '/get-offers';

export const API_RECORD_CRASH = '/crash-report';

export const ORDER_TYPE_DELIVERY = 'Delivery';
export const ORDER_TYPE_COLLECTION = 'Collection';

//additional buffer time
export const ADDITIONAL_BUFFER_MIN = 0;
export const RESERVATION_BUFFER_MIN = 30;

//card implementation credentials test type
export const PAYMENT_API_FORM_DEV_URL = 'https://web.e.test.connect.paymentsense.cloud/assets/js/client.js';

//card implementation credentials production type
export const PAYMENT_API_FORM_PROD_URL = 'https://web.e.connect.paymentsense.cloud/assets/js/client.js';

//end of card implementation credentials

export const SETTING_FILE_PATH = `${SERVER_BASE_URL}/storage/settings/`;

export const APP_TIMEZONE = process.env.REACT_APP_SITE_TIMEZONE;

export const STRIPE_PAYMENT_KEY_DEV = 'pk_test_51J8oqCHrmIWcrDbhPALY8w6Qq0yQQwB7q1b045ykHor80BgVqqr9WDNvNe4SgB3TTuSB4rpZplaVZnkwzlNAh8Tj00S5sK7DoR';

export const STRIPE_PAYMENT_KEY_PROD = 'pk_live_51Lb92XEyiaYxekM8012vmjcjXyK8n1Rksn8qtsld7c8MPTeSYsq0Kz1CzYp5EuoNkvmv5pjZpu3kzIjHd3bWLLO500expjwO4r';

export const ACTIVITY_RECORD = '/activity';

/**
 * SQL compatible date time format (Using DateFns)
 * This must be compatible with API "SQL_DATETIME_FORMAT" output
 * Outputs like: 2022-04-25 14:20:05
 * @type {string}
 */
export const SQL_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
