import { useState } from 'react';
import './allMenu.module.scss';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Card, Grid, Typography
} from '@mui/material';
import { CURRENCY_SYMBOL } from '@/util/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddToCart2 from '../cart/addToCart2';
import './categoryMenu.scss';
import { Theme } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
import {
 MenuGroupItem, MenuItem, MenuItemGroup, MenuItemRegular
} from '@/types';

type MenuItem2Props = {
  item: MenuItem;
  groupItems: MenuGroupItem[]
};

const styles = {
  menuGroup: {
    width: '100%',
  },
  menuGroupHeading: {
    backgroundColor: (t: Theme) => t.palette.secondary.dark,
  },
  menuGroupHeadingText: {
    fontSize: 30,
    color: (t: Theme) => t.palette.grey['50'],
  },
  menuGroupHeadingTextCount: {
    marginLeft: 1,
    color: yellow['400'],
  },
};

export default function MenuItem2({ item, groupItems }: MenuItem2Props) {

  const [expanded, setExpanded] = useState(0);

  const handleChange = (itemId: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? itemId : 0);
  };

  const renderItem = (item: MenuItemRegular|MenuGroupItem) => (

    <Card key={item.id} className="MenuItem">
      <Grid
        item
        xs={12}
        className="ItemNameAndPriceContainer"
        sx={{
          backgroundColor: (t: Theme) => t.background.lightYellow
        }}
      >
        <Box
          sx={{
            xs: 12
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            className="ItemName"
          >
            {item.name}
          </Typography>

          <Typography variant="body2" component="div" className="ItemDescription">
            {item.description}
          </Typography>

        </Box>

        <Box className="ItemPriceBox">
          <Box className="item-price">
            {CURRENCY_SYMBOL}
            {' '}
            {item.price.toFixed(2)}
          </Box>

          <Box ml={1} className="price-icon-div">
            <AddToCart2 item={item} />
          </Box>
        </Box>
      </Grid>
    </Card>
  );

  const renderGroup = (item: MenuItemGroup) => (
    <Accordion
      expanded={expanded === item.id}
      onChange={handleChange(item.id)}
      className="MenuItemGroup"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="AddItemToCart MenuItemGroupIcon" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={styles.menuGroupHeading}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={styles.menuGroupHeadingText}
        >
          {item.name}
        </Typography>
        <Typography
          variant="subtitle1"
          className="MenuItemGroupItemCount"
          sx={styles.menuGroupHeadingTextCount}
        >
          {groupItems.length}
          {' '}
          {(groupItems.length > 1) ? 'Items' : 'Item'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }} className="group-item-details-div">
        {
          groupItems.map((itm) => renderItem(itm))
        }
      </AccordionDetails>
    </Accordion>
  );

  if (!item.is_group && !item.group_id) {
    return renderItem(item);
  }

  if (item.is_group) {
    return renderGroup(item);
  }

}
