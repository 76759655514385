import { useSelector } from 'react-redux';
import { RootState } from '@/store';

type UseSettingResult<T extends readonly string[]> = {
  [Key in T[number]]: string | number | boolean | null;
};

/**
 * Get settings from state
 * @param settingKeys : string[]
 * @param valueOnly : boolean
 * @return any
 */
function useSetting<T extends readonly string[]>(settingKeys: [...T], valueOnly: boolean = true): UseSettingResult<T> {

  if (!Array.isArray(settingKeys)) {
    throw new Error('Setting keys must be an array');
  }

  const settings = useSelector((state: RootState) => state.setting.data);

  return Object.fromEntries(settingKeys.map((key) => {

    let value = null;

    if (settings.hasOwnProperty(key)) {
      value = valueOnly ? settings[key].value : settings[key];
    }

    return [key, value];
  })) as UseSettingResult<T>;

}

export default useSetting;
