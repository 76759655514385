import {
  Avatar, Box, Button, Paper, Typography
} from '@mui/material';
import UserAvatar from '../UserAvatar';
import { logoutFromUI, updateRequireAuth } from '@/store/user/userActions';
import {
 connect, MapDispatchToProps, useDispatch, useSelector
} from 'react-redux';
import { RootState } from '@/store';


const SessionExpire: React.FC<{logoutUI: (user: object) => void}> = ({ ...otherProps }) => {

  const user = useSelector((state: RootState) => state.user.data);
  const dispatch = useDispatch();

  const updateAuthentication = () => {
    dispatch(updateRequireAuth(false));

    otherProps.logoutUI(user);
  }

  return (
    <Paper
      elevation={0}
      style={{
        padding: 5
      }}
    >

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: 20 }}
      >
        <Box display="block">
          <Avatar>
            <UserAvatar />
          </Avatar>
        </Box>
        <Box>
          <Typography variant="h5">
            {user.name}
          </Typography>
        </Box>
      </Box>

      <Box style={{ marginBottom: 3 }}>

        <Typography variant="h5">
          {' '}
          Your session has expired
          <br />
          {' '}
          due to inactivity
        </Typography>

      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">

        <Button
          type="button"
          className="point-link"
          variant="contained"
          color="primary"
          onClick={updateAuthentication}

        >
          Login Again
        </Button>
      </Box>

    </Paper>
  );
}

const mapDispatch = (disptch: MapDispatchToProps<any, any>) => ({
  logoutUI: (user: object) => disptch(logoutFromUI(user))
});

export default connect(null, mapDispatch)(SessionExpire);
