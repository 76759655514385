import { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { getUser, updateRequireAuth } from '@/store/user/userActions';
import _ from 'lodash';
import { getSettingOpeningHour } from '@/store/opening/openingActions';
import { getSetting } from '@/store/setting/settingActions';
import '@/assets/css/bundle.scss';
import { getSeo } from '@/store/seo/seoActions';
import ModalComp from '../util/Modal/ModalComp';
import SessionExpire from '@/components/util/AuthenticationError/SessionExpire';
import Nav from '../nav/Nav';
import { useLocation } from 'react-router-dom';
import { EchoInstance } from '@/util/util';
import useSetting from '@/hooks/useSetting';
import ReactGA from 'react-ga';
import Nav2 from '../nav/Nav2';
import Nav3 from '../nav/Nav3';

function loadServerData({
  opening, setting, user, seo, ...otherProps
}) {

  // load seo
  if (!seo.loading && _.isEmpty((seo.data))) {
    otherProps.getSeo();
  }

  // load auth user
  if (_.isEmpty(user)) {
    otherProps.getUser(true);
  }

  // load opening hours
  if (!opening.loading && _.isEmpty((opening.data))) {
    otherProps.getOpeningHours();
  }

  // load settings
  if (!setting.loading && _.isEmpty((setting.data))) {
    otherProps.getSettings();
  }
}

function Header({ ...otherProps }) {

  const location = useLocation();

  const [loadAssets, setLoadAssets] = useState(0);

  const setting = useSetting([
    'google_analytics_id',
  ]);

  useEffect(() => {
    ReactGA.initialize('Gh41513');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const pageSeo = useMemo(() => {

    // seo is empty
    if (_.isEmpty(otherProps.seo.data)) return false;

    const path = window.location.pathname;

    const seoRule = otherProps.seo.data[path];
    // if SEO not found for the page
    return (seoRule) || false;

  }, [otherProps.seo.data])

  useEffect(() => {
    setLoadAssets(1);

    // load data from server
    loadServerData(otherProps);
  }, []);

  // send activity record when page changes
  useEffect(() => {

    sendActivityRecord();

    return () => sendActivityRecord(true);

  }, [location.pathname]);

  function sendActivityRecord(leave = false) {

    const path = btoa(location.pathname);
    const channelName = `activityCh.${path}`;

    if (leave) {
      EchoInstance.leave(channelName);
      return;
    }

    EchoInstance.join(channelName);

  }

  return (
    <>
      {
        pageSeo
        && (
          <Helmet>

            <meta charSet={`${pageSeo.charset}`} />
            <title>{`${pageSeo.title}`}</title>
            <meta name="title" content={`${pageSeo.title}`} />
            <meta name="keywords" content={`${pageSeo.keywords.join(',')}`} />
            <meta name="description" content={`${pageSeo.description}`} />
            <meta name="copyright" content={`${pageSeo.copyright}`} />
            {/*<META NAME="geo.position" CONTENT="latitude; longitude">*/}
            {/*<META NAME="geo.placename" CONTENT="Place Name">*/}
            {/*<META NAME="geo.region" CONTENT="Country Subdivision Code">*/}
          </Helmet>
        )
      }
      {
        loadAssets
        && (
          <Helmet>

            {/*css*/}
            <link
              href="https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap"
              rel="stylesheet"
            />
            <link
              href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap"
              rel="stylesheet"
            />

          </Helmet>
        )
      }

      {
        otherProps.show_header
        && <Nav />


      }

      {
        otherProps.isRequireAuth && Object.keys(otherProps.user).length > 0
        && (
          <ModalComp
            open
            hideHeader
            hideFooter
            closeOnBackdropClick={false}
            primaryBackground
            disableFocusEnforcement
          >

            <SessionExpire />
          </ModalComp>
        )

      }
    </>
  )
}

const mapStateToProps = (state) => ({
  show_header: state.layout.show_header,
  user: state.user.data,
  opening: state.opening,
  setting: state.setting,
  seo: state.seo,
  isRequireAuth: state.user.requireAuth,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (updateState = false) => dispatch(getUser(updateState)),
  getOpeningHours: () => dispatch(getSettingOpeningHour()),
  getSettings: () => dispatch(getSetting()),
  getSeo: () => dispatch(getSeo()),
  updateRequireAuth: (payload) => dispatch(updateRequireAuth(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
