import { Grid, List, Paper } from '@mui/material';
import { useEffect } from 'react';
import PageHeader from '@/components/pageHeader/pageHeader';
import UserProfileComponent from '@/components/user/userProfileComponent';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import routes from '@/util/routes';
import { urlRedirectSet } from '@/store/url/urlActions';
import { connect } from 'react-redux';
import SlideAnimation from '@/components/util/Animations/SlideAnimation';


function UserProfile({ ...otherProps }) {

  const getLocaltion = useLocation();
  const navigate = useNavigate();
  useEffect(() => {

    if (!otherProps.user.isLoggedIn) {

      otherProps.setUrlRedirect(getLocaltion.pathname, routes.login);

      // redirect
      navigate(routes.login);


    }
  }, [otherProps.user]);

  const styles = {
    activeColor: {
      backgroundColor: '#F3F3F3',
    },
  };

  return (
    <SlideAnimation in>
      <>
        <PageHeader pageName="My Profile" />

        <Grid container>

          <Grid item xs={12} md={3} margin="2">
            <Link to={routes.orderHistory}>
              <Paper>
                <List component="nav" align="center" className="profile-padding profile-margin">
                  Today's Order
                </List>
              </Paper>
            </Link>

            <Link to={routes.userProfile}>
              <Paper sx={styles.activeColor}>
                <List component="nav" align="center" className="profile-padding">
                  My Profile
                </List>
              </Paper>
            </Link>

          </Grid>

          <Grid item xs={12} md={9}>
            <UserProfileComponent />
          </Grid>

        </Grid>
      </>
    </SlideAnimation>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  setUrlRedirect: (from, to) => dispatch(urlRedirectSet(from, to)),

})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
