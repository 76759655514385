import { memo, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box, CardContent, Grid,
  List, ListItem, ListItemText,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux';
import { HandleSetMenuSelection, SelectedMenuAdded } from '@/store/menu/menuActions';
import MobileMenuItem from './mobileMenuItem';
import './categoryMenu.scss';
import MenuItem2 from './menuItem2';

const errStyles = {
  marginLeft: '5px',
  marginRight: '5px',
  marginBottom: 2,
  padding: 1,
  color: (t) => t.palette.primary.main,
  fontSize: 23,
  border: 1,
  borderRadius: 2,
};

const styles = {
  menuGroupHeading: {
    backgroundColor: (theme) => theme.palette.secondary.dark,
    borderRadius: 5
  },
};

function MobileMenuCategory({ ...otherProps }) {

  const [selectedCategory, setSelectedCategory] = useState('none');

  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    otherProps.HandleSetMenuSelection(false);
    setExpanded(isExpanded ? panel : false);
  };

  const handleCategorySelect = (category) => {
    otherProps.SelectedMenuAdded(category, otherProps.items[category.name]);

  };

  useEffect(() => {

    if (selectedCategory === 'none' && otherProps.selectedCategory) {
      // update category selection
      handleSelection(otherProps.selectedCategory);

      // open accordion if its a parent or child
      if (otherProps.selectedCategory.is_parent || otherProps.selectedCategory.parent_id) {
        const accordionId = (otherProps.selectedCategory.is_parent)
          ? otherProps.selectedCategory.id
          : otherProps.selectedCategory.parent_id
        ;
        setExpanded(accordionId);
      }
    }
  }, [selectedCategory]);


  const handleSelection = (category) => {
    setSelectedCategory(category.name);
    handleCategorySelect(category);
  }

  useEffect(() => {
    setSelectedCategory(otherProps.selectedCategory.name);
  }, [otherProps.selectedCategory]);

  const renderCategoryItem = (category) => (
    <Accordion
      key={category.id}
      expanded={expanded === category.id}
      onChange={handleAccordionChange(category.id)}
      className="mobileMenuCategoryDiv"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`header${category.id}`}
        className="categoryHeading"
      >
        <Typography component="h2" className="category-text">
          {category.name}
        </Typography>
      </AccordionSummary>

      <AccordionDetails
        style={{ flexDirection: 'column' }}
        className="category-details"
      >
        <MobileMenuItem categoryName={category.name} />
      </AccordionDetails>
    </Accordion>
);

  const renderCategoryWithChilds = (category) => {

    const flatCategories = [category, ...category.childs];

    return (
      <Accordion
        key={category.id}
        expanded={expanded === category.id}
        onChange={handleAccordionChange(category.id)}
        style={{ bottomBorder: '1px solid #141414' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`header${category.id}`}
          className={styles.menuGroupHeading}
        >
          <Typography component="h2">
            {category.name}

          </Typography>

        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {
            flatCategories.map((cat) => renderCategoryItem(cat))
          }
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderCategory = (category) => {

    if (category.is_parent) {
      return renderCategoryWithChilds(category);
    }

    return renderCategoryItem(category);
  };

  const setMenuClick = () => {
    otherProps.HandleSetMenuSelection(true);
  }

  return (
    <List component="nav">

      {
        (!otherProps.isOpen && !otherProps.willOpen && !otherProps.isTomorrowAvailable) && (
          <Box>
            <Typography
              color="primary"
              fontSize={23}
              border={1}
              borderRadius={2}
              p={1}
              mb={2}
              mx={1}
            >
              Today we're closed but you can order for later by calling us
            </Typography>
          </Box>
        )
      }

      { // when open but no order type available
        (otherProps.isOpen && !otherProps.collectionOrder && !otherProps.deliveryOrder) && (
          <Typography sx={errStyles}>
            Online ordering is not available now but you can order by calling us
          </Typography>
        )
      }

      {
        otherProps.storeCategories.map((category) => renderCategory(category))
      }

      {
        otherProps.setMenus.length
        && (
          <Accordion
            className="mobileMenuCategoryDiv"
            onChange={setMenuClick}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className="categoryHeading"
            >
              <Typography component="h2" className="category-text">
                Set Menu
              </Typography>

            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column' }} className="category-details">
              <List component="nav">

                <Grid className="item-card">


                  <CardContent className="CardContent">
                    {
                      otherProps.setMenus?.map((item) => (
                        <MenuItem2 key={item.id} item={item} groupItems={[]} />
                      ))
                    }

                  </CardContent>


                </Grid>

              </List>

            </AccordionDetails>
          </Accordion>
        )
      }

    </List>
  );
}

const mapStateToProps = (state) => ({
  storeCategories: state.menu.categories,
  items: state.menu.items,
  setMenus: state.menu.set_menus,
  selectedCategory: state.menu.selectedCategory,
  isOpen: state.opening.isOpen,
  willOpen: state.opening.willOpen,
  isTomorrowAvailable: (!state.opening.orderTiming.today && state.opening.orderTiming.hours.length),
  deliveryOrder: state.opening.delivery,
  collectionOrder: state.opening.collection,
});

const mapDispatchToProps = (dispatch) => ({
  SelectedMenuAdded: (selectedCategory, selectedItems) => dispatch(SelectedMenuAdded(selectedCategory, selectedItems)),
  HandleSetMenuSelection: (isSelected) => dispatch(HandleSetMenuSelection(isSelected)),
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(MobileMenuCategory));
