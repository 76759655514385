/* eslint-disable default-param-last */
import {
  OFFER_GET_REQUEST,
  OFFER_GET_FAIL,
  OFFER_GET_SUCCESS
} from './offerTypes';

const defaultState = {
  loading: false,
  data: {
    coupons: [],
    discounts: []
  },
  error: '',
  errors: {}
};

export type OfferState = typeof defaultState;
type Action = {
  type: typeof OFFER_GET_REQUEST | typeof OFFER_GET_FAIL | typeof OFFER_GET_SUCCESS;
  payload: any
};

const reducer = (state = defaultState, { type, payload }: Action) => {

  switch (type) {

    case OFFER_GET_REQUEST:
      return {
        ...state,
        loading: true
      }

    case OFFER_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: payload?.message,
        errors: payload?.errors || {}
      }

    case OFFER_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: '',
        errors: {}
      }

    default: return state
  }

};

export default reducer;
