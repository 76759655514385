import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import { footerShowHide, headerShowHide } from '@/store/layout/layoutActions';
import { connect } from 'react-redux';
import loginImage from '@/assets/images/error.png';
import './Error404Page.scss';

const Error404Page = (props) => {

  // hide/show header footer
  useEffect(() => {

    // hiding header footer
    toggleHeaderFooter(false);

    // showing header footer
    return () => toggleHeaderFooter(true);

  }, []);

  const toggleHeaderFooter = (show) => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }

  return (

    <Box className="error-container">

      <div id="upSide">
        <h1 className="error-code">404</h1>
      </div>
      <div id="downSide">
        <h2 className="error-msg">Sorry, Page Not Found</h2>
        <p className="error-description my-2">The page you requested could not be found</p>
        <Link to="/">
          <button className="btn btn-outline-secondary my-2">GO BACK HOME</button>
        </Link>
      </div>

    </Box>

  );
}

const mapDispatchToProps = (dispatch) => ({
  showHideHeader: (shouldShow) => dispatch(headerShowHide(shouldShow)),
  showHideFooter: (shouldShow) => dispatch(footerShowHide(shouldShow))
});

export default connect(null, mapDispatchToProps)(Error404Page);
