import { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TextField, FormControl, FormControlLabel, Button, InputLabel, MenuItem, Select, Switch, CircularProgress, Box
} from '@mui/material';
import ModalComp from '../util/Modal/ModalComp';
import { USER_ADDRESS_TYPES, USER_ADDRESS } from '@/util/constants';
import { formSingleValidator, formValidator, validationRules } from '@/util/formValidation';
import _ from 'lodash';
import { saveAddress, updateAddress, updateUserAddresses } from '@/store/user/userActions';
import { cartDeliveryAddressUpdate } from '@/store/cart/cartActions';
import useNotify from '@/hooks/useNotify';


const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const defaultAddressFields = {
  house: '',
  street: '',
  city: '',
  county: '',
  postCode: '',
  note: '',
}

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    house: false,
    street: false,
    city: false,
    county: false,
    postcode: false,
    note: false,
  }
};

//handle address fields minimum errors
function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) || 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function UserAddressAddEdit({
  address, showEditPopUp, showEditPopUpHandler, clearSelectedAddressHandler, ...otherProps
}) {
  const [notify] = useNotify();

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  const classes = {

    formControl: {
      margin: (theme) => theme.spacing(1),
      minWidth: 120,
      maxWidth: '100vw',
    },
    selectEmpty: {
      marginTop: (theme) => theme.spacing(2),
    },
    form: {
      maxWidth: 400
    },
    fieldAddressType: {
      minWidth: 250,
      marginTop: (theme) => theme.spacing(2)
    },
    defaultAddressControl: {
      verticalAlign: 'bottom',
      marginLeft: (theme) => theme.spacing(2)
    },
    addressTypeOption: {
      textTransform: 'uppercase'
    },
    submit: {
      marginTop: 5,
    }

  };

  const [addressType, setAddressType] = useState(() => USER_ADDRESS_TYPES[0]);

  const [checkAddress, setCheckAddress] = useState({
    makeDefault: true,
  });

  const [addressFormState, addressFormHandler] = useState({
    defaultAddressFields
  });

  const [buttonText, setButtonText] = useState('Add Address');

  const [addressTitle, setAddressTitle] = useState('Add New Address');

  const [isloading, setIsLoading] = useState(false);

  // mapt address to state
  useEffect(() => {

    if (address === null) {
      setButtonText('Add Address');
      setAddressTitle('Add New Address');
      return;
    }

    addressFormHandler({
      house: address.house_no,
      street: address.street_name,
      city: address.city,
      county: address.county,
      postCode: address.postcode,
      note: address.note
    });

    setButtonText('Save Changes');
    setAddressTitle('Update Address');

    setCheckAddress({
      makeDefault: address.is_default
    });

    setAddressType(address.type);

  }, [address]);

  //set validation rules for address fields...
  const validationSchema = {
    house: [
      // validationRules.required(),
      // validationRules.string(),
      // validationRules.containsNumber([1]),
      // validationRules.min([1]),
      validationRules.max([30])
    ],
    street: [
      validationRules.required(),
      validationRules.string(),
      validationRules.min([3]),
    ],
    city: [
      validationRules.required(),
      validationRules.string(),
      validationRules.min([3]),
    ],
    county: [
      validationRules.required(),
      validationRules.string(),
      validationRules.min([3]),
    ],
    postCode: [
      validationRules.required(),
      validationRules.string(),
      validationRules.min([5]),
    ],
    note: [
      validationRules.min([10]),
      validationRules.max([50])
    ]
  };


  //handle House No
  const formHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    addressFormHandler({
      ...addressFormState,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);


      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();

  }

  const handleChange = (event) => {
    setAddressType(event.target.value);
  };

  //handle Address make default Switch
  const handleSwitch = (event) => {
    setCheckAddress({ ...checkAddress, [event.target.name]: event.target.checked });
  };

  const addressSubmitHandler = async (e) => {

    setIsLoading(true);

    e.preventDefault();

    const { error, data } = await formValidator(addressFormState, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      setIsLoading(false);
      return;
    }


    if (address === null) {

      const addressData = {
        house_no: addressFormState.house,
        street_name: addressFormState.street,
        city: addressFormState.city,
        county: addressFormState.county,
        postcode: addressFormState.postCode,
        note: addressFormState.note,
        is_default: checkAddress.makeDefault,
        type: addressType
      }

      const response = await otherProps.saveAddress(addressData);

      if (response.status) {
        setIsLoading(false);
        addressFormHandler({
          ...defaultAddressFields
        });
        // hiding popup
        showEditPopUpHandler(false);

      } else {
        // @TODO: use error notification
        setIsLoading(false);
      }
    } else {

      const addressData = {
        house_no: addressFormState.house,
        street_name: addressFormState.street,
        city: addressFormState.city,
        county: addressFormState.county,
        postcode: addressFormState.postCode,
        note: addressFormState.note,
        is_default: checkAddress.makeDefault,
        type: addressType,
        _method: 'patch'
      }
      const updateUrl = `${USER_ADDRESS}/${address.id}`;

      const updateUserAddress = await otherProps.updateAddress(addressData, updateUrl);
      if (updateUserAddress) {
        setIsLoading(false);
        otherProps.cartDeliveryAddressUpdate(updateUserAddress.data);

        addressFormHandler({
          ...defaultAddressFields
        });
        showEditPopUpHandler(false);
        notify.success('Address Edited Successfully!!');
      } else {
        setIsLoading(false);
      }
    }

  }

  const handleCloseButton = () => {
    clearSelectedAddressHandler(null);
    showEditPopUpHandler(false);
  };

  const handleAddNew = () => {
    showEditPopUpHandler(true);
    addressFormHandler({
      ...defaultAddressFields
    });
    clearSelectedAddressHandler(null);

  };

  return (
    <ModalComp
      title={addressTitle}
      maxDesktopWidth="50vw"
      clickNode={(
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleAddNew()}
        >
          Add new address
        </Button>
      )}
      open={showEditPopUp}
      hideFooter
      closeHandler={handleCloseButton}
    >
      <Box component="form" noValidate sx={classes.form} onSubmit={addressSubmitHandler}>

        <TextField
          type="text"
          variant="outlined"
          margin="dense"
          fullWidth
          name="house"
          label="House No."
          autoComplete="off"
          id="house"
          error={!!errorState.fields.house}
          helperText={errorState.fields.house}
          value={addressFormState.house}
          onChange={formHandler}
        />

        <TextField
          type="text"
          variant="outlined"
          margin="dense"
          required
          fullWidth
          name="street"
          label="Street"
          autoComplete="off"
          id="street"
          error={!!errorState.fields.street}
          helperText={errorState.fields.street}
          value={addressFormState.street}
          onChange={formHandler}
        />

        <TextField
          type="text"
          variant="outlined"
          margin="dense"
          required
          fullWidth
          name="city"
          label="City"
          autoComplete="off"
          id="city"
          error={!!errorState.fields.city}
          helperText={errorState.fields.city}
          value={addressFormState.city}
          onChange={formHandler}
        />

        <TextField
          type="text"
          variant="outlined"
          margin="dense"
          required
          fullWidth
          name="county"
          label="County"
          autoComplete="off"
          id="county"
          error={!!errorState.fields.county}
          helperText={errorState.fields.county}
          value={addressFormState.county}
          onChange={formHandler}
        />

        <TextField
          type="text"
          variant="outlined"
          margin="dense"
          required
          fullWidth
          name="postCode"
          label="Post Code"
          autoComplete="off"
          id="postcode"
          error={!!errorState.fields.postCode}
          helperText={errorState.fields.postCode}
          value={addressFormState.postCode}
          onChange={formHandler}
        />

        <TextField
          type="text"
          variant="outlined"
          margin="dense"
          fullWidth
          name="note"
          label="Address note"
          autoComplete="off"
          id="note"
          error={!!errorState.fields.note}
          helperText={errorState.fields.note}
          value={addressFormState.note}
          onChange={formHandler}
        />

        <FormControl variant="outlined" sx={classes.fieldAddressType}>
          <InputLabel id="demo-simple-select-outlined-label">Address Type</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={addressType}
            onChange={handleChange}
            label="Address Type"
          >
            {
              USER_ADDRESS_TYPES.map((addressType) => (
                <MenuItem key={addressType} value={addressType} sx={classes.addressTypeOption}>{addressType}</MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={classes.defaultAddressControl}>
          <FormControlLabel
            value="end"
            name="makeDefault"
            checked={checkAddress.makeDefault}
            onChange={handleSwitch}
            control={<Switch color="primary" />}
            label={checkAddress.makeDefault ? 'Default' : 'Not default'}
            labelPlacement="end"
          />
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={classes.submit}
        >
          {buttonText}
          {
            isloading
            && <CircularProgress size={25} style={{ marginLeft: 7 }} />
          }
        </Button>
      </Box>
    </ModalComp>
  );
}

UserAddressAddEdit.propTypes = {
  address: PropTypes.object,
  showEditPopUp: PropTypes.bool,
  showEditPopUpHandler: PropTypes.func,
  clearSelectedAddressHandler: PropTypes.func
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});


const mapDispatchToProps = (dispatch) => ({
  saveAddress: (addressData) => dispatch(saveAddress(addressData)),
  updateAddress: (updateData, url) => dispatch(updateAddress(updateData, url)),
  cartDeliveryAddressUpdate: (address) => dispatch(cartDeliveryAddressUpdate(address)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAddressAddEdit);
