import {
 Button, Card, CardContent, Grid, Hidden, List, Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { utilStyle } from '../util/UtilStyle';
import MenuItem2 from './menuItem2';
import './categoryMenu.scss';
import { updateShowLeftSideBar } from '@/store/layout/layoutActions';
import { useEffect, useState } from 'react';


function MobileMenuItems({ categoryName, ...otherProps }) {


  const groupItems = (item) => {

    if (!item.is_group) return [];

    const groupItems = otherProps.stateItems[categoryName]?.filter((itm) => itm.group_id === item.id);
    return groupItems;

  };


  return (
    <List component="nav">

      <Grid className="item-card">


        <CardContent className="CardContent">
          {
            otherProps.stateItems[categoryName]?.map((item) => (
              <MenuItem2 key={item.id} item={item} groupItems={groupItems(item)} />
            ))
          }

        </CardContent>


      </Grid>

    </List>
  );
}

const mapStateToProps = (state) => ({
  selectedCategoryFromState: state.menu.selectedCategory,
  selectedItemsFromState: state.menu.selectedItems,
  stateCategories: state.menu.categories,
  stateItems: state.menu.items,
});

const mapDispatchToProps = (dispatch) => ({
  updateShowLeftSideBar: (isUpdate) => dispatch(updateShowLeftSideBar(isUpdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuItems);
