/* eslint-disable default-param-last */

import {
  SETTING_GET_OPENING_HOURS_FAIL,
  SETTING_GET_OPENING_HOURS_REQUEST,
  SETTING_GET_OPENING_HOURS_SUCCESS,
  SETTING_VALIDATE_OPENING_HOURS_STATE,
} from './openingTypes';

const defaultState = {
  loading: false,
  isOffDay: false,
  isOpen: false,
  willOpen: false, // if not yet opened, but it'll
  collection: true, // this type of order available or not
  delivery: true,
  orderTiming: {
    today: true, // if the timing is for today (or tomorrow)
    hours: [],
  },
  data: [],
  errorMsg: '',
  errors: {}
};

const reducer = (state = defaultState, { type, payload }) => {

  switch (type) {

    case SETTING_GET_OPENING_HOURS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case SETTING_GET_OPENING_HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        errorMsg: '',
        errors: {},
      }

    case SETTING_GET_OPENING_HOURS_FAIL:
      return {
        ...state,
        loading: false,
        data: [],
        error: payload?.message,
        errors: payload?.errors || {}
      }

    case SETTING_VALIDATE_OPENING_HOURS_STATE:
      return {
        ...state,
        isOffDay: payload.isOffDay,
        isOpen: payload.isOpen,
        willOpen: payload.willOpen,
        delivery: payload.delivery,
        collection: payload.collection,
        orderTiming: payload.orderTiming
      }

    default:
      return state
  }

};

export default reducer;
