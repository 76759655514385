import CartPreview from '@/components/cart/cartPreview';
import AddPoint from '@/components/order/addPoint';
import AddressSelection from '@/components/order/addressSelection';
import CardPayment from '@/components/order/cardPayment';
import CouponSelection from '@/components/order/couponSelection';
import DateTimeComp from '@/components/order/dateTimeComp';
import PageHeader from '@/components/pageHeader/pageHeader';
import SlideAnimation from '@/components/util/Animations/SlideAnimation';
import PageLoader from '@/components/util/PageLoader';
import useNotify from '@/hooks/useNotify';
import useSetting from '@/hooks/useSetting';
import {
  cartCouponUpdate,
  cartDeliveryChargeUpdate,
  cartDiscountUpdate, cartOrderTypeUpdate,
  cartPaymentMethodUpdate,
  updateAdjustPointMoney,
  updateCartOrderPoints,
  updateCartPointsToMoney
} from '@/store/cart/cartActions';
import { updateOpeningHourState } from '@/store/opening/openingActions';
import { urlRedirectSet } from '@/store/url/urlActions';
import { updateUserPoints } from '@/store/user/userActions';
import {
  API_CONFIRM_ORDER,
  API_DELIVERY_CHARGE,
  API_DISCOUNT_APPLY,
  API_STORE_ORDER,
  API_USER_POINTS,
  CURRENCY_SYMBOL,
  ORDER_TYPE_COLLECTION,
  ORDER_TYPE_DELIVERY,
  PAYMENT_API_FORM_DEV_URL,
  PAYMENT_API_FORM_PROD_URL,
  SQL_DATETIME_FORMAT,
  STRIPE_PAYMENT_KEY_DEV,
  STRIPE_PAYMENT_KEY_PROD
} from '@/util/constants';
import routes from '@/util/routes';
import { apiRequest, getCurrentTime } from '@/util/util';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Hidden,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


const styles = {
  root: {
    width: '100%',
  },
  button: {
    marginTop: (theme) => theme.spacing(1),
    marginRight: (theme) => theme.spacing(1),
  },
  actionsContainer: {
    marginTop: 3,
    marginBottom: 1
  },
  resetContainer: {
    padding: (theme) => theme.spacing(3),
  },
};

function OrderConfirmationPage({ ...otherProps }) {

  const [notify] = useNotify();

  const navigate = useNavigate();

  const getLocation = useLocation();

  const [loading, setLoading] = useState(false);

  const [btnOrderTxt, setBtnOrderTxt] = useState('Place My Order');

  const [btnEnable, setBtnEnable] = useState(false);

  const appSetting = useSetting([
    'app_debug',
    'points_enabled',
  ]);

  const [pointApiObject, setPointApiObject] = useState({});

  const [lastDiscount, setLastDiscount] = useState(() => ({ ...otherProps.orderDiscount }));

  const [allowNext, setAllowNext] = useState({
    allow: true,
    msg: ''
  });

  const setAllowNextStep = (msg = '') => {
    setAllowNext({
      allow: !msg.length,
      msg
    });
  };

  // checking if user login or not
  useEffect(() => {

    if (!otherProps.user.isLoggedIn) {

      otherProps.setUrlRedirect(getLocation.pathname, routes.login);

      // redirect
      navigate(routes.login);

    }
  }, [otherProps.user.isLoggedIn]);

  // change order type if necessary
  useEffect(() => {

    const currentOrderType = otherProps.orderType;

    const allowedOrderTypes = [];
    if (otherProps.orderDelivery) allowedOrderTypes.push(ORDER_TYPE_DELIVERY);
    if (otherProps.orderCollection) allowedOrderTypes.push(ORDER_TYPE_COLLECTION);

    // if today closed & tomorrow available
    if (!otherProps.isOpen && !otherProps.willOpen && otherProps.isTomorrowAvailable) {

      // check if current order type is in allowed
      if (allowedOrderTypes.includes(currentOrderType)) return;

      // change order type
      const newOrderType = allowedOrderTypes[0];
      if (!newOrderType) return;

      otherProps.cartOrderTypeUpdate(newOrderType);

      notify.warning(`
      Your order has been changed from ${currentOrderType} to ${newOrderType}
      as ${currentOrderType} orders is not available tomorrow
      `);
    }

    // when closed & tomorrow not available
    if (
      !otherProps.isOpen
      && !otherProps.willOpen
      && !otherProps.isTomorrowAvailable
      && otherProps.openingDaysData.length
    ) {
      notify.warning('Too late! Today we\'re closed now.');
      navigate(routes.order);
    }

    // when open but selected order type is not available
    const isValidOrderType = allowedOrderTypes.includes(currentOrderType);
    if (otherProps.isOpen && !isValidOrderType) {
      notify.warning(`${currentOrderType} orders unavailable`);
      navigate(routes.order);
    }

  }, [
    otherProps.isOpen,
    otherProps.willOpen,
    otherProps.isTomorrowAvailable,
    otherProps.orderDelivery,
    otherProps.orderCollection,
  ]);

  useEffect(() => {

    // recalculate time if opening data exists
    if (otherProps.openingDaysData.length) {
      otherProps.validateOpeningState();
    }

    // reset cart
    otherProps.updateCartOrderPoints(0);

    otherProps.updateCartPointsToMoney(0);

    otherProps.updateAdjustPointMoney(0);

    otherProps.cartPaymentMethodUpdate('');
  }, []);

  //is user login then calculate discount over order type
  async function getDiscount() {
    otherProps.cartDeliveryChargeUpdate('', parseFloat(0));

    if (!otherProps.user.isLoggedIn) return;

    const totalAmount = (
      otherProps.totalItemCost
      + otherProps.totalAddonsCost
      + otherProps.cart.setMenusTotal
    ).toFixed(2);


    const discountRequestData = {
      total: totalAmount,
      order_type: otherProps.orderType.toLowerCase(),
    };

    const response = await apiRequest.post(API_DISCOUNT_APPLY, discountRequestData);
    const discount = response.data.data;

    if (response.data.status) {

      const discountSaveData = {
        id: discount.id,
        amount: discount.amount.toFixed(2),
        type: discount.type,
        value: discount.value,
      };

      otherProps.cartDiscountUpdate(discountSaveData);

      notify.success('Discount Applied!!');


    } else if (response.data.message !== 'Not available') {

      notify.info(response.data.message);

    }
  }

  useEffect(() => {

    getDiscount();

  }, [otherProps.user.isLoggedIn, otherProps.orderType]);

  const stepsData = useMemo(() => {

    const data = {
      step: 3,
      deliverySteps: [
        'Select Time',
        'Select Address',
        'Add Coupon (if you have)',
        'Complete Payment'
      ],
      collectionSteps: [
        'Select Time',
        'Add Coupon (if you have)',
        'Complete Payment'
      ]
    };

    if (appSetting.points_enabled) {
      const pointsStepName = 'Use your points';

      data.deliverySteps.splice(2, 0, pointsStepName);
      data.collectionSteps.splice(1, 0, pointsStepName);

      data.step = 4;
    }

    return data;
  }, [appSetting]);

  const totalSteps = useMemo(() => ((otherProps.orderType === ORDER_TYPE_DELIVERY)
    ? stepsData.deliverySteps
    : stepsData.collectionSteps), [otherProps.orderType]);

  function getDeliveryContent(step) {

    switch (step) {
      case 0:
        return <DateTimeComp setAllowNext={setAllowNextStep} />

      case 1:
        return <AddressSelection setAllowNext={setAllowNextStep} />;

      case 2:
        if (appSetting.points_enabled) {
          return <AddPoint orderPoints={pointApiObject} setAllowNext={setAllowNextStep} />;
        }
        return (
          <CouponSelection
            setAllowNext={setAllowNextStep}
            lastDiscount={lastDiscount}
            setLastDiscount={setLastDiscount}
          />
        );

      case 3:
        if (appSetting.points_enabled) {
          return (
            <CouponSelection
              setAllowNext={setAllowNextStep}
              lastDiscount={lastDiscount}
              setLastDiscount={setLastDiscount}
            />
          );
        }
        return <CardPayment setAllowNext={setAllowNextStep} />;

      case 4:
        return <CardPayment setAllowNext={setAllowNextStep} />;

      default:
        return 'Unknown step';
    }
  }

  function getCollectionContent(step) {
    switch (step) {

      case 0:
        return <DateTimeComp setAllowNext={setAllowNextStep} />

      case 1:
        if (appSetting.points_enabled) {
          return <AddPoint orderPoints={pointApiObject} setAllowNext={setAllowNextStep} />;
        }
        return (
          <CouponSelection
            setAllowNext={setAllowNextStep}
            lastDiscount={lastDiscount}
            setLastDiscount={setLastDiscount}
          />
        );

      case 2:
        if (appSetting.points_enabled) {
          return (
            <CouponSelection
              setAllowNext={setAllowNextStep}
              lastDiscount={lastDiscount}
              setLastDiscount={setLastDiscount}
            />
          );
        }
        return <CardPayment setAllowNext={setAllowNextStep} />;

      case 3:
        return <CardPayment setAllowNext={setAllowNextStep} />;

      default:
        return 'Unknown step';
    }
  }

  // redirect user to menu page if there's no item in cart
  useEffect(() => {
    if (!otherProps.cart.items.length && !otherProps.cart.set_menus.length) {

      notify.warning('Please add some item to continue.');
      navigate(routes.order);
    }

  }, []);

  const [activeStep, setActiveStep] = useState(0);

  const getStripePromise = useMemo(() => (
    appSetting.app_debug
      ? loadStripe(STRIPE_PAYMENT_KEY_DEV)
      : loadStripe(STRIPE_PAYMENT_KEY_PROD)
  ), [appSetting.app_debug]);

  const handleNext = async () => {

    // don't go to next step if not allowed
    if (!allowNext.allow) {
      notify.warning(allowNext.msg);
      return;
    }

    // handle time, Allow if time is ASAP
    if (!otherProps.isASAPTime && !otherProps.deliveryTime) {
      notify.error('Please select valid time');
      return;
    }

    const currentTime = getCurrentTime(true);

    // check if already closed
    if (otherProps.isASAPTime) {
      const isValidAsapTime = otherProps.openingTime.map((hours) => (
        (currentTime.toMillis() >= hours.from.toMillis())
        && (currentTime.toMillis() <= hours.to.toMillis())
      ));

      //if any element return true then restaurant is open then length swill more than 0
      const shouldOpenLen = isValidAsapTime.filter((v) => v).length;

      if (shouldOpenLen === 0) {
        notify.error('Restaurant has closed now');
        return;
      }

    }


    // 1st step: Time selection
    if (activeStep === 0) {

      const orderTimeRange = otherProps.openingTime.find((timing) => (
        (timing.from.toMillis() <= otherProps.deliveryTime.toMillis())
        && (timing.to.toMillis() >= otherProps.deliveryTime.toMillis())
      ));

      // do not check order types for collection orders with ASAP time
      if (!(
        otherProps.isASAPTime
        && otherProps.orderCollection
        && (otherProps.orderType === ORDER_TYPE_COLLECTION)
      )) {
        // check validity of time
        if (orderTimeRange === undefined) {

          // if no time available
          notify.warning('Please select a valid time.');
          return;
        }

        // check if current order type allowed in selected time (1st step)
        if (
          !otherProps.isASAPTime
          && (
            (otherProps.orderType === ORDER_TYPE_DELIVERY && !orderTimeRange.delivery)
            || (otherProps.orderType === ORDER_TYPE_COLLECTION && !orderTimeRange.collection)
          )
        ) {
          notify.warning(`${otherProps.orderType} orders are not available at your selected time, Please select valid time`);
          return;
        }

      }

    }


    // check if selected time has passed
    if (
      otherProps.isToday
      && !otherProps.isASAPTime
      && otherProps.deliveryTime
    ) {

      const selectedTime = otherProps.deliveryTime;

      if (currentTime.toMillis() > selectedTime.toMillis()) {
        notify.warning('Selected time has gone, please select next available time');
        return;
      }
    }

    if (otherProps.orderType === ORDER_TYPE_DELIVERY) {
      // handle address
      if (_.isEmpty(otherProps.deliveryAddress) && activeStep === 1) {
        notify.error('Please select an address');
        return;
      }
    }

    // save draft order after coupon step
    if (activeStep === totalSteps.length - 2) {
      setBtnEnable(true);
      setLoading(true);
      //draft order api call handle here
      const draftOrder = await saveDraftOrder();
      if (!draftOrder.status) {
        setLoading(false);
        setBtnEnable(false);

        notify.error('Something went wrong...');
        return;
      }
      setLoading(false);
      setBtnEnable(false);
    }

    // points step
    if (activeStep === totalSteps.length - 4 && appSetting.points_enabled) {
      setLoading(true);
      try {

        let totalAmount = otherProps.totalItemCost + otherProps.totalAddonsCost;
        totalAmount -= otherProps.orderDiscount.value;
        totalAmount -= otherProps.orderCoupon.value;

        const pointsObject = {
          total_amount: totalAmount,
        }

        const response = await apiRequest.post(API_USER_POINTS, pointsObject);
        const points = response.data.data;

        if (response.data.status) {

          setLoading(false)
          otherProps.updateUserPoints(parseInt(points.total_points));
          setPointApiObject(points);

        } else {

          setLoading(false);
          notify.error('Something went wrong, Please try later');
          return;
        }

      } catch (e) {
        setLoading(false);
        notify.error('Something went wrong, Please try later');
        return;
      }

    }

    // save order
    if (activeStep === totalSteps.length - 1) {

      setBtnEnable(true);
      setLoading(true);
      setBtnOrderTxt('Processing...');

      // save order
      const getOrderResponse = await saveOrder();

      if (getOrderResponse?.status) {

        setLoading(false);
        setBtnOrderTxt('Place My Order');
        setBtnEnable(false);

        notify.success('Your order has been placed');
        // send to success page
        navigate(routes.orderSuccess);

      } else {

        setLoading(false);
        setBtnEnable(false);
        setBtnOrderTxt('Place My Order');

        notify.error('Something went wrong, Please try again in a while.');
      }
    }

    // address step
    if (activeStep === totalSteps.length - stepsData.step && otherProps.orderType === 'Delivery') {

      setLoading(true);
      setBtnEnable(true);
      const totalItemCost = otherProps.totalItemCost + otherProps.totalAddonsCost;

      const deliveryObject = {
        total: totalItemCost.toFixed(2),
        postcode: otherProps.deliveryAddress.postcode,
      }

      const deliveryChargeResponse = await apiRequest.post(API_DELIVERY_CHARGE, deliveryObject);

      if (deliveryChargeResponse.data.status) {

        setLoading(false);
        setBtnEnable(false);

        otherProps.cartDeliveryChargeUpdate(
          deliveryChargeResponse.data.data.distance,
          deliveryChargeResponse.data.data.charge
        );

        if (deliveryChargeResponse.data.data.charge === 0) {
          notify.success('You are getting free delivery');

        } else {
          notify.info(`Delivery Charge Added ${CURRENCY_SYMBOL} ${deliveryChargeResponse.data.data.charge}`);
        }
      } else {

        setLoading(false);
        setBtnEnable(false);

        notify.error(deliveryChargeResponse.data.message);
        return; //don't let user to next step if there has any error in address selection.
      }


    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const saveDraftOrder = async () => {

    const { cart } = otherProps;

    const orderRequestData = {
      items: cart.items.map((itm) => ({
        id: itm.id,
        qty: itm.qty,
        addons: itm?.selected_addon.map((addon) => addon.id),
      })),

      set_menus: cart.set_menus.map((itm) => ({
        id: itm.id,
        qty: itm.qty,
        items: itm?.Additional_items.map((addItem) => ({
          id: addItem.id,
          addons: addItem?.selected_addon.map((addon) => addon.id),
        })),
      })),
      coupon: {
        ...cart.coupon,
        amount: cart.coupon.value.toFixed(2),
      },
      discount: {
        ...cart.discount,
        amount: cart.discount.value.toFixed(2),
      },
      points: cart.orderPoint,
      delivery: {
        address_id: cart.delivery.address.id,
        charge: cart.delivery.charge,
        distance: cart.delivery.distance,
        time: cart.delivery.isAsapTime ? '' : cart.delivery.time.toFormat(SQL_DATETIME_FORMAT),
        is_asap: cart.delivery.isAsapTime
      },
      order: cart.order,
    };

    if (otherProps.orderType === 'Collection') {
      orderRequestData.delivery = {
        address_id: '',
        time: cart.delivery.isAsapTime ? '' : cart.delivery.time.toFormat(SQL_DATETIME_FORMAT),
        is_asap: cart.delivery.isAsapTime,
      };
    }

    try {

      const orderResponse = await apiRequest.post(API_STORE_ORDER, orderRequestData);
      return orderResponse.data;

    } catch (e) {
      notify.error('Something went wrong, Please try again in a while.');
      setLoading(false);

      setBtnOrderTxt('Place My Order');

      setBtnEnable(false);

      return { status: false }
    }

  }

  const saveOrder = async () => {

    const { cart } = otherProps;

    const orderRequestData = {

      payment: {
        method: cart.payment.method,
        status: false,
        trxId: '',
      }
    };

    try {

      const orderResponse = await apiRequest.post(API_CONFIRM_ORDER, orderRequestData);
      return orderResponse.data;

    } catch (e) {
      notify.error('Something went wrong, Please try again in a while.');
      setLoading(false);

      setBtnOrderTxt('Place My Order');

      setBtnEnable(false);

      return { status: false }
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const backToMenuPage = () => {
    navigate(routes.order);
  };

  const getPaymentFormUrl = () => (appSetting.app_debug ? PAYMENT_API_FORM_DEV_URL : PAYMENT_API_FORM_PROD_URL)

  return (
    <>

      <Helmet>
        <script src={getPaymentFormUrl()} />
      </Helmet>


      <Elements stripe={getStripePromise}>
        <SlideAnimation in>
          <>
            <PageLoader show={loading} />
            <PageHeader
              pageName="Order Confirmation"
              breadCrumbComponentPadding="10px 0"
              breadCrumbComponent={(
                <Button
                  variant="contained"
                  color="primary"
                  onClick={backToMenuPage}
                >
                  <ArrowBackIosIcon />
                  Back To Menu
                </Button>
              )}
            />

            <Grid container justifyContent="center" mb={3} mt={2}>
              <Grid item xs={12} md={8}>
                <Box sx={styles.root}>

                  <Stepper activeStep={activeStep} orientation="vertical" className="order-confirmation-stepper">
                    {totalSteps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel sx={{
                          backgroundColor: '#cdcdcf',
                          paddingLeft: 1,
                          borderRadius: 1,
                        }}
                        >
                          {label}
                        </StepLabel>

                        <StepContent sx={{ marginTop: '10px' }}>

                          {/*content*/}
                          {
                            otherProps.orderType === ORDER_TYPE_DELIVERY
                            && <Typography component={Box}>{getDeliveryContent(index)}</Typography>

                          }

                          {
                            otherProps.orderType === ORDER_TYPE_COLLECTION
                            && <Typography component={Box}>{getCollectionContent(index)}</Typography>

                          }

                          {/*buttons*/}
                          <Box
                            sx={styles.actionsContainer}
                          >
                            <div>
                              <Button
                                disabled={activeStep === 0 || loading}
                                onClick={handleBack}
                                sx={styles.button}
                              >
                                Back
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={btnEnable}
                                onClick={handleNext}
                                sx={styles.button}
                              >
                                {
                                  loading
                                  && (
                                    <CircularProgress
                                      size={25}
                                      sx={{
                                        color: 'white',
                                        marginRight: 1
                                      }}
                                    />
                                  )
                                }
                                {activeStep === totalSteps.length - 1 ? btnOrderTxt : 'Next'}

                              </Button>
                            </div>
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>

                  {activeStep === totalSteps.length && (
                    <Paper square elevation={0} sx={styles.resetContainer} style={{ marginBottom: 7 }}>
                      <Typography>Something Went Wrong - Please&apos;Try Again.</Typography>
                      <Button onClick={handleReset} sx={styles.button}>
                        Try again
                      </Button>
                    </Paper>
                  )}

                </Box>
              </Grid>

              <Hidden lgDown>
                <Grid item xs={12} md={4}>
                  <CartPreview />
                </Grid>
              </Hidden>
            </Grid>
          </>
        </SlideAnimation>
      </Elements>

    </>
  );

}

const mapStateToProps = (state) => ({
  user: state.user,
  cart: state.cart,
  isToday: state.opening.orderTiming.today,
  isOpen: state.opening.isOpen,
  willOpen: state.opening.willOpen,
  isTomorrowAvailable: (!state.opening.orderTiming.today && state.opening.orderTiming.hours.length),
  orderDelivery: state.opening.delivery,
  orderCollection: state.opening.collection,
  openingTime: state.opening.orderTiming.hours,
  openingDaysData: state.opening.data,
  UnavailableOrderTypeWithTime: state.opening.unavailableOrderTypeWithSelectedTime,
  isASAPTime: state.cart.delivery.isAsapTime,
  deliveryTime: state.cart.delivery.time,
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderDiscount: state.cart.discount,
  orderCoupon: state.cart.coupon,
  deliveryAddress: state.cart.delivery.address,
  orderType: state.cart.order.type,
});

const mapDispatchToProps = (dispatch) => ({
  setUrlRedirect: (from, to) => dispatch(urlRedirectSet(from, to)),
  cartDeliveryChargeUpdate: (distance, charge) => dispatch(cartDeliveryChargeUpdate(distance, charge)),
  cartCouponUpdate: (coupon) => dispatch(cartCouponUpdate(coupon)),
  cartDiscountUpdate: (discount) => dispatch(cartDiscountUpdate(discount)),
  cartOrderTypeUpdate: (type) => dispatch(cartOrderTypeUpdate(type)),
  updateCartOrderPoints: (points) => dispatch(updateCartOrderPoints(points)),
  updateCartPointsToMoney: (amount) => dispatch(updateCartPointsToMoney(amount)),
  updateUserPoints: (points) => dispatch(updateUserPoints(points)),
  updateAdjustPointMoney: (amount) => dispatch(updateAdjustPointMoney(amount)),
  cartPaymentMethodUpdate: (method) => dispatch(cartPaymentMethodUpdate(method)),
  validateOpeningState: () => dispatch(updateOpeningHourState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationPage);
