import { useEffect, useState } from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { GET_TODAYS_ORDER } from '@/util/constants';
import { apiRequest } from '@/util/util';
import OrderListItem from '@/components/order/OrderListItem';
import '@/components/order/OrderList.scss';


function OrderList() {

  const [isLoading, setIsLoading] = useState(true);

  const [loadMessage, setLoadMessage] = useState('Please Wait');

  const [orderHistoryData, setOrderHistoryData] = useState([]);


  useEffect(() => {

    async function getTodayOrders() {

      try {
        const responseData = await apiRequest.get(GET_TODAYS_ORDER);
        setOrderHistoryData(responseData.data);
        if (responseData.data.data.length) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setLoadMessage('You have no order today.');
        }
      } catch (e) {
        setLoadMessage('You have no order today.');
      }
    }

    getTodayOrders();

  }, []);


  return (
    <TableContainer className="tableHelmet" style={{ textAlign: 'center' }}>
      <Table aria-label="collapsible table">

        {
          orderHistoryData.data?.length != 0
          && (
            <TableBody>

              {
                isLoading
                && <CircularProgress />
              }

              {orderHistoryData.data?.map((order, idx) => (

                <OrderListItem
                  key={idx}
                  order={order}
                  index={idx + 1}
                />
              ))}

            </TableBody>
          )
        }

        {
          !orderHistoryData.data?.length
          && (
            <TableBody>
              <TableRow>
                <TableCell className="profile-padding no-order-text">
                  {loadMessage}
                </TableCell>
              </TableRow>
            </TableBody>
          )
        }
      </Table>
    </TableContainer>
  );
}


export default OrderList;


