import {
 Box, Divider, Grid, ListItem
} from '@mui/material';
import { connect } from 'react-redux';
import { CURRENCY_SYMBOL } from '@/util/constants';
import {
  cartItemAdd, cartItemAddonsUpdate, cartItemRemove, decreaseSetMenuQty, increaseSetMenuQty, removeSetMenu
} from '@/store/cart/cartActions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useNotify from '@/hooks/useNotify';


function SetMenuCartItem({ item, index, ...otherProps }) {

  const [notify] = useNotify();

  const cartItemAddonStyle = {
    root: {
      backgroundColor: '#f8e4e8'
    }
  };

  const deleteSetMenu = () => {
    otherProps.removeSetMenu(item, index);
  }

  const addSetMenuHandler = () => {
    otherProps.increaseSetMenuQty(item, index);
    notify.success('Item Quantity Increased');
  }

  const removeSetMenuHandler = () => {
    otherProps.decreaseSetMenuQty(item, index);
    notify.warning('Item Quantity Decreased');
  }

  return (
    <ListItem>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Box style={{ fontSize: 17 }}>
              {item.name}
              {
                item?.Additional_items?.length
                && <Box sx={cartItemAddonStyle.root}>With Additions * </Box>
              }

            </Box>

            <Box display="flex">

              <Box mr="5px" fontWeight="900" fontSize="25px" className="hover-pointer">
                <DeleteForeverIcon color="primary" onClick={deleteSetMenu} />
              </Box>

              <Box display="flex" border={1} justifyContent="center" alignItems="center" borderColor="primary.main" borderRadius="10px">
                <Box className="hover-pointer">
                  <RemoveOutlinedIcon color="secondary" onClick={removeSetMenuHandler} />

                </Box>
                <Box ml="6px" mr="6px" fontWeight="900" fontSize="20px">
                  {item.qty}
                </Box>
                <Box className="hover-pointer">
                  <AddOutlinedIcon color="secondary" onClick={addSetMenuHandler} />
                </Box>
              </Box>

              <Box ml={1} fontSize={16}>
                {CURRENCY_SYMBOL}
                {
                  (item.total_cost * item.qty).toFixed(2)
                }
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider />
    </ListItem>
  );
}

const mapStateToProps = (state) => ({
  addons: state.cart.addons,
  cartItems: state.cart.items,
});

const mapDispatchToProps = (dispatch) => ({
  removeSetMenu: (item, index) => dispatch(removeSetMenu(item, index)),
  increaseSetMenuQty: (item, index) => dispatch(increaseSetMenuQty(item, index)),
  decreaseSetMenuQty: (item, index) => dispatch(decreaseSetMenuQty(item, index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetMenuCartItem);
