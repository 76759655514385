import { useRef, useState } from 'react';
import NavItem from './navItem';
import { connect } from 'react-redux';
import Logout from '@/pages/auth/logOut';
import UserAvatar from '../util/UserAvatar';
import { Link } from 'react-router-dom';
import useSetting from '@/hooks/useSetting';
import { SETTING_FILE_PATH } from '@/util/constants';
import routes from '@/util/routes';
import { isMobile } from 'react-device-detect';
import './Nav2.scss';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';


function Nav2({ ...otherProps }) {

  const styles = {
    navbarFixed: {
      width: '100vw',
      position: 'fixed',
      zIndex: 11,
    },
    mobileNavToggle: {
      color: '#f3f3f3'
    }
  };

  const setting = useSetting([
    'phone',
    'site_logo_large',
    'navbar_fixed',
  ]);
  const mobileNavToggleRef = useRef(false);

  const [openNav, setOpenNav] = useState(false);

  const showMenuItems = () => {
    setOpenNav((v) => !v);
  }

  return (
    <Box
      component="header"
      className="w3l-header-nav fixed-top"
      sx={setting.navbar_fixed ? styles.navbarFixed : ''}
      style={{ position: 'sticky' }}
    >
      {/*/nav*/}

      <nav className="navbar navbar-expand-lg navbar-light">
        <Link to="/" className="navbar-brand d-lg-none">
          <img className="nav-logo" src={`${SETTING_FILE_PATH + setting.site_logo_large}`} alt="" />
        </Link>
        <button
          ref={mobileNavToggleRef}
          className={`navbar-toggler ${openNav ? '' : 'collapsed'}`}
          type="button"
          data-toggle="collapse"
          data-target="#menu2"
          aria-controls="menu2"
          aria-expanded={openNav}
          aria-label="Toggle navigation"
          onClick={showMenuItems}
        >
          {/*<span className="navbar-toggler-icon" />*/}
          {
            !openNav && <MenuIcon fontSize="large" sx={styles.mobileNavToggle} />
          }
          {
            openNav && <CloseIcon fontSize="large" sx={styles.mobileNavToggle} />
          }
        </button>
        <div className={`collapse navbar-collapse ${openNav ? 'show' : ''}`} id="menu2">
          <ul className="navbar-nav mx-auto">

            <NavItem name="Home" path="/" mobileMenuToggleRef={mobileNavToggleRef} />
            <NavItem name="Gallery" path="/gallery" mobileMenuToggleRef={mobileNavToggleRef} />
            <NavItem name="Order Online" path="/order" mobileMenuToggleRef={mobileNavToggleRef} />

            <Link to="/" className="d-none d-lg-flex nav-logo-container">
              <img className="nav-logo" src={`${SETTING_FILE_PATH + setting.site_logo_large}`} alt="" />
            </Link>

            <NavItem name="Reservation" path="/reservation" mobileMenuToggleRef={mobileNavToggleRef} />
            <NavItem name="Contact" path="/contact" mobileMenuToggleRef={mobileNavToggleRef} />
            {
              otherProps.isLoggedIn
                ? <Logout name="LogOut" mobileMenuToggleRef={mobileNavToggleRef} />
                : <NavItem name="Login" path="/login" mobileMenuToggleRef={mobileNavToggleRef} />
            }

            {/*/User avatar*/}
            {
              otherProps.isLoggedIn
              && (
              <Link to={routes.userProfile}>
                <div
                  className="search-right mt-1"
                  onClick={() => {
                    if (isMobile) {
                      mobileNavToggleRef?.current?.click();
                    }
                  }}
                >
                  <UserAvatar />
                </div>
              </Link>
)
            }
          </ul>
        </div>
      </nav>

      {/*//nav*/}

    </Box>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data
});

export default connect(mapStateToProps)(Nav2);
