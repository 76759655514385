import CartItem2 from '@/components/cart/cartItem2';
import SetMenuCartItem from '@/components/cart/SetMenuCartItem';
import NumberAnimation from '@/components/util/Animations/NumberAnimation';
import useNotify from '@/hooks/useNotify';
import useSetting from '@/hooks/useSetting';
import {
  cartDiscountUpdate,
  cartItemAdd,
  cartItemAddonsUpdate,
  cartItemRemove,
  cartOrderNoteUpdate,
  cartOrderTypeUpdate,
  clearCoupon,
  clearDiscount
} from '@/store/cart/cartActions';
import { updateOpeningHourState } from '@/store/opening/openingActions';
import { CURRENCY_SYMBOL, ORDER_TYPE_COLLECTION, ORDER_TYPE_DELIVERY } from '@/util/constants';
import { formSingleValidator, formValidator, validationRules } from '@/util/formValidation';
import routes from '@/util/routes';
import { moneyFormat } from '@/util/util';
import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  TextField, Typography
} from '@mui/material';
import _ from 'lodash';
import {
  memo, useEffect, useReducer, useState
} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './cart2.scss';

const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};
const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    note: false,
  }
};

function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) || 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

const styles = {
  root: {
    width: '100%',
    // maxWidth: 420,
    backgroundColor: (theme) => theme.palette.background.paper,
  },
};
const cartBtnStyle = {
  root: {
    width: '100%',
    marginTop: 1,
    marginBottom: 2
  }
};

const TotalPrice = memo(({ total }) => (
  <>
    <Hidden mdUp>
      {CURRENCY_SYMBOL}
      {total.toFixed(2)}
    </Hidden>

    <Hidden lgDown>
      {CURRENCY_SYMBOL}
      <NumberAnimation
        number={total}
        decimal={2}
        component="span"
      />
    </Hidden>
  </>
));

function Cart2({ drawerControlHandler, ...otherProps }) {

  const navigate = useNavigate();
  const [notify] = useNotify();
  const setting = useSetting([
    'order_status',
    'order_disable_message',
  ]);

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);
  const [total, setTotal] = useState(0);
  const [orderType, setOrderType] = useState(otherProps.orderType);

  useEffect(() => {
    setOrderType(otherProps.orderType);
  }, [otherProps.orderType]);

  // if order type is unavailable in opening hour then set it
  useEffect(() => {
    if (!otherProps.deliveryOrder && otherProps.collectionOrder) {
      otherProps.cartOrderTypeUpdate(ORDER_TYPE_COLLECTION);
    }
  }, [otherProps.deliveryOrder, otherProps.collectionOrder]);

  const [note, setNote] = useState({
    note: otherProps?.note,
  });

  const validationSchema = {
    note: [
      validationRules.string(),
      validationRules.min([5]),
      validationRules.max([100]),
    ],
  };

  const noteChangeHandler = (e) => {

    const name = e.target.name;

    const value = e.target.value;

    setNote({
      ...note,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);


      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 200)();
  };

  useEffect(() => {

    otherProps.clearDiscount();
    otherProps.clearCoupon();

  }, [otherProps.cart.items, otherProps.orderType, otherProps.cart.addons]);

  const orderMinimumSetting = useSetting([
    'delivery_order_minimum',
    'collection_order_minimum',
    'delivery_order_delivery_time',
    'collection_order_delivery_time',
  ]);

  const [orderMinimum, setOrderMinimum] = useState(orderMinimumSetting.delivery_order_minimum);

  const [prepareTime, setPrepareTime] = useState(orderMinimumSetting.delivery_order_delivery_time);
  //to store order minimum value in the state from setting.
  //default delivery minimum set

  useEffect(() => {

    if (orderType === ORDER_TYPE_COLLECTION) {
      setOrderMinimum(orderMinimumSetting.collection_order_minimum);
      setPrepareTime(orderMinimumSetting.collection_order_delivery_time);
    } else {
      setOrderMinimum(orderMinimumSetting.delivery_order_minimum);
      setPrepareTime(orderMinimumSetting.delivery_order_delivery_time);

    }

  }, [orderMinimumSetting, setOrderMinimum]);


  const orderTypeHandler = (e) => {
    setOrderType(e.target.value);
    otherProps.cartOrderTypeUpdate(e.target.value);
  };

  const ItemSubmitHandler = async () => {

    if (drawerControlHandler !== undefined) {
      drawerControlHandler('right', false);
    }

    const { error, data } = await formValidator(note, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      return;
    }

    otherProps.cartOrderNoteUpdate(note.note);

    const totalAmount = (otherProps.cart.itemsTotal
      + otherProps.cart.addonsTotal
      + otherProps.cart.setMenusTotal).toFixed(2);

    if (!otherProps.cart.items.length && !otherProps.cart.set_menus.length) {
      notify.warning('Please add some item first');
      return;
    }

    if (orderMinimum > parseFloat(totalAmount)) {
      notify.warning(`Minimum order for ${orderType} is ${CURRENCY_SYMBOL}${orderMinimum}`);
      return;
    }

    navigate(routes.orderConfirmation);

  };

  // update total if needed
  useEffect(() => {
    setTotal(moneyFormat(
      otherProps.cart.itemsTotal
      + otherProps.cart.addonsTotal
      + otherProps.cart.setMenusTotal
    ));
  }, [
    otherProps.cart.itemsTotal,
    otherProps.cart.addonsTotal,
    otherProps.cart.setMenusTotal
  ]);

  if (otherProps.isOpen && !otherProps.collectionOrder && !otherProps.deliveryOrder) {
    return (
      <Box sx={styles.root}>
        <Typography
          sx={{
            marginTop: 2,
            padding: 2,
            border: '1px solid',
            borderColor: (t) => t.palette.primary.main,
            borderRadius: 2,
            color: (t) => t.palette.primary.main,
            fontSize: 18,
          }}
        >
          Please call us for order
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={styles.root}>

      <Card xs={12} className="orderTypeDiv">
        <Box display="flex" justifyContent="space-between">
          <Box className="order-type-title" fontSize={18}>
            {orderType} Minimum
          </Box>
          <Box fontSize={18}>
            {CURRENCY_SYMBOL}
            {orderMinimum}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box className="order-type-title" fontSize={18}>
            {orderType} Time
          </Box>
          <Box fontSize={18}>
            {prepareTime} min
          </Box>
        </Box>
      </Card>

      <Paper
        className="bg-light mb-1"
        sx={{
          borderRadius: 2,
          border: '1px solid',
          borderColor: (t) => t.palette.primary.main
        }}
      >

        <List component="nav">
          <ListItem button>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  className="order-type-title"
                  fontSize={25}
                  textAlign="center"
                  marginTop={1}
                >
                  Choose Order Type
                </Box>
                <RadioGroup value={orderType} color="primary" onChange={orderTypeHandler}>

                  <Box marginTop={1} display="flex" justifyContent="space-between">


                    {
                      otherProps.deliveryOrder && (
                        <Box>
                          <FormControlLabel
                            className="order-type-radio"
                            value={ORDER_TYPE_DELIVERY}
                            control={<Radio color="primary" />}
                            label="Delivery"
                          />
                        </Box>
                      )
                    }

                    {
                      otherProps.collectionOrder && (
                        <Box>
                          <FormControlLabel
                            className="order-type-radio"
                            value={ORDER_TYPE_COLLECTION}
                            control={<Radio color="primary" />}
                            label="Collection"
                          />
                        </Box>
                      )
                    }

                  </Box>
                </RadioGroup>

              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Paper>

      <Paper
        sx={{
          marginTop: 1,
          borderRadius: 2,
          border: '1px solid',
          borderColor: (t) => t.palette.primary.main,
          paddingBottom: 0
        }}
      >
        <List component="nav" sx={{ paddingBottom: 0 }}>
          {
            otherProps.cart.items.map((itm, index) => (
              <CartItem2
                key={`${itm.id}_${index}`}
                item={itm}
                index={index}
              />
            ))
          }

          {
            otherProps.cart.set_menus.map((itm, index) => (
              <SetMenuCartItem key={itm.id} item={itm} index={index} />
            ))
          }

          <ListItem>
            <Divider />
          </ListItem>

          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            className="total-div"
          >
            <Box className="total-text">Total</Box>
            <Box className="total-price">
              <TotalPrice total={total} />
            </Box>
          </Box>

        </List>
      </Paper>


      <Paper className="bg-light mb-1">

        {
          (!otherProps.isOffDay
            && (otherProps.isOpen || otherProps.willOpen)
            && setting.order_status
          ) && (
            <Box>
              <TextField
                multiline
                maxRows={2}
                type="text"
                variant="outlined"
                margin="dense"
                fullWidth
                name="note"
                label="Any Order Instruction ?"
                id="note"
                autoComplete="off"
                error={!!errorState.fields.note}
                helperText={errorState.fields.note}
                value={note.note}
                onChange={noteChangeHandler}
              />
              <Button
                type="button"
                variant="contained"
                color="primary"
                sx={cartBtnStyle.root}
                onClick={ItemSubmitHandler}
                style={{ padding: 10 }}
              >
                {
                  otherProps.willOpen ? 'Pre Order for Later' : 'Continue'
                }
              </Button>
            </Box>
          )
        }

        {
          (
            setting.order_status
            && !otherProps.isOpen
            && !otherProps.willOpen
            && otherProps.isTomorrowAvailable
          ) && (
            <Box>
              <TextField
                multiline
                maxRows={2}
                type="text"
                variant="outlined"
                margin="dense"
                fullWidth
                name="note"
                label="Any Order Instruction ?"
                id="note"
                autoComplete="off"
                error={!!errorState.fields.note}
                helperText={errorState.fields.note}
                value={note.note}
                onChange={noteChangeHandler}
              />
              <Button
                variant="contained"
                color="primary"
                sx={cartBtnStyle.root}
                onClick={ItemSubmitHandler}
              >
                Order for Tomorrow
              </Button>
            </Box>
          )
        }
        {
          !setting.order_status && (
            <Button variant="outlined" color="primary" sx={cartBtnStyle.root} disabled>
              {setting.order_disable_message}
            </Button>
          )
        }

      </Paper>

    </Box>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  isOffDay: state.opening.isOffDay,
  isOpen: state.opening.isOpen,
  willOpen: state.opening.willOpen,
  isTomorrowAvailable: (!state.opening.orderTiming.today && state.opening.orderTiming.hours.length),
  orderType: state.cart.order.type,
  opening: state.opening.data,
  note: state.cart.order.note,
  deliveryOrder: state.opening.delivery,
  collectionOrder: state.opening.collection,
});

const mapDispatchToProps = (dispatch) => ({
  cartAddItem: (item) => dispatch(cartItemAdd(item)),
  cartRemoveItem: (item) => dispatch(cartItemRemove(item)),
  cartUpdateItemAddon: (item, addons) => dispatch(cartItemAddonsUpdate(item, addons)),
  cartOrderTypeUpdate: (type) => dispatch(cartOrderTypeUpdate(type)),
  cartOrderNoteUpdate: (note) => dispatch(cartOrderNoteUpdate(note)),
  cartDiscountUpdate: (discount) => dispatch(cartDiscountUpdate(discount)),
  clearCoupon: () => dispatch(clearCoupon()),
  clearDiscount: () => dispatch(clearDiscount()),
  updateOpeningState: () => dispatch(updateOpeningHourState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart2);
