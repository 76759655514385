import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';

import { OfferState } from '@/store/offer/offerReducer';

//only for development (debug)
// const composeEnhancers = composeWithDevTools({
//   trace: true,
//   traceLimit: 25
// });
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

//only for development
const store = process.env.REACT_APP_APP_MODE === 'production'
  ? createStore(rootReducer, applyMiddleware(thunk))
  : createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
;


//for production
// const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

// export interface State {
//   user: any,
//   layout: any,
//   url: any,
//   cart: any,
//   opening: any,
//   setting: any,
//   seo: any,
//   menu: any,
//
//   offer: OfferState,
// }


export type RootState = ReturnType<typeof store.getState>;
