import { ForwardedRef, forwardRef, ReactNode } from 'react';
import { useSpring, animated } from '@react-spring/web';

type SlideAnimationProps = {
  in: boolean;
  children: ReactNode;
  delay?: number;
  speed?: number;
  onEnter?: () => void;
  onExited?: () => void;
}

const SlideAnimation = forwardRef((
  {
    in: open, delay = 0, speed = 30, children, onEnter, onExited, ...otherProps
  }: SlideAnimationProps,
  ref: ForwardedRef<HTMLDivElement>
) => {

  const style = useSpring({
    from: {
      transform: 'translate3d(100%,0,0)'
    },
    to: {
      transform: open ? 'translate3d(0%,0,0)' : '',
      transitionDuration: `${speed}ms`
    },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    delay,
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (

    <animated.div
      ref={ref}
      style={style}
      {...otherProps}
    >
      {children}
    </animated.div>
  );
});

export default SlideAnimation;
