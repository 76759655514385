import { Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSetting from '@/hooks/useSetting';
import { updateShowPromotionalPopup } from '@/store/layout/layoutActions';
import { SETTING_FILE_PATH } from '@/util/constants';
import ModalComp from '@/components/util/Modal/ModalComp';


function PromotionalSlider2({ ...otherProps }) {

  const setting = useSetting([
    'promotional_img_1',
    'promotional_title_1',
    'promotional_slider_enable',
    'promotional_img_background_color',
    'promotional_title_color',
    'promotional_btn_background_color',
    'promotional_btn_text_color',
    'promotional_extra_btn_show',
    'promotional_extra_btn_title',
    'promotional_extra_btn_link',

  ]);


  const [promotionalModalHandler, setPromotionalModalHandler] = useState(true);

  const navigate = useNavigate();

  const handlePopup = () => {
    setPromotionalModalHandler(false);
    otherProps.updateShowPromotionalPopup(true);
  }

  const routePage = () => {
    navigate(setting.promotional_extra_btn_link);
    otherProps.updateShowPromotionalPopup(true);
  }


  return (
    <>

      {
        setting?.promotional_img_1
        && setting?.promotional_slider_enable
        && !otherProps.promotional_banner_show
        && (
          <ModalComp
            open={promotionalModalHandler}
            hideFooter
            hideHeader
            closeHandler={handlePopup}
            closeOnBackdropClick
            disableFocusEnforcement
            primaryBackground={false}
            cardBackgroundColor={setting.promotional_img_background_color}
            modalBodyPadding="0px"
            maxDesktopWidth="50vw"
            modalCardPosition="relative"
          >
            <Box>

              <CloseIcon className="modalTitleCloseButton" onClick={handlePopup} />

              <img
                className="promotional-image"
                src={`${SETTING_FILE_PATH + setting.promotional_img_1}`}
                alt="restaurants near me"
              />

            </Box>

            <Box textAlign="center">
              <h3 className="promotional-title" style={{ color: setting.promotional_title_color }}>
                {setting.promotional_title_1}
              </h3>
            </Box>

            <Box className="promotional-buttons">
              {/* <Button
              variant="contained"
              className="promotional-btn"
              onClick={handlePopup}
              style={
                {
                  color: setting.promotional_btn_text_color,
                  backgroundColor: setting.promotional_btn_background_color
                }
              }

            >
              Close
            </Button> */}

              {
                setting.promotional_extra_btn_show
                && (
                  <Button
                    variant="contained"
                    onClick={routePage}
                    style={{
                      color: setting.promotional_btn_text_color,
                      backgroundColor: setting.promotional_btn_background_color
                    }}
                  >
                    {setting.promotional_extra_btn_title}
                  </Button>
                )
              }


            </Box>


          </ModalComp>
        )
      }

    </>
  );
}

const mapStateToProps = (state) => ({
  promotional_banner_show: state.layout.show_promotional_popup
});

const mapDispatchToProps = (dispatch) => ({
  updateShowPromotionalPopup: (val) => dispatch(updateShowPromotionalPopup(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalSlider2);



