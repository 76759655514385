import { ReactNode } from 'react';
import '@animxyz/core';
import { XyzTransitionGroup, } from '@animxyz/react';
import { XYZDirection, XYZDirectionScale, XYZDuration } from '@/components/util/Animations/types';


type StackedAnimationProps = {
  children: ReactNode;
  duration: XYZDuration;
  stackDuration: XYZDuration;
  initialAnimation?: boolean;
  tag?: string;
  stackReverseOrder?: boolean;
  additionalAnimateClasses?: string;
  directionIn?: XYZDirection;
  directionOut?: XYZDirection;
  directionScale: XYZDirectionScale;
};

const StackedAnimation = (
  {
    initialAnimation = true,
    children,
    duration,
    tag = 'div',
    stackDuration,
    stackReverseOrder = false,
    additionalAnimateClasses,
    directionIn = 'left',
    directionOut = 'right',
    directionScale
  }: StackedAnimationProps
) => {

  const animationDuration = () => {
    let durationName = 'duration';

    if (duration === undefined) return durationName;

    durationName += `-${duration}`;
    return durationName;
  };

  const animationDirection = () => {

    const inDirection = `in-${directionIn}`;
    const outDirection = `out-${directionOut === undefined ? directionIn : directionOut}`;

    let direction = [inDirection, outDirection];
    if (directionScale === undefined) return direction;

    direction[0] = `${inDirection}-${directionScale}`;
    direction[1] = `${outDirection}-${directionScale}`;
    return direction;
  };

  const animateClasses = () => {

    const stack = stackReverseOrder ? 'stagger-rev' : 'stagger';

    const stackWithTiming = stackDuration === undefined
      ? stack
      : `${stack}-${stackDuration}`
    ;

    return [
      'fade',
      stackWithTiming,
      animationDuration(),
      ...animationDirection(),
      additionalAnimateClasses
    ];
  };

  return (
    <XyzTransitionGroup
      xyz={`${animateClasses().join(' ')}`}
      tag={tag}
      appear={initialAnimation}
    >
      {children}
    </XyzTransitionGroup>
  );
};


export default StackedAnimation;
