import {
  SEO_GET_REQUEST,
  SEO_GET_FAIL,
  SEO_GET_SUCCESS
} from './seoTypes';
import { apiRequest } from '@/util/util';
import { API_GET_SEO } from '@/util/constants';

///// Login
export const seoRequest = () => ({
    type: SEO_GET_REQUEST
  });

export const seoRequestFail = (err) => ({
    type: SEO_GET_FAIL,
    payload: err
  });

export const seoRequestSuccess = (settings) => ({
    type: SEO_GET_SUCCESS,
    payload: settings
  });

export const getSeo = () => async (dispatch) => {

    dispatch(seoRequest());

    try {

      // get seo from api
      const allSeo = await apiRequest.get(API_GET_SEO);

      dispatch(seoRequestSuccess(allSeo.data.data));

    } catch (e) {
      dispatch(seoRequestFail(e.response?.data));
    }

  };
