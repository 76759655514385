import { useEffect, useState } from 'react';
import MainSlider from '@/components/slider/main/MainSlider';
import PromotionalSlider from '@/components/slider/promotionalSlider/PromotionalSlider';
import PromotionalSlider2 from '@/components/slider/promotionalSlider/promotionalSlider2';
import axios from 'axios';
import { connect } from 'react-redux';
import useSetting from '@/hooks/useSetting';
import { red } from '@mui/material/colors';
import { Box } from '@mui/material';
import { SETTING_FILE_PATH } from '@/util/constants';
import { Link } from 'react-router-dom';
import About from '../about/about';
import ReservationComponent from '@/components/reservation/reservation';
import routes from '@/util/routes';
import PageLoader from '@/components/util/PageLoader';
import ReviewSlider from '@/components/slider/ReviewSlider';
import SlideAnimation from '@/components/util/Animations/SlideAnimation';
import { updateFirstArrival } from '@/store/layout/layoutActions';
import pdfMenu from '@/assets/images/shapla_tandoori_menu.pdf';

axios.defaults.withCredentials = true;


const HomePage = ({ ...otherProps }) => {

  const homeSetting = useSetting([
    'allergy_information',
    'site_title',
    'food_img_1',
    'food_img_2',
    'food_img_3',
    'food_img_4',
    'food_img_title_1',
    'food_img_title_2',
    'food_img_title_3',
    'food_img_title_4',
    'review_img_1',
    'review_img_2',
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const [isAnimation, setIsAnimation] = useState(true);

  const [showPromotionalPopup, setShowPromotionalPopup] = useState(false);

  setTimeout(() => {

    setShowPromotionalPopup(true);

  }, 3000)

  useEffect(() => {

    setIsLoading(otherProps.settings.loading);

  }, [otherProps.settings.loading]);


  useEffect(() => {
    if (!isLoading) {
      otherProps.updateFirstArrival(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (otherProps.first_arrival) {
      setIsAnimation(true);
    }
  }, [otherProps.first_arrival]);


  return (
    <>
      <PageLoader show={isLoading} backgroundColor="#ef5c24" />

      <SlideAnimation in={isAnimation}>
        <>

          <MainSlider />

          <h1 hidden>
            Welcome To Shapla Tandoori || Best Indian Takeaway Cambridge
          </h1>

          <h3 hidden>
            Shapla Tandoori is an authentic Indian takeaway located on Shinfield Road in Whitley, where we offer the finest Indian dishes for you to enjoy.
          </h3>

          {/* home page service grids */}
          <section id="services" className="home-services pt-5 pb-5">

            <div className="container pt-3">
              <About showBtn />
            </div>
          </section>

          {
          showPromotionalPopup
          && <PromotionalSlider2 />
          }

          {/* Reservation Component */}

          {/* <ReservationComponent /> */}

          {/* what we do grids */}
          <section className="grids-1 py-5  parallax-food-section ">
            <div className="grids">
              <div className="container">
                <h3 className="title-big text-center">Flavour of Shapla</h3>
                <div className="row text-center grids-gap pt-4">
                  <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-0 mt-4 dish-image">

                    <img
                      src={`${SETTING_FILE_PATH + homeSetting.food_img_1}`}
                      className="img-responsive"
                      alt={homeSetting.food_img_title_1}
                    />
                    <h4 className="food-title "><a className="text-white">{homeSetting.food_img_title_1}</a></h4>

                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-0 mt-4 dish-image">

                    <img
                      src={`${SETTING_FILE_PATH + homeSetting.food_img_2}`}
                      className="img-responsive"
                      alt={homeSetting.food_img_title_2}
                    />
                    <h4 className="food-title "><a className="text-white">{homeSetting.food_img_title_2}</a></h4>

                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-0 mt-4 dish-image">

                    <img
                      src={`${SETTING_FILE_PATH + homeSetting.food_img_3}`}
                      className="img-responsive"
                      alt={homeSetting.food_img_title_3}
                    />
                    <h4 className="food-title "><a className="text-white">{homeSetting.food_img_title_3}</a></h4>

                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-0 mt-4 dish-image">

                    <img
                      src={`${SETTING_FILE_PATH + homeSetting.food_img_4}`}
                      className="img-responsive"
                      alt={homeSetting.food_img_title_4}
                    />
                    <h4 className="food-title "><a className="text-white">{homeSetting.food_img_title_4}</a></h4>

                  </div>
                </div>
              </div>
            </div>
          </section>


          {/* <section id="services" className="w3l-stats  services py-5 ">
            <div className="container">
              <h1 className="title-big text-white text-center fugaz-font">Reviews</h1>
              <div className="row text-center img-grids">
                <div className="col-12 reviewHover">
                  <ReviewSlider />
                </div>
              </div>
            </div>
          </section> */}


          {/*  Allergy section */}
          <section className="w3l-stats parallax-allergy-section">
            <div className="main-w3 py-5" id="stats">


              <div className="container text-center">
                <h2 className="text-white fugaz-font ">Allergy Information</h2>
                <Box marginBottom={3} marginTop={3} className="cover-content">
                  <h5 className="text-white text-left">{homeSetting.allergy_information}</h5>
                </Box>

                <a href={pdfMenu} download className="read-more btn btn-style btn-primary">
                  View Our Menu
                </a>


              </div>

            </div>
          </section>
        </>

      </SlideAnimation>
    </>
);
}

const mapStateToProps = (state) => ({
  user: state.user.data,
  lastUser: state.user.lastUser,
  items: state.menu.items,
  settings: state.setting,
  first_arrival: state.layout.first_arrival,
});

const mapDispatchToProps = (dispatch) => ({
  updateFirstArrival: (IsFirstArrive) => dispatch(updateFirstArrival(IsFirstArrive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
