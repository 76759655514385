import { Backdrop, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';

type PageLoaderProps = {
  show: boolean;
  loaderColor?: '';
  backgroundColor?: '';
};

export default function PageLoader({ show = false, loaderColor, backgroundColor }: PageLoaderProps) {

  const styles = (t: Theme) => ({
    zIndex: t.zIndex.drawer + 1,
    color: loaderColor ?? '#fff !important',
    backgroundColor: backgroundColor ?? ''
  });

  return (
    <Backdrop sx={styles} open={show}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
