import {
  Box, Button, CardContent, Grid, Hidden, List, Typography
} from '@mui/material';
import { connect } from 'react-redux';
import MenuItem2 from './menuItem2';
import './categoryMenu.scss';
import { updateShowLeftSideBar } from '@/store/layout/layoutActions';
import SlideAnimation from '@/components/util/Animations/SlideAnimation';

const errStyles = {
  marginBottom: 2,
  padding: 1,
  color: (t) => t.palette.primary.main,
  fontSize: 23,
  border: 1,
  borderRadius: 2,
};

function CategoryMenu2({ ...otherProps }) {

  const groupItems = (item) => {

    if (!item.is_group) return [];

    const groupItems = otherProps.selectedItemsFromState[0].filter((itm) => itm.group_id === item.id);
    return groupItems;

  };

  const handleSideBar = () => {
    otherProps.updateShowLeftSideBar(true);
  };

  return (
    <List component="nav">

      <Grid className="item-card">

        <CardContent>

          { // when closed & tomorrow is not available
            (!otherProps.isOpen && !otherProps.willOpen && !otherProps.isTomorrowAvailable) && (
              <Typography sx={errStyles}>
                Today we're closed but you can order for later by calling us
              </Typography>
            )
          }

          { // when open but no order type available
            (otherProps.isOpen && !otherProps.collectionOrder && !otherProps.deliveryOrder) && (
              <Typography sx={errStyles}>
                Online ordering is not available now but you can order by calling us
              </Typography>
            )
          }

          {
            otherProps.selectedItemsFromState[0].map((item) => (
              <MenuItem2
                key={item.id}
                item={item}
                groupItems={groupItems(item)}
              />
            ))
          }

        </CardContent>


      </Grid>
      <Hidden mdUp>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSideBar()}
          className="moreMenu"
          style={{ marginLeft: 145, marginBottom: 5, marginTop: 15 }}
        >
          Main Menu
        </Button>
      </Hidden>

    </List>
  );
}

const mapStateToProps = (state) => ({
  selectedCategoryFromState: state.menu.selectedCategory,
  selectedItemsFromState: state.menu.selectedItems,
  isOpen: state.opening.isOpen,
  willOpen: state.opening.willOpen,
  isTomorrowAvailable: (!state.opening.orderTiming.today && state.opening.orderTiming.hours.length),
  deliveryOrder: state.opening.delivery,
  collectionOrder: state.opening.collection,
});

const mapDispatchToProps = (dispatch) => ({
  updateShowLeftSideBar: (isUpdate) => dispatch(updateShowLeftSideBar(isUpdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMenu2);
