import CategoryMenu2 from '@/components/menu/CategoryMenu2';
import MobileMenuCategory from '@/components/menu/mobileMenuCategory';
import SideMenu from '@/components/menu/sideMenu';
import Offers from '@/components/offer/Offers';
import PageHeader from '@/components/pageHeader/pageHeader';
import SlideAnimation from '@/components/util/Animations/SlideAnimation';
import PageLoader from '@/components/util/PageLoader';
import { cartOrderTypeUpdate } from '@/store/cart/cartActions';
import { MenuAdded, SelectedMenuAdded } from '@/store/menu/menuActions';
import { urlRedirectSet } from '@/store/url/urlActions';
import { API_BASE_URL, ORDER_TYPE_COLLECTION } from '@/util/constants';
import {
  Button, Grid, Hidden, Paper
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Cart2 from '../cart/cart2';

const MenuPage2 = ({ ...otherProps }) => {

  const [state, setState] = useState(() => ({
    itemsLoading: true,
    isClosedCalculated: false,
    isNotAvailableOrderTypeChecked: false,
  }));


  useEffect(() => {

    (async () => {
      if (!otherProps.items.length) {
        await fetchItems();
      }

      if (!state.isNotAvailableOrderTypeChecked) {
        checkNotAvailableOrderType();
      }

      const hourLength = otherProps.openingHour?.length;

      if (hourLength && !state.isClosedCalculated) {
        setState((prevState) => ({
          ...prevState,
          isClosedCalculated: true,
        }));
      }

    })();

  }, [otherProps.openingHour]);


  const checkNotAvailableOrderType = useCallback(() => {
    try {

      const data = otherProps.openingHour;

      const totalArrayLen = data?.length;

      const hasNoDeliveryArray = data?.filter((val) => val.delivery === false);

      const hasNoCollectionArray = data.filter((val) => val.collection === false);

      if (hasNoCollectionArray.length === totalArrayLen
        || hasNoDeliveryArray.length === totalArrayLen) {

        if (hasNoDeliveryArray.length) {

          //update default order type in store if delivery not available
          otherProps.cartOrderTypeUpdate(ORDER_TYPE_COLLECTION);

          //update unavailable order type in store
          // otherProps.updateUnavailableOrderType('Delivery');

        } else {
          // otherProps.updateUnavailableOrderType('Collection');
        }
      }

      setState((prevState) => ({
        ...prevState,
        isNotAvailableOrderTypeChecked: true,
      }));

    } catch (e) {
      console.log(e);
      //@todo: do something here @sunny
    }
  }, [otherProps.openingHour]);

  const fetchItems = useCallback(async () => {

    try {

      if (!otherProps.categories.length) {
        const response = await axios.get(`${API_BASE_URL}/menu`);

        const { data } = response.data;

        const items = data.items;
        const categories = data.categories;
        const setMenu = data.set_menus;

        otherProps.MenuAdded(categories, items, setMenu);

        if (categories.length) {
          const firstCategory = categories[0];
          const firstCategoryItems = items[firstCategory.name];

          otherProps.SelectedMenuAdded(firstCategory, firstCategoryItems);
        }

      }

      setState((prevState) => ({
        ...prevState,
        itemsLoading: false
      }));

    } catch (e) {
      //@todo: do something here @sunny
      console.log(e);
    }
  }, [otherProps.categories]);



  return (
    <SlideAnimation in>

      <>
        <PageLoader show={state.itemsLoading} />

        <PageHeader
          pageName="Order Online"
          breadCrumbComponentPadding="10px 0"
          breadCrumbComponent={(
            <Offers>
              {
                (openOffers) => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openOffers}
                  >
                    Available Offers
                  </Button>
                )
              }
            </Offers>
          )}
        />

        <Grid container>

          <Hidden mdDown>
            <Grid item xs={12} md={3} style={{ paddingLeft: 7 }}>
              <Paper>
                <SideMenu />
              </Paper>
            </Grid>
          </Hidden>


          <Hidden mdDown>
            <Grid item xs={12} md={6}>
              {
                otherProps.selectedItemsFromState[0]
                && <CategoryMenu2 />
              }

            </Grid>
          </Hidden>


          <Hidden mdUp>
            <Grid item xs={12} md={6}>
              {
                otherProps.selectedItemsFromState[0]
                && <MobileMenuCategory />
              }
            </Grid>
          </Hidden>


          <Hidden mdDown>
            <Grid item xs={12} md={3}>
              <Cart2 />
            </Grid>
          </Hidden>

        </Grid>
      </>
    </SlideAnimation>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.menu.categories,
  items: state.menu.items,
  selectedCategoryFromState: state.menu.selectedCategory,
  selectedItemsFromState: state.menu.selectedItems,
  openingHour: state.opening.orderTiming.hours,
});

const mapDispatchToProps = (dispatch) => ({
  setUrlRedirect: (from, to) => dispatch(urlRedirectSet(from, to)),
  MenuAdded: (categories, items, setMenu) => dispatch(MenuAdded(categories, items, setMenu)),
  cartOrderTypeUpdate: (type) => dispatch(cartOrderTypeUpdate(type)),
  SelectedMenuAdded: (selectedCategory, selectedItems) => dispatch(SelectedMenuAdded(selectedCategory, selectedItems)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage2);
