import {
 useEffect, useMemo, useRef, useState
} from 'react';
import NavItem from './navItem';
import { connect } from 'react-redux';
import Logout from '@/pages/auth/logOut';
import UserAvatar from '../util/UserAvatar';
import { Link } from 'react-router-dom';
import useSetting from '@/hooks/useSetting';
import { SETTING_FILE_PATH } from '@/util/constants';
import routes from '@/util/routes';
import { isMobile } from 'react-device-detect';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import CloseIcon from '@mui/icons-material/Close';
import './Nav3.scss';
import MenuIcon from '@mui/icons-material/Menu';
import { Hidden } from '@mui/material';


function Nav3({ ...otherProps }) {

  const styles = {
    navbarFixed: {
      width: '100vw',
      position: 'fixed',
      zIndex: 11,
    },
  };

  const setting = useSetting([
    'phone',
    'site_logo_large',
    'navbar_fixed',
    'address',
    'facebook_url',
    'instagram_url',
    'trip_advisor',
    'address',
    'country_code',
    'display_email',
  ]);
  const mobileNavToggleRef = useRef(false);

  const [openNav, setOpenNav] = useState(false);

  const showMenuItems = () => {
    setOpenNav((v) => !v);
  }

  const SiteLogo = useMemo(() => `${SETTING_FILE_PATH + setting.site_logo_large}`, [setting.site_logo_large]);

  const [stickyNavbar, setStickyNavbar] = useState(false);
  const fixedTop = () => {
    if (window.scrollY) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }

  };

  window.addEventListener('scroll', fixedTop);

  return (
    <header>
      <Hidden smDown>
        <div className="brl-head">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              {/* Contact information details Start */}
              <div className="brl-head-contact brl-head-col">
                <div className="brl-head-address">
                  <RoomIcon className="icon-style" />
                  <p>{setting.address}</p>
                </div>
                <div className="brl-head-direct-contact">
                  <div>
                    <EmailIcon className="icon-style" />
                    <a href={`mailto: ${setting.display_email}`}>
                      {' '}
                      {setting.display_email}
                    </a>
                  </div>
                </div>
                <div className="brl-head-direct-contact mt-2">
                  <div>
                    <PhoneIcon className="icon-style" />
                    <a href={`tel:${setting.country_code}-${setting.phone}`}>
                      {setting.phone}
                    </a>
                  </div>
                </div>
              </div>

              <Link to="/" className="navbar-brand"><img src={`${SETTING_FILE_PATH + setting.site_logo_large}`} height="50" alt="Site Logo" /></Link>

              <div className="brl-head-social brl-head-col">
                <div className="brl-social-wrapper social-icons">
                  <a target="_blank" className="facebook">
                    <FacebookIcon />
                  </a>
                  {/*?php endif ?*/}
                </div>
                <div className="brl-social-wrapper">
                  <a target="_blank" className="instagram">
                    <InstagramIcon />
                  </a>
                </div>
              </div>
              {/* Social connection information icons End */}
            </div>
          </div>
        </div>
      </Hidden>

      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={
          stickyNavbar
            ? {
            position: 'fixed',
              width: '100%',
              zIndex: '9999',
              top: '0'
          }
          : {}
        }
      >
        {/* Product logo Start */}

        <Link to="/" className="d-sm-block d-md-none navbar-brand">
          <img src={SiteLogo} height="50" alt="Site Logo" />
        </Link>

        <button
          ref={mobileNavToggleRef}
          className={`navbar-toggler ${openNav ? '' : 'collapsed'}`}
          type="button"
          data-toggle="collapse"
          data-target="#menu3"
          aria-controls="menu3"
          aria-expanded={openNav}
          aria-label="Toggle navigation"
          onClick={showMenuItems}
        >
          {/*<span className="navbar-toggler-icon" />*/}
          {
            !openNav && <MenuIcon fontSize="large" sx={styles.mobileNavToggle} />
          }
          {
            openNav && <CloseIcon fontSize="large" sx={styles.mobileNavToggle} />
          }
        </button>

        <div className={`collapse navbar-collapse ${openNav ? 'show' : ''}`} id="menu3">
          <ul className="navbar-nav mx-auto">
            <NavItem name="Home" path="/" mobileMenuToggleRef={mobileNavToggleRef} />
            <NavItem name="Awards" path="/awards" mobileMenuToggleRef={mobileNavToggleRef} />
            <NavItem name="Gallery" path="/gallery" mobileMenuToggleRef={mobileNavToggleRef} />
            <NavItem name="Reservation" path="/reservation" mobileMenuToggleRef={mobileNavToggleRef} />
            <NavItem name="Contact" path="/contact" mobileMenuToggleRef={mobileNavToggleRef} />

            {
                  otherProps.isLoggedIn
                    ? <Logout name="LogOut" mobileMenuToggleRef={mobileNavToggleRef} />
                    : <NavItem name="Login" path="/login" mobileMenuToggleRef={mobileNavToggleRef} />
                }

            {
                  otherProps.isLoggedIn
                  && (
                    <Link to={routes.userProfile}>
                      <div
                        className="search-right"
                        onClick={() => {
                          if (isMobile) {
                            mobileNavToggleRef?.current?.click();
                          }
                        }}
                      >
                        <UserAvatar />
                      </div>
                    </Link>
                  )
                }
            <Link to="/order" className="nav-item btn btn-secondary btn-style">Order Online</Link>

          </ul>
        </div>

      </nav>
    </header>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data
});

export default connect(mapStateToProps)(Nav3);
