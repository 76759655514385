import ModalComp from '../util/Modal/ModalComp';
import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CURRENCY_SYMBOL } from '@/util/constants';
import _ from 'lodash';
import { connect, MapDispatchToPropsParam } from 'react-redux';
import { updateShowAutoOfferPopup } from '@/store/layout/layoutActions';
import { fetchOffers } from '@/store/offer/offerActions';
import { Theme } from '@mui/material/styles';
import { CouponOffer, DiscountOffer, AllOffers } from '@/types';
import { RootState } from '@/store';

type OfferStateProps = {
  offers: AllOffers;
  show_auto_offer_popup: boolean;
  children: CallableFunction
}

type OfferDispatchProps = {
  updateShowAutoOfferPopup: any;
  getOffers: any;
}

type OffersProps = OfferStateProps & OfferDispatchProps;

const styles = {
  highlightWord: {
    color: (t: Theme) => t.palette.secondary.light,
    whiteSpace: 'nowrap'
  },
  offerContainer: (t: Theme) => ({
    border: '2px solid',
    borderStyle: 'dashed',
    borderColor: t.palette.grey['500'],
    backgroundColor: t.background.lightYellow,
    padding: 2,
    borderRadius: 7
  }),
  offerTitle: {
    margin: (t: Theme) => t.spacing(1)
  }
};

const Offers = ({ ...otherProps }: OffersProps) => {

  const [open, setOpen] = useState(false);

  useEffect(() => {

    if ((
        otherProps.offers.discounts.length || otherProps.offers.coupons.length
      )
      && !otherProps.show_auto_offer_popup
    ) {
      otherProps.updateShowAutoOfferPopup(true);
      setOpen(true);
    }
  }, [otherProps.offers]);

  const closeHandler = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {

    if (!otherProps.offers.discounts.length && !otherProps.offers.coupons.length) {
      otherProps.getOffers();
    }

  }, []);

  const getOrderTypeForPrint = (type: string) => {
    if (type === 'both') {
      return 'Collection & Delivery'
    }

    return type.replace(type[0], type[0].toUpperCase());
  };

  const printOffers = () => (
    <>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4" sx={styles.offerTitle}>Discounts</Typography>
        {
          !otherProps.offers.discounts?.length
          && (
            <Box m={2}>
              <Typography variant="h5">Sorry! No discount available at this time.</Typography>
            </Box>
          )
        }
        {
          otherProps.offers.discounts?.map((discount: DiscountOffer) => (
            <Box
              sx={styles.offerContainer}
              mx={1}
              marginBottom={2}
              key={discount.id}
            >
              <Typography variant="h5" color="primary">
                {discount.fixed && CURRENCY_SYMBOL}
                {discount.amount}
                {!discount.fixed && ' %'} discount
              </Typography>
              <Typography>
                on {getOrderTypeForPrint(discount.for)} orders
              </Typography>
              <Typography variant="caption">
                Minimum order { CURRENCY_SYMBOL }{ discount.min_order }
              </Typography>
            </Box>
          ))
        }
      </Grid>

      <Grid item xs={12} lg={6}>
        <Typography variant="h4" sx={styles.offerTitle}>Coupons</Typography>
        {
          !otherProps.offers.coupons?.length
          && (
            <Box mx={1}>
              <Typography variant="h5">Sorry! No coupon available at this time.</Typography>
            </Box>
          )
        }
        {
          otherProps.offers.coupons?.map((coupon: CouponOffer) => (
            <Box
              key={coupon.id}
              mx={1}
              marginBottom={2}
              sx={styles.offerContainer}
            >
              <Typography variant="h5" color="primary">
                {coupon.code}
              </Typography>
              <Typography>
                <Box component="span" sx={styles.highlightWord}>
                  {coupon.fixed && CURRENCY_SYMBOL}
                  {`${coupon.amount} `}
                  {!coupon.fixed && '% '}
                </Box>
                off on
                {' '}
                {getOrderTypeForPrint(coupon.for)}
                {' '}
                orders
              </Typography>
              <Typography variant="caption">
                For {' '}
                <Box component="span" sx={styles.highlightWord}>
                  {coupon.coupon_available_for}
                </Box>
                {!coupon.once && (' (Can be used multiple times)')}
              </Typography>
            </Box>
          ))
        }

      </Grid>
    </>
  );

  return (
    <>
      {
        otherProps.children(openModal)
      }

      <ModalComp
        title="Available Offers"
        open={open}
        closeHandler={closeHandler}
        hideFooter
      >
        <Grid container>

          {
            _.isEmpty(otherProps.offers)
            && (
              <Grid item xs={12} px={3} py={2}>
                <Typography variant="h4">Sorry! No offers available right now</Typography>
              </Grid>
            )
          }

          {
            !_.isEmpty(otherProps.offers) && printOffers()
          }

        </Grid>
      </ModalComp>
    </>
  );
}



const mapStateToProps = (state: RootState) => ({
  offers: state.offer.data,
  show_auto_offer_popup: state.layout.show_auto_offer_popup,
});

const mapDispatchToProps = (dispatch: MapDispatchToPropsParam<any, any>) => ({
  updateShowAutoOfferPopup: (show: boolean) => dispatch(updateShowAutoOfferPopup(show)),
  getOffers: () => dispatch(fetchOffers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
