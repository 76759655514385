import store from '@/store';

export default class MainStore {

  /**
   * @type {{
   * state: store.getState,
   * dispatch: store.dispatch,
   * subscribe: store.subscribe,
   * }}
   */
  static privateStore = {
    state: null,
    dispatch: null,
    subscribe: null
  };

  static getStore() {

    if (!MainStore.privateStore.state) {

      console.log('new store');

      MainStore.privateStore.state = store.getState();
      MainStore.privateStore.dispatch = store.dispatch;
      MainStore.privateStore.subscribe = store.subscribe;

      store.subscribe(() => MainStore.privateStore.state = store.getState());
    }

    return MainStore.privateStore;
  }

}
