import {
  useEffect, useReducer, useRef, useState
} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { connect } from 'react-redux';
import { footerShowHide, headerShowHide } from '@/store/layout/layoutActions';
import { apiRequest } from '@/util/util';
import _ from 'lodash';
import routes from '@/util/routes';
import UserAvatar from '@/components/util/UserAvatar';
import {
  Alert,
  CircularProgress,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import { AUTH_ENDPOINT_FORGET_PASSWORD } from '@/util/constants';
import Copyright from '@/components/util/copyright';
import loginImage from '@/assets/images/login.jpg';
import { formSingleValidator, formValidator, validationRules } from '@/util/formValidation';

const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    email: false
  }
};

function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) || 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function ForgetPassword(props) {

  const navigate = useNavigate();

  // redirect if user logged in
  useEffect(() => {
    if (props.isLoggedIn) {
      navigate(routes.home);
    }
  }, [props.user]);

  const styles = {
    root: {
      height: '100vh',
    },
    paper: {
      margin: (theme) => theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: (theme) => theme.spacing(1),
      backgroundColor: (theme) => theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: (theme) => theme.spacing(1),
    },
    submit: {
      margin: (theme) => theme.spacing(3, 0, 2),
    },
  };



  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  const validationSchema = {
    email: [
      validationRules.required(),
      validationRules.email()
    ]
  };

  // hide/show header footer
  useEffect(() => {

    // hiding header footer
    toggleHeaderFooter(false);


    // showing header footer
    return () => toggleHeaderFooter(true);

  }, []);

  const toggleHeaderFooter = (show) => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }

  // handle form inputs
  const [credentials, setCredentials] = useState({
    email: ''
  });

  const onChangePassword = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCredentials({
      ...credentials,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);

      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();
  };


  const submitHandler = async (e) => {
    e.preventDefault();

    // all field validation before submit
    const { error, data } = await formValidator(credentials, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      return;
    }

    try {

      errorDispatch({
        type: errorTypes.SUBMIT
      });

      const res = await apiRequest.post(AUTH_ENDPOINT_FORGET_PASSWORD, {
        email: credentials.email
      });

      errorDispatch({
        type: errorTypes.SUBMIT_SUCCESS,
        payload: res.data.message
      });

    } catch (e) {
      const err = e?.response?.data?.errors;

      errorDispatch({
        type: errorTypes.SUBMIT_FAIL,
        payload: err?.email[0] || e?.response?.data?.message
      });
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={styles.root}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} lg={4} component={Paper} elevation={6}>
        <Box sx={styles.paper}>

          {
            _.isEmpty(props.lastUser)
              ? (
                <Avatar sx={styles.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
              )
              : <UserAvatar />
          }

          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>

          {
            errorState.error
            && <Alert severity="error" variant="standard">{errorState.message}</Alert>
          }

          {
            errorState.success
            && <Alert severity="success" variant="standard">{errorState.message}</Alert>
          }


          <Box component="form" sx={styles.form} noValidate onSubmit={submitHandler}>
            <TextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={!!errorState.fields.email}
              helperText={errorState.fields.email}
              value={credentials.email}
              onChange={onChangePassword}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={styles.submit}
              disabled={errorState.loading}
            >
              Send Reset Link
              {
                errorState.loading
                && <CircularProgress size="20px" style={{ marginLeft: '10px' }} />
              }
            </Button>

            <Grid container>
              <Grid item xs>
                <RouterLink to={routes.login}>
                  Back to Login
                </RouterLink>
              </Grid>
              <Grid item>
                <RouterLink to={routes.signup}>
                  Don't have an account? Sign Up
                </RouterLink>
              </Grid>
            </Grid>
            {/*<Box mt={5}>*/}
            {/*  <Copyright />*/}
            {/*</Box>*/}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data,
  lastUser: state.user.lastUser
});

const mapDispatchToProps = (dispatch) => ({
  showHideHeader: (shouldShow) => dispatch(headerShowHide(shouldShow)),
  showHideFooter: (shouldShow) => dispatch(footerShowHide(shouldShow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
