import { FC, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { footerShowHide, headerShowHide } from '@/store/layout/layoutActions';
import { connect, MapDispatchToPropsParam } from 'react-redux';

type RedirectToProps = {
  fullUrl: string,
  showHideHeader: (show: boolean) => void,
  showHideFooter: (show: boolean) => void,
  showRedirectingText?: boolean
};

const RedirectElement: FC = () => (
  <Box
    sx={{
      backgroundColor: (t) => t.palette.primary.main,
      height: '100vh',
      width: '100vw',
      pt: 10
    }}
  >
    <Typography
      align="center"
      fontSize={25}
      color="white"
    >
      Redirecting...
    </Typography>
  </Box>
);

function RedirectTo({ fullUrl, showRedirectingText = false, ...otherProps }: RedirectToProps) {

  const toggleHeaderFooter = (show: boolean) => {
    otherProps.showHideHeader(show);
    otherProps.showHideFooter(show);
  }

  useEffect(() => {

    // hiding header footer
    toggleHeaderFooter(false);

    // redirect
    window.location.replace(fullUrl);

    // showing header footer
    return () => toggleHeaderFooter(true);

  }, []);

  return showRedirectingText ? <RedirectElement /> : null;
}

const mapDispatchToProps = (dispatch: MapDispatchToPropsParam<any, any>) => ({
  showHideHeader: (shouldShow: boolean) => dispatch(headerShowHide(shouldShow)),
  showHideFooter: (shouldShow: boolean) => dispatch(footerShowHide(shouldShow)),
});

export default connect(null, mapDispatchToProps)(RedirectTo);
