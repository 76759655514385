import {
 Card, FormControlLabel, Grid, Radio, RadioGroup
} from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useNotify from '@/hooks/useNotify';
import useSetting from '@/hooks/useSetting';

function PaymentMethods({ cartSelection, cartSelectionHandler, ...otherProps }) {

  const styles = {
    root: {
      maxWidth: 350,
      // padding: 20,
    },
    padding: {
      padding: 4,
    },
    cursorHover: {
      cursor: 'pointer',
    },
    paymentCard: () => ({
      ...styles.padding,
      width: 350
    }),
    loadingCenter: {
      display: 'flex',
      justifyContent: 'center'
    },
    marginTop20: {
      marginTop: 20,
    },
    display: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };


  const [notify] = useNotify();

  const paymentSetting = useSetting([
    'payment_cash',
    'payment_card',
  ]);

  const handleCard = (event) => {
    cartSelectionHandler(event.target.value);
  }

  const showCardText = () => {
    notify.info('You can proceed your order by selecting cash but pay by card over the phone by calling at Restaurant.');
  }

  return (
    <RadioGroup sx={styles.root} value={cartSelection} onChange={handleCard}>
      {
        paymentSetting.payment_cash
        && (
        <Grid container>
          <Card sx={styles.paymentCard}>
            <FormControlLabel value="cash" control={<Radio color="primary" />} label="Cash" />
          </Card>
        </Grid>
        )
      }

      {
        paymentSetting.payment_card
        && (
        <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
          <Card sx={styles.paymentCard}>
            <FormControlLabel value="card" control={<Radio color="primary" />} label="Card" />
          </Card>
        </Grid>
)

      }

      {
        !paymentSetting.payment_card
        && (
        <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
          <Card sx={styles.paymentCard} onClick={showCardText}>
            Card

          </Card>
        </Grid>
)

      }

    </RadioGroup>
  );
}

PaymentMethods.propTypes = {
  cartSelection: PropTypes.string,
  cartSelectionHandler: PropTypes.func,
};

const mapStateToProps = (state) => ({
  orderType: state.cart.order,
});


export default connect(mapStateToProps)(PaymentMethods);
