import PageHeaderBreadcrumb from './pageHeaderBreadcrumb';

type PageHeaderWithComponent = {
  breadCrumbComponent: React.ReactNode;
  breadCrumbComponentPadding?: string;
};

type PageHeaderWithoutComponent = {
  breadCrumbComponent?: never;
  breadCrumbComponentPadding?: never;
};

type PageHeaderProps = {
  pageName: string;
  bgClass?: string;
} & (PageHeaderWithComponent | PageHeaderWithoutComponent);

export default function PageHeader({
 pageName, bgClass, breadCrumbComponent, breadCrumbComponentPadding
}: PageHeaderProps) {

  const backgroundImgClass = (bgClass === undefined) ? 'breadcrumb-bg-about' : bgClass;

  return (
    <>
      <section className="w3l-about-breadcrumb">
        <div className={`breadcrumb-bg ${backgroundImgClass} py-5`}>
          <div className="container py-lg-3 py-md-3">
            <h2 className="title">{ pageName }</h2>
          </div>
        </div>
      </section>

      <PageHeaderBreadcrumb
        pageName={pageName}
        component={breadCrumbComponent}
        padding={breadCrumbComponentPadding}
      />
    </>
  );
}
