import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Slide } from '@mui/material';
import * as colors from '@mui/material/colors';
import {
 SnackbarKey, SnackbarOrigin, useSnackbar, VariantType
} from 'notistack';

type NotifyTypeCB = (msg: string, duration?: number) => void;
type NotifyTypeProps = Record<VariantType, NotifyTypeCB>;

const getCloseBtnColor = (type: VariantType | string) => {
  let color;

  switch (type) {
    case 'success':
      color = colors.green['100'];
      break;

    case 'error':
      color = colors.red['100'];
      break;

    case 'info':
      color = colors.blue['100'];
      break;

    default:
      color = colors.grey['500'];
      break;
  }

  return { color }
};

function useNotify(
  positionY: SnackbarOrigin['vertical'] = 'top',
  positionX: SnackbarOrigin['horizontal'] = 'center'
) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify = (msg: string, type: VariantType = 'default', duration: number = 2000) => {

    const action = (key: SnackbarKey) => (
      <IconButton
        sx={{ position: 'right' }}
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon style={getCloseBtnColor(type)} />
      </IconButton>
    );

    enqueueSnackbar(msg, {
      variant: type,
      anchorOrigin: {
        vertical: positionY,
        horizontal: positionX,
      },
      TransitionComponent: Slide,
      autoHideDuration: duration,
      // fontSize: fontSize,
      action
    });
  };

  const types: NotifyTypeProps = {
    default: (msg, duration) => notify.call(null, msg, 'default', duration),
    success: (msg, duration) => notify.call(null, msg, 'success', duration),
    error: (msg, duration) => notify.call(null, msg, 'error', duration),
    info: (msg, duration) => notify.call(null, msg, 'info', duration),
    warning: (msg, duration) => notify.call(null, msg, 'warning', duration),
  };

  return [types, closeSnackbar];
}

export default useNotify;
