import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
 Box, List, ListItem, ListItemIcon, Checkbox
} from '@mui/material';
import { connect } from 'react-redux';
import { CURRENCY_SYMBOL } from '@/util/constants';

const styles = {
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: (theme) => theme.palette.background.paper,
  },
  selectBox: {
    minWidth: '35px'
  },
  addonName: {
    flex: 'unset',
    fontSize: '17px'
  },
  addonPrice: {
    flex: 'unset',
    fontWeight: 700,
  }
}

function AddToCartAddons({
 item, updating, selectHandler, ...otherProps
}) {

  const [checked, setChecked] = useState([]);

  const handleToggle = (id) => () => {

    const currentIndex = checked.indexOf(id);

    const newChecked = [...checked];

    if (currentIndex === -1) {

      newChecked.push(id);

    } else {

      newChecked.splice(currentIndex, 1);

    }

    setChecked(newChecked);
  };

  // auto select already added addons in cart
  useEffect(() => {
    if (updating) {

      const cartAddonIds = item?.selected_addon.map((cartAddon) => cartAddon.id);

      setChecked(cartAddonIds);
    }
  }, [updating]);


  // update parent about selected addons
  useEffect(() => {

    const checkedAddons = item.addons.filter((addon) => checked.includes(addon.id));

    selectHandler(checkedAddons);
  }, [checked]);

  return (
    <List sx={styles.root}>

      {item.addons.map((addon) => {
        const labelId = `checkbox-list-label-${addon.id}`;

        return (
          <ListItem key={addon.id} role={undefined} dense button onClick={handleToggle(addon.id)}>
            <ListItemIcon sx={styles.selectBox}>
              <Checkbox
                edge="start"
                color="primary"
                checked={checked.indexOf(addon.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box
                id={labelId}
                sx={styles.addonName}
              >
                {addon.name}
              </Box>

              <Box sx={styles.addonPrice}>
                {CURRENCY_SYMBOL}
                {' '}
                {addon.price.toFixed(2)}
              </Box>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
}

AddToCartAddons.propTypes = {
  item: PropTypes.object.isRequired,
  selectHandler: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  cartAddons: state.cart.addons,
});

export default connect(mapStateToProps)(AddToCartAddons);
