import { useEffect, useState } from 'react';
import {
  Box, Button, Modal,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import FadeAnimation from '../Animations/FadeAnimation';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material/styles';

type ModalCompProps = {
  backDropTimeout?: number;
  submitBtnText?: string;
  open: boolean;
  closeHandler?: (status?: boolean) => void;
  closeOnBackdropClick?: boolean;
  submitHandler?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  showSubmitLoader?: boolean;
  clickNode?: React.ReactNode;
  title: string;
  cardBackgroundColor?: string;
  modalBodyPadding?: string;
  modalCardPosition?: string;
  primaryBackground?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  maxDesktopWidth?: string;
  minWidth?: string;
  disableFocusEnforcement?: boolean;
  children: React.ReactNode;
  // otherProps: any;
};

function ModalComp({
  backDropTimeout = 500,
  submitBtnText = 'Submit',
  open,
  closeHandler,
  closeOnBackdropClick = true,
  submitHandler = () => {},
  showSubmitLoader = true,
  clickNode,
  title,
  cardBackgroundColor = '',
  modalBodyPadding = '',
  modalCardPosition = '',
  primaryBackground = false,
  hideFooter = false,
  hideHeader = false,
  maxDesktopWidth = '',
  minWidth = '',
  disableFocusEnforcement = false,
  ...otherProps
}: ModalCompProps) {

  if (isMobile) {
    maxDesktopWidth = '90vw';
  }

  const styles = {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    primaryBackground: {
      backgroundColor: (theme: Theme) => theme.palette.primary.main,
    },
    paper: {
      backgroundColor: (theme: Theme) => cardBackgroundColor || theme.palette.background.paper,
      boxShadow: (theme: any) => theme.shadows[5],
    },
    modalCard: {
      maxWidth: `${maxDesktopWidth || 'auto'}`,
      minWidth: `${minWidth || 'auto'}`,
      minHeight: 'auto',
      overflow: 'auto',
      maxHeight: '100vh'
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    modalTitle: (t: Theme) => ({
      padding: t.spacing(1),
      backgroundColor: t.palette.primary.dark,
      color: t.palette.primary.contrastText,
      fontSize: '25px',
      borderBottom: '1px solid',
      borderColor: t.palette.primary.main,
      display: 'flex',
      justifyContent: 'space-between'
    }),
    modalTitleCloseButton: {
      cursor: 'pointer',
      marginLeft: (theme: Theme) => theme.spacing(1)
    },
    modalBody: {
      padding: (theme: Theme) => theme.spacing(1),
    },
    modalFooter: {
      backgroundColor: (theme: Theme) => theme.palette.background.default,
      display: 'flex',
      justifyContent: 'flex-end',
      padding: (theme: Theme) => theme.spacing(2),
    },
    modalFooterCancelButton: {
      marginRight: 10
    }
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (open !== undefined) {
      setShow(open);
    }
  }, [open]);

  const handleOpen = () => {
    setShow(true);
  };

  const handleCloseFromModal = (event: object, reason: string) => {

    if (reason === 'backdropClick' && closeOnBackdropClick) {
      handleClose();
    }
  };

  const handleClose = () => {

    setShow(false);

    if (typeof closeHandler === 'function') {
      closeHandler(false);
    }
  };

  return (
    <div>
      {
        clickNode
        && (
          <span onClick={handleOpen}>
            {clickNode}
          </span>
        )
      }

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ ...styles.modal, color: primaryBackground ? styles.primaryBackground : '' }}
        open={show}
        onClose={handleCloseFromModal}
        closeAfterTransition
        disableEnforceFocus={disableFocusEnforcement}
        BackdropProps={{
          timeout: backDropTimeout,
        }}
      >
        <FadeAnimation in={show}>
          <Box
            sx={{
              position: modalCardPosition,
              ...styles.paper,
              ...styles.modalCard
            }}
          >
            {
              !hideHeader
              && (
                <Box sx={styles.modalTitle}>
                  <Box>{title}</Box>
                  <Box sx={styles.modalTitleCloseButton} onClick={handleClose}>
                    <CloseIcon />
                  </Box>
                </Box>
              )
            }


            <Box sx={styles.modalContent}>
              <Box sx={styles.modalBody}>
                {
                  otherProps.children || <h1>Default Modal Content</h1>
                }
              </Box>
              {
                !hideFooter
                && (
                  <Box sx={styles.modalFooter}>
                    <Button
                      sx={styles.modalFooterCancelButton}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={submitHandler}
                    >
                      {submitBtnText}
                    </Button>
                  </Box>
                )
              }
            </Box>
          </Box>
        </FadeAnimation>
      </Modal>
    </div>
  );
}

export default ModalComp;
