import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
 Card, CardContent, Menu, MenuItem, FormControlLabel, Switch, Box, Typography
} from '@mui/material';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { removeAddress } from '@/store/user/userActions'
import { USER_ADDRESS } from '@/util/constants';

function AddressCard({
  address,
  isSelected = false,
  selectAddressHandler,
  clearSelectHandler,
  showEditHandler,
  ...otherProps
}) {

  const styles = {
    root: {
      marginTop: 5,
      minWidth: 320,
      maxWidth: 400,
    },
    header: {
      textTransform: 'capitalize',
      fontSize: 25,
    },
    selectedAddressBorder: {
      border: '1px solid',
      borderColor: (theme) => theme.palette.primary.main
    },
    addressCardHeader: {
      backgroundColor: '#ececec',
      padding: 2
    },
    addressItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 1
    },
    switchLabel: {
      marginRight: -10
    },
    menuIconSize: {
      fontSize: 30
    },
    marginTop20: {
      marginTop: 10,
    },
    spaceBetween: {
      display: 'space-between',
    },
    selectedAddressBackgroundColor: {
      backgroundColor: '#f3f3f3',
    },
    addressHeader: {
      backgroundColor: '#f3f3f3'
    },

    cursorHover: {
      cursor: 'pointer',
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const openHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = () => {
    setAnchorEl(null);
  };

  const deleteHandler = async (id) => {
    const deleteUrl = `${USER_ADDRESS}/${id}`;

    const deletedAddress = await otherProps.removeAddress(deleteUrl);

    if (deletedAddress) {
      clearSelectHandler();
    }
  };

  const editHandler = (address) => {
    selectAddressHandler(address);
    showEditHandler(true);
    closeHandler();
  };

  return (
    <Card sx={{ ...styles.root, ...(isSelected ? styles.selectedAddressBorder : '') }}>
      <CardContent>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.addressCardHeader}
          mb={1}
        >
          {/*<div className={[styles.display, styles.marginBottom20, styles.addressHeader].join(' ')}>*/}
          <Box sx={{ ...styles.header, ...styles.itemColor }}>{address.type}</Box>

          {/*menu*/}
          <Box>
            <MoreVertIcon sx={{ ...styles.menuIconSize, ...styles.cursorHover }} color="primary" onClick={openHandler} />

            <Menu
              id={address.id}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeHandler}
              key={address.id}
            >
              <MenuItem>
                <DeleteIcon id={`${address.id}d`} color="secondary" onClick={() => deleteHandler(address.id)} />
              </MenuItem>
              <MenuItem>
                <EditSharpIcon id={`${address.id}e`} color="primary" onClick={() => editHandler(address)} />
              </MenuItem>
            </Menu>
          </Box>
          {/*</div>*/}
        </Box>


        <Box sx={styles.addressItem}>
          <div>{'House No: '}</div>
          <div>{`${address.house_no === null ? ' ' : address.house_no}`}</div>
        </Box>

        <Box sx={styles.addressItem}>
          <div>{'Street: '}</div>
          <div>{`${address.street_name}`}</div>
        </Box>

        <Box sx={styles.addressItem}>
          <div>{'City: '}</div>
          <div>{`${address.city}`}</div>
        </Box>

        <Box sx={styles.addressItem}>
          <div>{'County: '}</div>
          <div>{`${address.county}`}</div>
        </Box>

        <Box sx={styles.addressItem}>
          <div>{'Postcode: '}</div>
          <div>{`${address.postcode}`}</div>
        </Box>

        <Box sx={styles.addressItem}>
          <div>{'Address Note: '}</div>
          <div>{address.note}</div>
        </Box>

        <Box sx={styles.addressItem} mb={0} pb={0}>
          <Typography variant="h5">Select</Typography>
          <FormControlLabel
            // sx={styles.switchLabel}
            control={(
              <Switch
                color="primary"
                checked={isSelected}
                onChange={() => selectAddressHandler(address)}
              />
            )}
            label=""
          />
        </Box>

      </CardContent>
    </Card>
  );
}


AddressCard.propTypes = {
  address: PropTypes.object,
  isSelected: PropTypes.bool.isRequired,
  selectAddressHandler: PropTypes.func,
  clearSelectHandler: PropTypes.func,
  showEditHandler: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  removeAddress: (url) => dispatch(removeAddress(url))
});

export default connect(null, mapDispatchToProps)(AddressCard);
