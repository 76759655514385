import { useEffect } from 'react';
import PageHeader from '@/components/pageHeader/pageHeader';
import { connect } from 'react-redux';
import SlideAnimation from '@/components/util/Animations/SlideAnimation';

function Conditions() {

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <SlideAnimation in>

      <>
        <PageHeader pageName="Terms & Conditions" bgClass="breadcrumb-bg-services" />

        {/* features-4 */}

        <section className="grids-1 py-5">
          <div className="grids py-lg-5 py-md-4">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="policy-text">
                  <h1 className="text-center"><strong><strong>Terms and conditions of use</strong></strong></h1>
                  <h2><strong><strong>Introduction</strong></strong></h2>
                  <p>
                    These terms and conditions apply between you, the User of this Website (including any sub-domains,
                    unless expressly excluded by their own terms and conditions), andShapla Tandoori, the
                    owner and operator of this Website. Please read these terms and conditions carefully, as they affect
                    your legal rights. Your agreement to comply with and be bound by these terms and conditions is
                    deemed to occur upon your first use of the Website. If you do not agree to be bound by these terms
                    and conditions, you should stop using the Website immediately.
                  </p>
                  <p>
                    In these terms and conditions,
                    <strong><strong>User</strong></strong>
                  &nbsp;or
                    <strong><strong>Users</strong></strong>
                    &nbsp;means any third party that accesses the Website and is not either (i) employed by
                    Shapla Tandoori Ltd&nbsp;and acting in the course of their employment or (ii) engaged as a consultant or
                    otherwise providing services toShapla Tandoori&nbsp;and accessing the Website in
                    connection with the provision of such services.
                  </p>
                  <p>
                    You must be at least 18 years of age to use this Website. By using the Website and agreeing to
                    these terms and conditions, you represent and warrant that you are at least 18 years of age.
                  </p>
                  <h2><strong><strong>Intellectual property and acceptable use</strong></strong></h2>
                  <ol>
                    <li>
                      1. All Content included on the Website, unless uploaded by Users, is the property of
                      Shapla Tandoori Limited&nbsp;Trading asShapla Tandoori, our affiliates or other relevant
                      third parties. In these terms and conditions, Content means any text, graphics, images, audio,
                      video, software, data compilations, page layout, underlying code and software and any other form
                      of information capable of being stored in a computer that appears on or forms part of this
                      Website, including any such content uploaded by Users. By continuing to use the Website you
                      acknowledge that such Content is protected by copyright, trademarks, database rights and other
                      intellectual property rights. Nothing on this site shall be construed as granting, by implication,
                      estoppel, or otherwise, any license or right to use any trademark, logo or service mark displayed
                      on the site without the owner's prior written permission
                    </li>
                    <li>
                      2. You may, for your own personal, non-commercial use only, do the following:
                      <ol>
                        <li>a. retrieve, display and view the Content on a computer screen</li>
                        <li>b. print one copy of the Content</li>
                      </ol>
                    </li>
                    <li>
                      3. You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any
                      Content without the written permission of Shapla Tandoori Limited&nbsp;Trading as Shapla Tandoori &nbsp;.
                    </li>
                  </ol>
                  <h2><strong><strong>Prohibited use</strong></strong></h2>
                  <ol>
                    <li>
                      4. You may not use the Website for any of the following purposes:
                      <ol>
                        <li>
                          a. in any way which causes, or may cause, damage to the Website or interferes with any other
                          person's use or enjoyment of the Website;
                        </li>
                        <li>
                          b. in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or
                          otherwise objectionable or in breach of any applicable law, regulation, governmental order;
                        </li>
                        <li>
                          c. making, transmitting or storing electronic copies of Content protected by copyright
                          without the permission of the owner.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <h2><strong><strong>Registration</strong></strong></h2>
                  <ol>
                    <li>
                      5. You must ensure that the details provided by you on registration or at any time are correct
                      and complete.
                    </li>
                    <li>
                      6. You must inform us immediately of any changes to the information that you provide when
                      registering by updating your personal details to ensure we can communicate with you effectively.
                    </li>
                    <li>
                      7. We may suspend or cancel your registration with immediate effect for any reasonable purposes
                      or if you breach these terms and conditions.
                    </li>
                    <li>
                      8. You may cancel your registration at any time by informing us in writing to the address at the
                      end of these terms and conditions. If you do so, you must immediately stop using the Website.
                      Cancellation or suspension of your registration does not affect any statutory rights.
                    </li>
                  </ol>
                  <h2><strong><strong>Links to other websites</strong></strong></h2>
                  <ol>
                    <li>
                      9. This Website may contain links to other sites. Unless expressly stated, these sites are not
                      under the control of Shapla Tandoori Limited&nbsp;Trading asShapla Tandoori or that
                      of our affiliates.
                    </li>
                    <li>
                      10. We assume no responsibility for the content of such Websites and disclaim liability for any
                      and all forms of loss or damage arising out of the use of them.
                    </li>
                    <li>
                      11. The inclusion of a link to another site on this Website does not imply any endorsement of
                      the sites themselves or of those in control of them.
                    </li>
                  </ol>
                  <h2><strong><strong>Privacy Policy and Cookies Policy</strong></strong></h2>
                  <ol>
                    <li>
                      12. Use of the Website is also governed by our Privacy Policy and Cookies Policy, which are
                      incorporated into these terms and conditions by this reference. To view the Privacy Policy and
                      Cookies Policy, please click on the following: https://shaplatandoori.net
                    </li>
                  </ol>
                  <h2><strong><strong>Availability of the Website and disclaimers</strong></strong></h2>
                  <ol>
                    <li>
                      13. Any online facilities, tools, services or information that Shapla Tandoori
                      Limited&nbsp;Trading asShapla Tandoori makes available through the Website (the
                      <strong><strong>Service</strong></strong>
                      ) is provided "as is" and on an "as available" basis. We give no warranty that the Service will be
                      free of defects and/or faults. To the maximum extent permitted by the law, we provide no
                      warranties (express or implied) of fitness for a particular purpose, accuracy of information,
                      compatibility and satisfactory quality. Shapla Tandoori Limited&nbsp;Trading as Shapla Tandoori
                      is under no obligation to update information on the Website.
                    </li>
                    <li>
                      14. Whilst Shapla Tandoori Limited&nbsp;Trading asShapla Tandoori uses reasonable
                      endeavours to ensure that the Website is secure and free of errors, viruses and other malware, we
                      give no warranty or guaranty in that regard and all Users take responsibility for their own
                      security, that of their personal details and their computers.
                    </li>
                    <li>
                      15. Shapla Tandoori Limited&nbsp;Trading asShapla Tandoori accepts no liability for
                      any disruption or non-availability of the Website.
                    </li>
                    <li>
                      16. Shapla Tandoori Limited&nbsp;Trading asShapla Tandoori reserves the right to
                      alter, suspend or discontinue any part (or the whole of) the Website including, but not limited
                      to, any products and/or services available. These terms and conditions shall continue to apply to
                      any modified version of the Website unless it is expressly stated otherwise.
                    </li>
                  </ol>
                  <h2><strong><strong>Limitation of liability</strong></strong></h2>
                  <ol>
                    <li>
                      17. Nothing in these terms and conditions will: (a) limit or exclude our or your liability for
                      death or personal injury resulting from our or your negligence, as applicable; (b) limit or
                      exclude our or your liability for fraud or fraudulent misrepresentation; or (c) limit or exclude
                      any of our or your liabilities in any way that is not permitted under applicable law.
                    </li>
                    <li>
                      18. We will not be liable to you in respect of any losses arising out of events beyond our
                      reasonable control.
                    </li>
                    <li>
                      19. To the maximum extent permitted by law, Shapla Tandoori Limited&nbsp;Trading as Shapla Tandoori
                      &nbsp; accepts no liability for any of the following:
                      <ol>
                        <li>
                          a. any business losses, such as loss of profits, income, revenue, anticipated savings,
                          business, contracts, goodwill or commercial opportunities;
                        </li>
                        <li>b. loss or corruption of any data, database or software;</li>
                        <li>c. any special, indirect or consequential loss or damage.</li>
                      </ol>
                    </li>
                  </ol>
                  <h2><strong><strong>General</strong></strong></h2>
                  <ol>
                    <li>
                      20. You may not transfer any of your rights under these terms and conditions to any other
                      person. We may transfer our rights under these terms and conditions where we reasonably believe
                      your rights will not be affected.
                    </li>
                    <li>
                      21. These terms and conditions may be varied by us from time to time. Such revised terms will
                      apply to the Website from the date of publication. Users should check the terms and conditions
                      regularly to ensure familiarity with the then current version.
                    </li>
                    <li>
                      22. These terms and conditions together with the Privacy Policy and Cookies Policy contain the
                      whole agreement between the parties relating to its subject matter and supersede all prior
                      discussions, arrangements or agreements that might have taken place in relation to the terms and
                      conditions.
                    </li>
                    <li>
                      23. The Contracts (Rights of Third Parties) Act 1999 shall not apply to these terms and
                      conditions and no third party will have any right to enforce or rely on any provision of these
                      terms and conditions.
                    </li>
                    <li>
                      24. If any court or competent authority finds that any provision of these terms and conditions
                      (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision
                      will, to the extent required, be deemed to be deleted, and the validity and enforceability of the
                      other provisions of these terms and conditions will not be affected.
                    </li>
                    <li>
                      25. Unless otherwise agreed, no delay, act or omission by a party in exercising any right or
                      remedy will be deemed a waiver of that, or any other, right or remedy.
                    </li>
                    <li>
                      26. This Agreement shall be governed by and interpreted according to the law of England and
                      Wales and all disputes arising under the Agreement (including non-contractual disputes or claims)
                      shall be subject to the exclusive jurisdiction of the English and Welsh courts.
                    </li>
                  </ol>
                  <h2>
                    <strong><strong>Shapla Tandoori Limited</strong></strong>
                    <strong><strong>&nbsp;Trading as </strong></strong>
                    <strong><strong>Shapla Tandoori</strong></strong>
                    <strong><strong> details</strong></strong>
                  </h2>
                  <ol>
                    <li>
                      27.Shapla Tandoori Limited&nbsp;as Shapla Tandoori&nbsp;is a company incorporated in
                      England and Wales with registered number 14286431 whose registered address is
                      180 High Street, Chesterton, Cambridge CB4 1NS&nbsp;and it operates the Website www.shaplatandoori.net. You can
                      contact Shapla Tandoori Limited&nbsp;Trading as Shapla Tandoori&nbsp;by email on
                      info@shaplatandoori.net.
                    </li>
                  </ol>
                  <h2><strong><strong>Attribution</strong></strong></h2>
                  <ol>
                    <li>
                      28. These terms and conditions were created using a document from Rocket Lawyer
                      (https://www.rocketlawyer.co.uk).
                    </li>
                  </ol>
                  <p>&nbsp;</p>

                </div>


              </div>

            </div>
          </div>
        </section>
      </>
    </SlideAnimation>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,

});

export default connect(mapStateToProps)(Conditions);
