const routes = {
    home: '/',

    order: '/order',
    orderConfirmation: '/order/confirmation',
    orderSuccess: '/order/success',
    orderHistory: '/order/history',

    gallery: '/gallery',
    about: '/about',
    awards: '/awards',
    reservation: '/reservation',
    contact: '/contact',
    login: '/login',
    forgetPassword: '/forget-password',
    resetPassword: '/reset-password',
    signup: '/signup',
    points: '/points',
    privacyPolicy: '/privacy-policy',
    termsConditions: '/terms-and-conditions',
    allergyInformation: '/allergy-information',
    userProfile: '/profile',
    manage: '/manage',
    mail: '/mail'
  };

  export default routes;
