/* eslint-disable class-methods-use-this */
import {
  convertOpeningHoursToTime, getCurrentTime, getSettingEntries, getTomorrowDate
} from '@/util/util';
import { ORDER_TYPE_COLLECTION, ORDER_TYPE_DELIVERY } from '@/util/constants';
import { DateTime } from 'luxon';

export default class OpeningValidator {

  // main state
  getState;

  // main state
  settingState;

  // setting data
  settingData = {};

  // opening state
  state;

  // related setting values
  settings = {
    deliveryDelay: 0,
    collectionDelay: 0,
    ignoreCollectionDelayInLast: true,
    fullTimeStart: false,
    fullTimeEnd: true,
  };

  orderTypeDelivery = false;

  data = {
    isOffDay: false,
    isOpen: false,
    willOpen: false,
    collection: false,
    delivery: false,
    orderTiming: {
      today: true,
      hours: [],
    },
  };

  constructor(getState) {
    this.getState = getState;
    this.state = getState().opening;
    this.settingState = this.getState().setting.data;

    // get current orderType
    this.orderTypeDelivery = (this.getState().cart.order.type === ORDER_TYPE_DELIVERY);
  }

  getSettings() {

    return new Promise((resolve) => {

      const settingInterval = setInterval(() => {
        const settingLength = Object.keys(this.settingData).length;

        // if we dont have setting data then try again
        if (!settingLength) {

          // fetch data
          const data = this.getState().setting.data;
          const dataLength = Object.keys(data).length;

          if (!dataLength) return;

          this.settingData = data;
        }

        // when setting found
        clearInterval(settingInterval);

        const selectedSettings = getSettingEntries(this.settingData, [
          'full_time_order_start',
          'full_time_order_end',
          'delivery_order_delivery_time',
          'collection_order_delivery_time',
          'ignore_collection_order_time_in_last'
        ]);

        this.settings = {
          deliveryDelay: parseInt(selectedSettings.delivery_order_delivery_time),
          collectionDelay: parseInt(selectedSettings.collection_order_delivery_time),
          ignoreCollectionDelayInLast: selectedSettings.ignore_collection_order_time_in_last,
          fullTimeStart: selectedSettings.full_time_order_start,
          fullTimeEnd: selectedSettings.full_time_order_end,
        };

        resolve();

      }, 300);

    });
  }


  async getValidationData() {

    // if setting still not available
    await this.getSettings();

    // today & tomorrow timing
    const timings = this.getTimings();

    // if todays date not found then today is off day
    this.data.isOffDay = !(timings.today);

    // get today or tomorrow order timing
    const orderTiming = this.getOrderTiming(timings);
    this.data.orderTiming.hours = orderTiming ?? [];

    // if no timing available then not open
    if (!orderTiming) {
      this.data.isOpen = false;
      this.data.willOpen = false;
    } else {
      this.validateOrderType();
    }

    return { ...this.data };
  }

  validateOrderType() {
    // validate tomorrow date
    if (!this.data.orderTiming.today && this.data.orderTiming.hours.length) {
      this.data.orderTiming.hours.map((hourSet) => {

        // if tomorrow delivery / collection is enabled then enable that
        if (hourSet.delivery) this.data.delivery = true;
        if (hourSet.collection) this.data.collection = true;
      });

      return;
    }

    // today validation
    const currentTime = getCurrentTime();
    let delivery = false;
    let collection = false;

    // if will open
    if (!this.data.isOpen && this.data.willOpen) {

      // get valid open time
      if (this.data.orderTiming.hours.length > 1) {
        this.data.orderTiming.hours.map((hour) => {

          // if set of time is passed then do nothing
          if (currentTime.toMillis() >= hour.to.toMillis()) return;

          if (hour.delivery) delivery = true;
          if (hour.collection) collection = true;
        });

      } else {

        // when only one hour set available then use that hours order types
        delivery = this.data.orderTiming.hours[0].delivery;
        collection = this.data.orderTiming.hours[0].collection;
      }

      this.data.delivery = delivery;
      this.data.collection = collection;
      return;
    }


    // if open now
    if (!this.data.isOpen) return;

    this.data.orderTiming.hours.map((hour) => {

      // if set of time is passed then do nothing
      if (currentTime.toMillis() >= hour.to.toMillis()) return;

      // check delivery time with buffer minutes
      const { delivery, collection } = this.validateTodaysOrderTypes(hour, currentTime);

      if (delivery) this.data.delivery = delivery;
      if (collection) this.data.collection = collection;

    });
  }

  /**
   * Validates if current time is valid in hours set for order types
   * @param hourSet : {from:DateTime,to:DateTime,delivery:boolean,collection:boolean}
   * @param currentTime : DateTime
   * @return {{delivery: boolean, collection: boolean}}
   */
  validateTodaysOrderTypes(hourSet, currentTime) {

    const data = {
      delivery: false,
      collection: false
    };

    // delivery validation
    if (hourSet.delivery) {
      let delayMinutes = this.getOrderTypeDelayMinutes(ORDER_TYPE_DELIVERY);
      const lastTimeOfTiming = hourSet.to;

      delayMinutes = this.settings.fullTimeStart ? 0 : delayMinutes;

      if (!this.settings.fullTimeStart) {

        const currentTimeWithBuffer = this.applyMinutes(currentTime, delayMinutes);

        // check if current time is <= closing time, then delay will be 0
        if (currentTimeWithBuffer.toMillis() <= lastTimeOfTiming.toMillis()) data.delivery = true;
      }
    }

    // collection validation
    if (hourSet.collection) {

      let delayMinutes = this.getOrderTypeDelayMinutes(ORDER_TYPE_COLLECTION);
      const lastTimeOfTiming = hourSet.to;

      delayMinutes = this.settings.fullTimeStart ? 0 : delayMinutes;

      // if collection last time ignore set, then don't use buffer minutes
      if (this.settings.ignoreCollectionDelayInLast && !this.settings.fullTimeStart) {

        const currentTimeWithBuffer = this.applyMinutes(currentTime, delayMinutes);

        // check if current time is <= closing time, then delay will be 0
        if (currentTimeWithBuffer.toMillis() >= lastTimeOfTiming.toMillis()) delayMinutes = 0;
      }

      const currentTimeWithBuffer = this.applyMinutes(currentTime, delayMinutes);

      if (currentTimeWithBuffer.toMillis() <= lastTimeOfTiming.toMillis()) data.collection = true;
    }

    return data;
  }

  /**
   * Get todays timing & if not available get tomorrows
   * @param timings : object
   * @return object|null
   */
  getOrderTiming(timings) {

    let timing = timings.today;

    // if today timing not found
    if (!timing) {

      // then get tomorrow time
      timing = timings.tomorrow;

      // if today timing found then set tomorrow
      if (timing) this.data.orderTiming.today = false;
      return timing;
    }

    // if today timing found check if that's valid
    const currentTime = getCurrentTime();
    const todayValid = this.checkIfValidInOpeningTiming(currentTime, timing);

    if (!todayValid) {

      // check if today will open or not
      const lastOpeningHour = timing.slice(-1).pop().to;

      const willOpen = (currentTime.toMillis() <= lastOpeningHour.toMillis());
      this.data.willOpen = willOpen;

      // if today will not open then use tomorrow time
      if (!willOpen) {
        timing = timings.tomorrow;
        if (timing) this.data.orderTiming.today = false;
      }
    } else {
      this.data.isOpen = true;
    }

    return timing;
  }

  /**
   * Get Today & tomorrows timings
   * @return {{today: (string|null), tomorrow: (string|null)}}
   */
  getTimings() {

    const todayName = DateTime.now().weekdayShort
      .toLowerCase(); // Sun;
    const tomorrowName = getTomorrowDate().weekdayShort
      .toLowerCase();

    const times = {
      today: this.getDaysTiming(todayName),
      tomorrow: this.getDaysTiming(tomorrowName)
    };

    // convert timings
    if (times.today) {
      times.today = convertOpeningHoursToTime(times.today);
    }

    if (times.tomorrow) {
      times.tomorrow = convertOpeningHoursToTime(times.tomorrow, getTomorrowDate());
    }

    return times;
  }

  /**
   * Get day timing from day name
   * @param dayName : string
   * @return string|null
   */
  getDaysTiming(dayName) {
    return this.state.data.find((dayTiming) => dayTiming.day === dayName) ?? null;
  }

  /**
   * checks if provided time is valid open time in opening hours
   * @param checkTime : DateTime
   * @param timings : object
   * @param checkWithBufferTime : boolean
   * @return boolean
   */
  checkIfValidInOpeningTiming(
    checkTime,
    timings,
    checkWithBufferTime = true
  ) {

    let isValid = false;

    timings.map((timing) => {

      // if current iteration of time is withing defined opening times
      const check = this.checkIfValidOpeningTime(checkTime, timing, checkWithBufferTime);

      // when valid set value;
      if (check) isValid = check;
    });

    return isValid;
  }

  /**
   * Checks if a given time is valid opening time in provided opening times
   * @param checkTime : DateTime
   * @param openingTiming : object
   * @param applyBufferTime : boolean
   * @return boolean
   */
  checkIfValidOpeningTime(
    checkTime,
    openingTiming,
    applyBufferTime = true
  ) {

    if (!(checkTime) || !(openingTiming)) return false;

    const timing = applyBufferTime
      ? this.applyBufferTimeInTiming(openingTiming)
      : openingTiming
    ;

    // add a minute to allow last 5 minute selection in picker
    // const endTime = DateFns.set(timing.to, { seconds: 59 });

    const isValid = (
      (checkTime.toMillis() >= timing.from.toMillis()) && (checkTime.toMillis() <= timing.to.toMillis())
      // (checkTime.getTime() <= endTime.getTime())
    );

    return isValid;
  }

  /**
   * Applies buffer minutes to start & end time of an hour set
   * @param timing : object
   * @return {{from: Date, to: Date}}
   */
  applyBufferTimeInTiming(timing) {
    return {
      from: this.addBufferMinutes(timing.from, true, timing.to),
      to: this.addBufferMinutes(timing.to, false),
    }
  }

  /**
   * Add delivery buffer minutes to order tims
   * @param time : DateTime
   * @param isStartTime : boolean
   * @param lastTimeOfTiming : DateTime|null
   * @return DateTime
   */
  addBufferMinutes(time, isStartTime = true, lastTimeOfTiming = null) {

    // get delay time by order type
    const orderTypeDelay = this.getOrderTypeDelayMinutes();

    // get buffer minutes by time type
    let delayMinutes;

    if (isStartTime) {
      delayMinutes = this.settings.fullTimeStart ? 0 : orderTypeDelay;

      // if collection last time ignore set, then don't use buffer minutes
      if (this.settings.ignoreCollectionDelayInLast && lastTimeOfTiming && !this.orderTypeDelivery) {

        const currentTimeWithBuffer = this.applyMinutes(getCurrentTime(), delayMinutes);

        // check if current time is <= closing time, then delay will be 0
        if (currentTimeWithBuffer.toMillis() >= lastTimeOfTiming.toMillis()) delayMinutes = 0;
      }

    } else {
      delayMinutes = this.settings.fullTimeEnd ? 0 : orderTypeDelay;
    }

    // apply buffer time
    return isStartTime
      ? this.applyMinutes(time, delayMinutes)
      : this.applyMinutes(time, delayMinutes, false)
      ;
  }

  validateOrderTypeInTiming(checkTime, hourSet, orderType = null) {

    const orderTypeDelay = this.getOrderTypeDelayMinutes(orderType);

    const startDelay = this.settings.fullTimeStart ? 0 : orderTypeDelay;
    const endDelay = this.settings.fullTimeEnd ? 0 : orderTypeDelay;

    const startTime = this.applyMinutes(hourSet.from, startDelay);
    const endTime = this.applyMinutes(hourSet.to, endDelay);

    const isValid = (
      (checkTime.getTime() >= startTime.getTime()) && (checkTime.getTime() <= endTime.getTime())
    );
    return isValid;
  }

  /**
   * Gets order delay minutes by order type
   * @param orderType : string|null if null then uses current order type
   * @return number
   */
  getOrderTypeDelayMinutes(orderType = null) {

    let typeDelivery;

    switch (orderType) {
      case ORDER_TYPE_DELIVERY:
        typeDelivery = true;
        break;

      case ORDER_TYPE_COLLECTION:
        typeDelivery = false;
        break;

      default:
        typeDelivery = this.orderTypeDelivery;
        break;
    }

    return typeDelivery
      ? this.settings.deliveryDelay
      : this.settings.collectionDelay
    ;
  }

  /**
   * Applies buffer minutes to given time
   * @param time : DateTime
   * @param minutes : number
   * @param shouldAdd : boolean Add or Sub minute
   * @return DateTime
   */
  applyMinutes(time, minutes = 0, shouldAdd = true) {
    // apply buffer time
    return shouldAdd
      ? time.plus({ minute: minutes })
      : time.minus({ minute: minutes })
    ;
  }

}
