import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Hidden, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import BottomNav from './BottomNav';
import useSetting from '@/hooks/useSetting';
import { SETTING_FILE_PATH } from '@/util/constants';
import routes from '@/util/routes';
import Copyright from '../util/copyright';
import { getTimeFormatFromTimeString } from '@/util/util';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import './footer.scss';
import tripImage from '@/assets/images/gallery/trip.png';


function Footer({ ...otherProps }) {

  const setting = useSetting(['site_title',
    'facebook_url',
    'instagram_url',
    'trip_advisor',
    'address',
    'country_code',
    'phone',
    'display_email',
    'designed_by',
    'copyright_text',
    'site_logo_small',
    'about_description',

  ]);

  const [isVisible, setIsVisible] = useState(false);

  const footerTiming = useMemo(() => (
    <>
      {
        otherProps.opening.map((day, index) => (
          <Box key={`${day.id}_${index}`}>
            <Typography
              variant="body1"
              className="footer-time"
              style={{
                fontSize: 18,
                fontWeight: 600
              }}
            >
              {/*hide day name if no hours in it is shown*/}
              {
                (day.display_from || (day.hours.filter((hr) => hr.show).length))
                  ? day.day
                  : ''
              }
            </Typography>
            {
              day.display_from && day.display_to
                ? (
                  <Typography
                    key={`hr${day.id}_${index}`}
                    variant="body1"
                    className="footer-time text-uppercase"
                    style={{
                      marginLeft: 30
                    }}
                  >
                    {day.display_from}
                    {' '}
                    -
                    {' '}
                    {day.display_to}
                  </Typography>
                )
                : day.hours.map((hour, index) => (
                  <Typography
                    key={`hr${hour.id}_${index}`}
                    variant="body1"
                    className="footer-time text-uppercase"
                    style={{
                      marginLeft: 30
                    }}
                  >
                    {hour.show && getTimeFormatFromTimeString(hour.from, '', false)}
                    {' '}
                    {hour.show && '-'}
                    {' '}
                    {hour.show && getTimeFormatFromTimeString(hour.to, '', false)}
                  </Typography>
                ))
            }
          </Box>
        ))
      }
    </>
  ), [otherProps.opening]);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, [])


  const listenToScroll = () => {
    let heightToHideFrom = 20;
    const winScroll = document.body.scrollTop
      || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
       setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  if (!otherProps.show_footer) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }


  return (
    <>
      <section className="w3l-footer-29-main">
        <div className="footer-29 py-3">
          <div className="container py-lg-4">
            <div className="row footer-top-29">
              <div className="footer-list-29 col-lg-4">
                {/*<h6 className="footer-title-29">About Company</h6>*/}
                <h6 className="footer-title-29">{setting.site_title}</h6>
                <img src={`${SETTING_FILE_PATH + setting.site_logo_small}`} style={{ maxWidth: '100%' }} alt="Shapla Tandoori" />
                <p className="footer-about">
                  Shapla Tandoori understand that people have long been searching for the perfect curry and have made a pledge not to disappoint with their exotic flavours🌶 
                </p>

              </div>
              <div className="col-lg-3 col-md-6 col-sm-8 footer-list-29 footer-1 mt-lg-0 mt-5">
                <h6 className="footer-title-29">Contact Us</h6>
                <ul>
                  <li>
                    <p className="footer-address">
                      <RoomIcon />
                      {' '}
                      {setting.address}
                    </p>
                  </li>
                  <li>
                    <a className="footer-phone" href={`tel:${setting.country_code}-${setting.phone}`}>
                      <PhoneIcon />
                      {' '}
                      (
                      {setting.country_code}
                      )-
                      {setting.phone}
                    </a>
                  </li>
                  <li>
                    <a href={`mailto: ${setting.display_email}`} className="footer-email">
                      <EmailIcon />
                      {' '}
                      {setting.display_email}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-4 footer-list-29 footer-2 mt-lg-0 mt-5">
                <ul>
                  <h6 className="footer-title-29">Useful Links</h6>
                  <li><Link to={routes.about}>About Us</Link></li>
                  <li><Link to={routes.privacyPolicy}>Privacy Policy</Link></li>
                  <li><Link to={routes.termsConditions}>Terms & Conditions</Link></li>
                  <li><Link to={routes.allergyInformation}>Allergy Information</Link></li>
                  <li><Link to={routes.contact}>Contact Us</Link></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-5">
                <h6 className="footer-title-29">Opening Times</h6>
                <div className="footer-post mb-4">
                  {
                    footerTiming
                  }
                </div>
              </div>
              <div className="col-lg-12 footer-list-29 footer-4 mt-3">
                <div className="column1 align-self">
                  <h6 className="footer-title-29  mb-1">We're Social Like You</h6>
                  <p>Follow and Like us on our social media</p>
                </div>
                <div className="main-social-footer-29  text-center">
                  {
                    setting.facebook_url !== '#'
                    && (
                      <a href={setting.facebook_url} target="_blank" rel="noreferrer" className="facebook">
                        <FacebookIcon />
                      </a>
                    )
                  }

                  {
                    setting.trip_advisor !== '#'
                    && (
                      <a href={setting.trip_advisor} target="_blank" rel="noreferrer" className="twitter">
                        <img src={tripImage} alt="trip-advisor" className="trip-advisor" />
                      </a>
                    )
                  }

                  {
                    setting.instagram_url !== '#'
                    && (
                      <a href={setting.instagram_url} target="_blank" rel="noreferrer" className="instagram">
                        <InstagramIcon />
                      </a>
                    )
                  }

                </div>

              </div>
            </div>
          </div>
        </div>

      </section>


      <section className="w3l-footer-29-main w3l-copyright">
        <div className="container">
          <div className="bottom-copies copy-footer-29 text-center">

            <Copyright />

          </div>

        </div>

        <Hidden lgDown>
          {
            isVisible
            && <button onClick={() => topFunction()} id="movetop" title="Go to top">&#10548;</button>
          }
        </Hidden>


        <Hidden mdUp>
          <BottomNav />
        </Hidden>


      </section>
    </>
  );
}

const mapStateToProps = (state) => ({

  show_footer: state.layout.show_footer,
  opening: state.opening.data,
  isLogin: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(Footer);
