import { deepPurple, deepOrange, red } from '@mui/material/colors';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Avatar, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { RootState } from '@/store';

const classes = {
  root: {
    display: 'flex',
    '& > *': {
      margin: (t: Theme) => t.spacing(1),
    },
  },
  orange: {
    color: (t: Theme) => t.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: (t: Theme) => t.palette.getContrastText(deepPurple[500]),
    backgroundColor: red[700],
  },
};

function UserAvatar({ ...otherProps }) {

  if (!otherProps.isLoggedIn && _.isEmpty(otherProps.lastUser)) {
    return (
      <Box sx={classes.root}>
        <Avatar sx={classes.purple} />
      </Box>
    );
  }

  const getUserChars = () => {
    const userName = otherProps.user.name || otherProps.lastUser.name;

    let nameSplit = userName.split(' ');

    let chars = '';

    if (nameSplit.length === 1) {
      chars += nameSplit[0][0]; // first char
      chars += nameSplit[nameSplit.length - 1][1]; // last char
      return chars;
    }

    chars += nameSplit[0][0]; // first char of first element
    chars += nameSplit[nameSplit.length - 1][0]; // first char of last element

    return chars;
  };

  return (
    <Box sx={classes.root} title={otherProps.user.name || otherProps.lastUser.name}>
      <Avatar sx={classes.purple}>{getUserChars()}</Avatar>
    </Box>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data,
  lastUser: state.user.lastUser
});

export default connect(mapStateToProps)(UserAvatar);
