import { useEffect, useReducer, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  FormControlLabel,
  FormGroup,
  Alert,
  CircularProgress,
  Checkbox,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import { footerShowHide, headerShowHide } from '@/store/layout/layoutActions';
import { login, registrationDirect } from '@/store/user/userActions';
import _ from 'lodash';
import routes from '@/util/routes';
import Copyright from '@/components/util/copyright';
import { formSingleValidator, formValidator, validationRules } from '@/util/formValidation';
import useNotify from '@/hooks/useNotify';
import useRedirectIfAuthenticated from '@/hooks/useRedirectIfAuthenticated';
import useRedirectToIntended from '@/hooks/useRedirectToIntended';
import loginImage from '@/assets/images/login.jpg';


const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    name: false,
    email: false,
    password: false,
    password_confirmation: false,
    phone: false
  }
};

function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) || 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function Register(props) {

  // redirect users if they already authenticated
  useRedirectIfAuthenticated();

  // redirect to the page they wanted to go before interception
  useRedirectToIntended();

  const styles = {
    root: {
      // height: '100vh',
    },
    image: {
      backgroundImage: `url('${loginImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        (theme) => (theme.palette.mode === 'light' ? (theme) => theme.palette.grey[50] : (theme) => theme.palette.grey[900]),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: (theme) => theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: (theme) => theme.spacing(1),
      backgroundColor: (theme) => theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: (theme) => theme.spacing(1),
    },
    submit: {
      margin: (theme) => theme.spacing(3, 0, 2),
    },
  };

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  const [isloading, setIsLoading] = useState(false);

  // hide/show header footer
  useEffect(() => {
    // hiding header footer
    toggleHeaderFooter(false);
    // showing header footer
    return () => toggleHeaderFooter(true);

  }, []);

  const toggleHeaderFooter = (show) => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }

  const [notify] = useNotify();

  // handle form inputs
  const [credentials, setCredentials] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phone: '',
  });

  const [isTermSelected, setIsTermSelected] = useState(false);

  const validationSchema = {
    name: [
      validationRules.required(),
      validationRules.containOnlyAlphabet(),
      validationRules.min([3]),
      validationRules.max([30])
    ],
    email: [
      validationRules.required(),
      validationRules.email()
    ],
    password: [
      validationRules.required(),
      validationRules.min([8]),
      validationRules.max([30]),
      validationRules.alphaNumeric()
    ],
    password_confirmation: [
      validationRules.matchWithField(['password', credentials.password])
    ],
    phone: [
      validationRules.required(),
      validationRules.min([7])
    ]
  };

  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCredentials({
      ...credentials,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);

      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors,
      });
    }, 500)();
  };


  const handleTermsCheck = (e) => {
    setIsTermSelected(e.target.checked);
  }

  const submitHandler = async (e) => {

    setIsLoading(true);

    e.preventDefault();

    // all field validation before submit
    const { error, data } = await formValidator(credentials, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      setIsLoading(false);
      return;
    }
    if (!isTermSelected) {
      notify.warning('Please checked terms & conditions to signup');
      setIsLoading(false);
      return;
    }

    try {

      errorDispatch({
        type: errorTypes.SUBMIT
      });

      const registration = await props.registration(
        credentials.name,
        credentials.email,
        credentials.password,
        credentials.password_confirmation,
        credentials.phone
      );


      if (registration.status === 200) {
        setIsLoading(false);
        errorDispatch({
          type: errorTypes.SUBMIT_SUCCESS,
          payload: registration.message
        });
        notify.success('Sign up complete, you are successfully logged in.');
        props.login(credentials.email, credentials.password);
      } else {
        setIsLoading(false);

        errorDispatch({
          type: errorTypes.SUBMIT_FAIL,
          payload: registration.message
        });
        errorDispatch({
          type: errorTypes.FIELD_ERROR,
          payload: { email: registration.errors.email[0] },
        });
      }

    } catch (e) {
      setIsLoading(false);
      const err = e.response?.data;

      if (!err) {
        errorDispatch({
          type: errorTypes.SUBMIT_FAIL,
          payload: err?.message
        });
      }
    }

  };

  return (
    <Grid container component="main" sx={styles.root}>
      <CssBaseline />
      <Grid item xs={false} lg={8} sx={styles.image} />
      <Grid item xs={12} sm={12} md={6} lg={4} className="login-form-center" component={Paper} elevation={6} square>
        <Box sx={styles.paper}>

          <Avatar sx={styles.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>

          {
            errorState.error
            && <Alert severity="error" variant="standard">{errorState.message}</Alert>
          }
          {
            errorState.success
            && <Alert severity="success" variant="standard">{errorState.message}</Alert>
          }

          <Box component="form" noValidate onSubmit={submitHandler} style={{ height: '100vh' }}>

            <TextField
              type="text"
              variant="outlined"
              margin="dense"
              required
              fullWidth
              autoComplete="off"
              id="name"
              label="Full Name"
              name="name"
              error={!!errorState.fields.name}
              helperText={errorState.fields.name}
              value={credentials.name}
              onChange={changeHandler}
            />

            <TextField
              type="email"
              variant="outlined"
              margin="dense"
              required
              autoComplete="off"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              error={!!errorState.fields.email}
              helperText={errorState.fields.email}
              value={credentials.email}
              onChange={changeHandler}
            />
            <TextField
              type="password"
              variant="outlined"
              margin="dense"
              autoComplete="off"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              error={!!errorState.fields.password}
              helperText={errorState.fields.password}
              value={credentials.password}
              onChange={changeHandler}
            />
            <TextField
              type="password"
              variant="outlined"
              margin="dense"
              required
              autoComplete="off"
              fullWidth
              name="password_confirmation"
              label="Confirm Password"
              id="password_confirmation"
              error={!!errorState.fields.password_confirmation}
              helperText={errorState.fields.password_confirmation}
              value={credentials.password_confirmation}
              onChange={changeHandler}
            />
            <TextField
              type="number"
              variant="outlined"
              margin="dense"
              required
              autoComplete="off"
              fullWidth
              id="phone"
              label="Phone no."
              name="phone"
              error={!!errorState.fields.phone}
              helperText={errorState.fields.phone}
              value={credentials.phone}
              onChange={changeHandler}
            />

            <FormGroup>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isTermSelected}
                      onChange={handleTermsCheck}
                    />
                  )}
                  label={(
                    <>
                      <span>I accept the </span>
                      <RouterLink to={routes.termsConditions}>
                        terms & conditions
                      </RouterLink>
                    </>
                  )}
                />

              </Box>

            </FormGroup>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={styles.submit}
            >

              Sign Up
              {
                isloading
                && <CircularProgress color="info" size={25} style={{ marginLeft: 7 }} />
              }
            </Button>

            <Grid container>
              <Grid item xs>
                <RouterLink to={routes.home}>
                  Back To Home
                </RouterLink>
              </Grid>
              <Grid item>
                <RouterLink to={routes.login}>
                  Back to Login
                </RouterLink>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  showHideHeader: (shouldShow) => dispatch(headerShowHide(shouldShow)),
  showHideFooter: (shouldShow) => dispatch(footerShowHide(shouldShow)),
  login: (email, password, remember) => dispatch(login(email, password, remember)),
  registration: (name, email, password, confirmPassword, phone) => dispatch(registrationDirect(name, email, password, confirmPassword, phone))
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
