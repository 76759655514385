import { useEffect, useMemo, useState } from 'react';
import { MobileTimePicker } from '@mui/x-date-pickers';
import {
  Box, Button, FormControl, Grid, TextField, Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { cartDeliveryTimeUpdate, cartUpdateASAPTime } from '@/store/cart/cartActions';
import {
  formatDateTime, getCurrentTime, getTomorrowDate, isTimeSetAllowsOrderType
} from '@/util/util';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import useSetting from '@/hooks/useSetting';
import useNotify from '@/hooks/useNotify';
import { ORDER_TYPE_COLLECTION, ORDER_TYPE_DELIVERY } from '@/util/constants';
import { useNavigate } from 'react-router-dom';
import routes from '@/util/routes';


function DateTimeComp({ setAllowNext, ...otherProps }) {

  const navigate = useNavigate();

  const [notify] = useNotify();

  const settings = useSetting([
    'full_time_order_start',
    'full_time_order_end',
    'delivery_order_delivery_time',
    'collection_order_delivery_time',
  ]);

  const settingValues = useMemo(() => ({
    fullTimeStart: settings.full_time_order_start ?? false,
    fullTimeEnd: settings.full_time_order_end ?? false,
    deliveryDelay: parseInt(settings.delivery_order_delivery_time ?? 0),
    collectionDelay: parseInt(settings.collection_order_delivery_time ?? 0),
  }), [settings]);

  const ASAPTimeAllowed = useMemo(() => {

    // if closed then disable ASAP
    if (!otherProps.isOpen) return false;

    // find current available time set
    const currentTimeSet = otherProps.orderTiming.find((timing) => {
      const currentTime = getCurrentTime(true);

      return (
        (timing.from.toMillis() <= currentTime.toMillis())
        && (timing.to.toMillis() >= currentTime.toMillis())
      );
    });

    // if no time available
    if (currentTimeSet === undefined) return false;

    // dont allow when current delivery type is
    // not allowed in current time

    // if delivery
    if (otherProps.orderType === ORDER_TYPE_DELIVERY) return currentTimeSet.delivery;

    // if delivery
    if (otherProps.orderType === ORDER_TYPE_COLLECTION) return currentTimeSet.collection;

    return true;

  }, [otherProps.isOpen]);

  useEffect(() => {
    handleASAPSelect(ASAPTimeAllowed);
  }, [ASAPTimeAllowed]);

  // control picker show hide
  const [pickerShow, setPickerShow] = useState(false);

  // picker changed datetime
  const [selectedTime, setSelectedTime] = useState(() => {
    const previouslySelected = otherProps.deliveryTime;
    return previouslySelected ?? getCurrentTime();
  });

  // only used to track if cancelled the timer
  const [lastAcceptedTime, setLastAcceptedTime] = useState(selectedTime);

  // get minimum selectable time
  const minTime = useMemo(() => {

    const bufferMinutes = getOrderBufferMinutes();

    // when ordering for today
    if (otherProps.isToday) {
      const currentTime = getCurrentTime(true);

      if (!otherProps.orderTiming.length) return currentTime;

      let minTime = currentTime;
      let bufferApplied = false;
      let minTimeSet = false;

      // find start time from available hours that is greater than current time
      otherProps.orderTiming.map((timing) => {

        // do not try to set min time if already set
        if (minTimeSet) return;

        // add buffer minutes to start time
        const fromTime = timing.from.plus({
          minute: bufferMinutes.start
        });

        const toTime = timing.to.minus({
          minute: bufferMinutes.start
        });

        // if within this time range then use current ime
        if (
          (fromTime.toMillis() <= currentTime.toMillis())
          && (toTime.toMillis() >= currentTime.toMillis())
        ) {

          // check if timeSet allows order type
          if (isTimeSetAllowsOrderType(timing, otherProps.orderType)) {
            minTimeSet = true;
            return;
          }

        }


        // when not open but will be
        // set minTime to start time or current time whichever is greater
        if (fromTime.toMillis() >= currentTime.toMillis()) {
          minTime = fromTime;
          bufferApplied = true;
          minTimeSet = true;
        }

      });

      // add buffer minutes if not already added
      if (!bufferApplied) {
        return minTime.plus({
          minute: bufferMinutes.start
        });
      }

      return minTime;
    }

    // when tomorrow
    // set date if tomorrow is unavailable
    let minTIme = otherProps.orderTiming?.[0]?.from ?? getTomorrowDate();
    return minTIme.plus({
      minute: bufferMinutes.start
    });

  }, [otherProps.isToday, otherProps.orderTiming]);

  const disableTimeSelect = useMemo(() => {

    let shouldDisable = false;

    otherProps.orderTiming.map((timing) => {
      const lastTime = timing.to;

      // disable time selection when minimum valid time is > last time
      shouldDisable = (minTime.toMillis() >= lastTime.toMillis());
    });

    return shouldDisable;
  }, [minTime]);

  // watch for timeout
  useEffect(() => {
    // use min time as selected time
    setSelectedTime(minTime);
    setLastAcceptedTime(minTime);

    const currentTime = getCurrentTime(true);

    if (currentTime.toMillis() >= minTime.toMillis()) {
      navigate(routes.order);
    }

  }, [minTime]);

  // sync selected time with state
  useEffect(() => {

    // set time
    otherProps.setDeliveryTime(lastAcceptedTime);

  }, [lastAcceptedTime]);

  /**
   * Validates if given date time is valid order time
   * @param selectedDTime : DateTime
   * @return boolean
   */
  function checkIfValidOrderTime(selectedDTime) {
    let isValid = false;

    const bufferMinutes = getOrderBufferMinutes();

    // current time set
    const currentTimeSet = getOrderableHours();
    if (!currentTimeSet) return isValid;

    currentTimeSet.map((timeSet) => {

      // apply buffer minutes to date time
      const startTime = timeSet.from.plus({ minute: bufferMinutes.start });
      const endTime = timeSet.to.minus({ minute: bufferMinutes.end });

      // apply start times date in selected dateTime, so it can
      // compare to other dates than today
      const selectedTime = startTime.set({
        hour: selectedDTime.hour,
        minute: selectedDTime.minute
      });

      // if today minimum time is calculated minTime or start time for tomorrow
      const minimumTime = otherProps.isToday ? minTime : startTime;

      const valid = (
        (selectedTime.toMillis() >= minimumTime.toMillis())
        && (selectedTime.toMillis() >= startTime.toMillis())
        && (selectedTime.toMillis() <= endTime.toMillis())
      );

      if (valid) isValid = valid;

    });

    return isValid;
  }

  // get hours that can be used to order today or tomorrow
  function getOrderableHours() {

    // when no order time available then nothing to find
    if (!otherProps.orderTiming.length) return false;

    // if not today then use tomorrow's times
    if (!otherProps.isToday) return otherProps.orderTiming;

    const currentTime = getCurrentTime(true);

    // get times set that is not passed
    const dTime = otherProps.orderTiming.filter((timing) => currentTime.toMillis() <= timing.to.toMillis());

    return dTime;
  }

  function getOrderBufferMinutes() {

    const isOrderTypeDelivery = (otherProps.orderType === ORDER_TYPE_DELIVERY);

    // get delay time by order type
    const orderTypeDelay = isOrderTypeDelivery
      ? settingValues.deliveryDelay
      : settingValues.collectionDelay
    ;

    const bufferMinutes = {
      start: settingValues.fullTimeStart ? 0 : orderTypeDelay,
      end: settingValues.fullTimeEnd ? 0 : orderTypeDelay
    };

    // skip start buffer minutes if order type 'collection'
    // & <= of (buffer) minutes remaining to close

    // do it only when start buffer minutes available
    if (bufferMinutes.start && !isOrderTypeDelivery) {

      // if not today then no need to skip buffer minutes
      if (!otherProps.isToday) return bufferMinutes;

      const currentTimeSet = getCurrentTimeSet();

      // if time set not found then nothing to calculate
      if (!currentTimeSet) return bufferMinutes;

      const minToClose = getMinutesToClose();

      // when already closed then nothing to calculate
      if (minToClose < 0) return bufferMinutes;

      // when minutes to close is less than start buffer minutes
      // then ignore start buffer minutes
      if (minToClose <= bufferMinutes.start) bufferMinutes.start = 0;
    }

    return bufferMinutes;
  }

  function getCurrentTimeSet() {

    // when no order time available then nothing to find
    if (!otherProps.orderTiming.length) return false;

    // if not today then use tomorrow's 1st time
    if (!otherProps.isToday) return otherProps.orderTiming[0];

    const currentTime = getCurrentTime();

    // get 1st time set that is not passed
    const dTime = otherProps.orderTiming.find((timing) => currentTime.toMillis() <= timing.to.toMillis());

    // when time not found then
    return dTime ?? false;
  }

  /**
   * Get how many minutes left to close
   * @param checkTime : DateTime
   * @return number - of minutes to close
   */
  function getMinutesToClose(checkTime = null) {

    const cTime = checkTime ?? getCurrentTime();

    const currentTime = cTime.set({
      second: 0,
      millisecond: 0
    });

    const currentTimeSet = getCurrentTimeSet();
    if (!currentTimeSet) return 0;

    const closingTime = currentTimeSet.to;
    const minToClose = closingTime.diff(currentTime, 'minutes');

    // when already closed then nothing to calculate
    if (minToClose < 0) return 0;

    return minToClose;
  }

  // reset minutes to 0
  const handleOpen = () => {

    setPickerShow(true);

    setSelectedTime((selectedDate) => selectedDate.set({
      second: 0
    }));
  };

  const availableTimesText = useMemo(() => (
    <Box>
      <Box>
        Available Time:
        <span className="text-secondary">
          {
            (!otherProps.isToday && otherProps.orderTiming.length)
              ? ` (${formatDateTime(otherProps.orderTiming[0].from, 'MMM dd')})`
              : ''
          }
        </span>
      </Box>

      {
        otherProps.orderTiming.map((timing, idx) => (
          <Typography
            key={idx}
            color="primary"
          >
            {formatAvailableTime(timing)}
          </Typography>
        ))
      }
    </Box>
  ), [pickerShow]);

  function formatAvailableTime(timing) {

    // don't continue if timing doesn't support current order type

    const orderTypeDelivery = otherProps.orderType === ORDER_TYPE_DELIVERY;
    const orderTypeCollection = otherProps.orderType === ORDER_TYPE_COLLECTION;

    // if order type delivery but timing doesn't support it
    if (orderTypeDelivery && !timing.delivery) return null;

    // if order type collection but timing doesn't support it
    if (orderTypeCollection && !timing.collection) return null;

    const timeFormat = 'HH:mm';

    let startTime = timing.from;
    let endTime = timing.to;

    const currentTime = getCurrentTime();

    // if time passed then return nothing
    if (currentTime.toMillis() > endTime.toMillis()) return null;

    // use current time as start time if current time > start time
    if (currentTime.toMillis() >= startTime.toMillis()) startTime = currentTime;

    // apply buffer minutes
    const bufferMinutes = getOrderBufferMinutes();

    startTime = startTime.plus({ minute: bufferMinutes.start });
    endTime = endTime.minus({ minute: bufferMinutes.end });

    // if start time > end time then nothing
    if (startTime.toMillis() >= endTime.toMillis()) return null;

    const startFormat = formatDateTime(startTime, timeFormat);
    const endFormat = formatDateTime(endTime, timeFormat);

    return `${startFormat} - ${endFormat}`;
  }

  const handleASAPSelect = (value) => {
    otherProps.setDeliveryIsASAPTime(value);
  }

  /**
   * Validates selected date time
   * @param acceptedTime : DateTime
   */
  const validateSelectedTime = (acceptedTime = selectedTime) => {

    // remove seconds & ms
    const acceptedTimeClean = acceptedTime.set({
      second: 0,
      millisecond: 0
    });

    // validate if accepted time is valid
    const isValidTime = checkIfValidOrderTime(acceptedTimeClean);

    if (isValidTime) {
      // allow getting to next step
      setAllowNext();

      setPickerShow(false);

      // set next day date if tomorrow
      if (!otherProps.isToday) {
        const tomorrowDate = getTomorrowDate()
          .set({
            hour: acceptedTime.hour,
            minute: acceptedTime.minute,
            second: 0,
            millisecond: 0
          });

        setLastAcceptedTime(tomorrowDate);
        return;
      }

      setLastAcceptedTime(acceptedTime);
      return;
    }

    const errorMsg = 'Please select valid time to continue!';

    setAllowNext(errorMsg);
    setPickerShow(false);

    notify.warning(errorMsg);
  };


  return (
    <Grid container>
      <Grid item xs={12} md={6}>

        {/*pre order msg*/}
        {
          !otherProps.isOpen && otherProps.willOpen && (
            <Typography
              component={Box}
              variant="h5"
              color="primary"
              className="text-capitalize"
            >
              Pre order for today
            </Typography>
          )
        }

        {/*closed msg*/}
        {
          !otherProps.isToday && (
            <Typography
              component={Box}
              variant="h5"
              color="primary"
              className="text-capitalize"
            >
              We're closed for today
              {otherProps.orderTiming && ' but you can order for tomorrow'}
            </Typography>
          )
        }

        <form noValidate>

          {/*ASAP or select time*/}
          {
            otherProps.orderTiming && (
              <FormControl
                sx={{
                  mt: 2
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    variant={`${otherProps.cart.delivery.isAsapTime ? 'outlined' : 'contained'}`}
                    startIcon={otherProps.cart.delivery.isAsapTime
                      ? <RadioButtonUncheckedIcon />
                      : <RadioButtonCheckedIcon />}
                    onClick={() => handleASAPSelect(false)}
                    color="primary"
                    disabled={disableTimeSelect}
                  >
                    Select Time
                  </Button>

                  <Box
                    sx={{
                      marginLeft: 2,
                      marginRight: 2
                    }}
                  >
                    OR
                  </Box>

                  <Button
                    variant={`${otherProps.cart.delivery.isAsapTime ? 'contained' : 'outlined'}`}
                    startIcon={otherProps.cart.delivery.isAsapTime
                      ? <RadioButtonCheckedIcon />
                      : <RadioButtonUncheckedIcon />}
                    onClick={() => handleASAPSelect(true)}
                    color="primary"
                    disabled={!ASAPTimeAllowed}
                  >
                    ASAP
                  </Button>
                </Box>
              </FormControl>
            )
          }

          {/*time selection*/}
          <Box className="time-picker" mt={3}>
            {
              (otherProps.orderTiming && !otherProps.isAsap) && (
                <MobileTimePicker
                  ampm={false}
                  orientation="portrait"
                  renderInput={(params) => <TextField {...params} />}
                  label="Select Time"
                  minutesStep={5}
                  views={['hours', 'minutes']}
                  toolbarTitle={availableTimesText}
                  showToolbar
                  open={pickerShow}
                  value={selectedTime}
                  onOpen={handleOpen}
                  onChange={setSelectedTime}
                  onAccept={validateSelectedTime}
                  onClose={() => setPickerShow(false)}
                />
              )
            }
          </Box>

        </form>

      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  opening: state.opening.data,
  orderTiming: state.opening.orderTiming.hours,
  isOpen: state.opening.isOpen,
  willOpen: state.opening.willOpen,
  isToday: state.opening.orderTiming.today,
  deliveryTime: state.cart.delivery.time,
  cart: state.cart,
  isAsap: state.cart.delivery.isAsapTime,
  orderType: state.cart.order.type,
});

const mapDispatchToProps = (dispatch) => ({
  setDeliveryTime: (time) => dispatch(cartDeliveryTimeUpdate(time)),
  setDeliveryIsASAPTime: (isASAP) => dispatch(cartUpdateASAPTime(isASAP)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeComp);
