import { Component } from 'react';
import Error500Page from './Error500Page';
import { apiRequest } from '@/util/util';
import { API_RECORD_CRASH } from '@/util/constants';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Report the error to the server

    const data = {
      path: window.location.pathname,
      error: {
        msg: error.message,
        // eslint-disable-next-line quotes
        stack: error.stack.split("\n"),
      }
    };

    apiRequest.post(API_RECORD_CRASH, data);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error500Page />;
    }

    return this.props.children;
  }
}
